import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { type PromoUserSegmentType } from 'utils/apiList/promoUserSegment'
import { CodeBulkUploadType } from 'utils/apiList/bulkUploadV2'
import { getBulkUploadCodeByCampaignType } from 'features/CampaignAndDiscount/utils/getBulkUploadCode'
import {
  fetchCampaignSegmentList,
  updateCampaignProgress,
  fetchCampaignTypeList,
  updateDataInventoriesPerItem,
  removeInventoriesItem,
  fetchSegmentCampaignLevel,
  fetchCampaignCommercialInternalSkp,
  fetchCampaignCommercialInternalSkpDetail,
  fetchSegmentList,
  insertCampaignInventory,
} from './campaignAddManualThunk'

const SLICE_NAME = 'campaignAddManual'

export interface DataSegmentListStateType {
  text: string
  value: string
  name: string
  childs: { name: string }[]
  checked: boolean
  uniqueKey: string
}

export interface FormFilterStateType {
  segment: {
    id: number
    userSegment: string
    name: string
  }
}

export type CampaignTypeListType = {
  name: string
  label: string
  value: string
  uniqueKey: string
}

export type CampaignDetailType = {
  id: number
  name: string
  status: string
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  allDay: boolean
  campaignType: {
    label: string
    value: string
  }
  skp_detail: {
    skp_id: number
    skp_name: string
  }
}

export type InventoryProductListStateType = {
  list: {
    uniqueId: string
    isShowRow: boolean
    locationId: number
    locationName: string
    productId: number
    productName: string
    astroPercentage: number
    productSkuNumber: string
    productStock: number
    originalPrice: number
    campaignPriceDetails: {
      uniqueId: string
      isShowRow: boolean
      campaignInventoryId: number
      discountedPrice: number
      discountPercentage: number
      discountQuota: number
      dailyQuota: number
      userSegment: string
      globalDailyQuota: number
      tier: {
        threshold: number
        discountPercentage: number
        discountedPrice: number
        uniqueId: string
      }[]
    }[]
  }[]
}

type SkpItemType = { [key: string]: { id: number; name: string } }

export type SegmentOptionType = {
  name: string
  id: number
}

export type BulkUploadType = {
  bulkUploadUrl: string
  templateUrl: string
  name: string
  id: number
  code: CodeBulkUploadType
}

export interface CampaignAddManualStateType {
  isLoading: boolean
  formFilter: FormFilterStateType
  dataSegmentList: DataSegmentListStateType[]
  campaignTypeList: CampaignTypeListType[]
  dataSegmentCampaignLevelList: PromoUserSegmentType[]
  campaignDetail: CampaignDetailType
  inventoryProduct: InventoryProductListStateType
  isShowPopupDelete: boolean
  selectedInventoryProduct: InventoryProductListStateType['list']
  needToReload: number
  skps: SkpItemType
  skpsSearch: SkpItemType
  segmentOptions: SegmentOptionType[]
  selectedTargetUserSegments: SegmentOptionType[]
  bulkUpload: BulkUploadType
}

const initialState: CampaignAddManualStateType = {
  isLoading: false,
  formFilter: {
    segment: { id: 0, name: '', userSegment: '' },
  },
  dataSegmentList: [],
  campaignTypeList: [],
  dataSegmentCampaignLevelList: [],
  campaignDetail: {
    id: 0,
    name: '',
    status: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    allDay: false,
    campaignType: {
      label: '',
      value: '',
    },
    skp_detail: {
      skp_id: 0,
      skp_name: '',
    },
  },
  inventoryProduct: {
    list: [],
  },
  isShowPopupDelete: false,
  selectedInventoryProduct: [],
  needToReload: 1,
  skps: {},
  skpsSearch: {},
  segmentOptions: [],
  selectedTargetUserSegments: [],
  bulkUpload: { bulkUploadUrl: '', id: 0, name: '', templateUrl: '', code: '' },
}

const campaignAddManual = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetPartial: (
      state,
      action: PayloadAction<{
        name: keyof CampaignAddManualStateType
      }>,
    ) => {
      const { name } = action.payload
      state[name] = initialState[name] as never
    },
    resetTargetedUserSegment: (state) => {
      state.selectedTargetUserSegments = initialState.selectedTargetUserSegments
    },
    setFormFilter: (
      state,
      action: PayloadAction<{
        fieldName: keyof FormFilterStateType
        value: FormFilterStateType['segment']
      }>,
    ) => {
      const { fieldName, value } = action.payload
      state.formFilter[fieldName] = value as never
    },
    checkDataSegmentList: (state, action) => {
      state.dataSegmentList = state.dataSegmentList.map((el) => {
        const isChecked = el.uniqueKey === action.payload ? !el.checked : el.checked

        return { ...el, checked: isChecked }
      })
    },

    setCampaignDetail: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['campaignDetail'] }>,
    ) => {
      const { value } = action.payload
      state.campaignDetail = value
      state.bulkUpload = {
        ...state.bulkUpload,
        code: getBulkUploadCodeByCampaignType(value.campaignType.value),
      }
    },
    setNeedToReload: (state) => {
      state.needToReload += 1
    },

    setInventoryProductList: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['inventoryProduct']['list'] }>,
    ) => {
      state.inventoryProduct.list = action.payload.value
    },
    setIsShowPopupDelete: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['isShowPopupDelete'] }>,
    ) => {
      state.isShowPopupDelete = action.payload.value
    },
    addSelectedInventoryProduct: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['selectedInventoryProduct'][0] }>,
    ) => {
      state.selectedInventoryProduct.push(action.payload.value)
    },
    bulkAddSelectedInventoryProduct: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['selectedInventoryProduct'] }>,
    ) => {
      state.selectedInventoryProduct = [...state.selectedInventoryProduct, ...action.payload.value]
    },
    removeSelectedInventoryProductByCampaignInventoryId: (
      state,
      action: PayloadAction<{ campaignInventoryId: number }>,
    ) => {
      const { campaignInventoryId } = action.payload
      state.selectedInventoryProduct = state.selectedInventoryProduct.filter(
        (el) => el.campaignPriceDetails[0].campaignInventoryId !== campaignInventoryId,
      )
    },
    bulkRemoveSelectedInventoryProduct: (
      state,
      action: PayloadAction<{ value: CampaignAddManualStateType['selectedInventoryProduct'] }>,
    ) => {
      const { value } = action.payload

      state.selectedInventoryProduct = [...state.selectedInventoryProduct].filter(
        (el) =>
          !value.some(
            (elValue) =>
              el.campaignPriceDetails[0].campaignInventoryId !==
              elValue.campaignPriceDetails[0].campaignInventoryId,
          ),
      )
    },
    setSkps: (state, action: PayloadAction<SkpItemType>) => {
      state.skps = action.payload
    },
    setSkpsSearch: (state, action: PayloadAction<SkpItemType>) => {
      state.skpsSearch = action.payload
    },
    setResetSkps: (state) => {
      state.skps = {}
      state.skpsSearch = {}
    },
    setSelectedTargetUserSegments: (state, action: PayloadAction<SegmentOptionType[]>) => {
      state.selectedTargetUserSegments = action.payload
    },
    setBulkUpload: (
      state,
      action: PayloadAction<{
        value: Partial<BulkUploadType>
      }>,
    ) => {
      const { value } = action.payload
      state.bulkUpload = { ...state.bulkUpload, ...value }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampaignSegmentList.fulfilled, (state, action) => {
        state.dataSegmentList = action.payload.map((el) => ({
          ...el,
          name: el.text,
          childs: [],
          checked: false,
          uniqueKey: window.crypto.randomUUID(),
        }))
      })
      .addCase(updateCampaignProgress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateCampaignProgress.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateCampaignProgress.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignTypeList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignTypeList.rejected, (state) => {
        state.isLoading = false
      })

      .addCase(fetchCampaignTypeList.fulfilled, (state, actions) => {
        state.isLoading = false

        state.campaignTypeList = actions.payload.content.map((el) => ({
          ...el,
          uniqueKey: window.crypto.randomUUID(),

          name: el.label,
        }))
      })
      .addCase(updateDataInventoriesPerItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDataInventoriesPerItem.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateDataInventoriesPerItem.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(removeInventoriesItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(removeInventoriesItem.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(removeInventoriesItem.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignCommercialInternalSkp.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignCommercialInternalSkp.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignCommercialInternalSkp.fulfilled, (state, action) => {
        state.isLoading = false

        const newSkpsUnlisted: SkpItemType = {}
        const skpSearch: SkpItemType = {}

        action.payload.data.forEach((el) => {
          const value = { id: el.id, name: el.skp_no }
          if (!state.skps[el.skp_no]) {
            newSkpsUnlisted[el.skp_no] = value
          }

          if (action.payload.params.skp_no) {
            skpSearch[el.skp_no] = value
          }
        })

        state.skps = { ...newSkpsUnlisted, ...state.skps }
        state.skpsSearch = skpSearch
      })
      .addCase(fetchCampaignCommercialInternalSkpDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchCampaignCommercialInternalSkpDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchCampaignCommercialInternalSkpDetail.fulfilled, (state, action) => {
        state.isLoading = false

        if (action.payload.data.length) {
          const { skp_no, id } = action.payload.data[0]
          state.skps = { [skp_no]: { id, name: skp_no }, ...state.skps }
        }
      })
      .addCase(
        fetchSegmentCampaignLevel.fulfilled,
        (state, action: PayloadAction<PromoUserSegmentType[]>) => {
          const { payload } = action

          state.dataSegmentCampaignLevelList = payload
        },
      )
      .addCase(fetchSegmentList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSegmentList.fulfilled, (state, action) => {
        state.isLoading = false
        state.segmentOptions = action.payload
      })
      .addCase(fetchSegmentList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(insertCampaignInventory.pending, (state) => {
        state.isLoading = true
      })
      .addCase(insertCampaignInventory.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(insertCampaignInventory.rejected, (state) => {
        state.isLoading = false
      })
  },
})

export default campaignAddManual.reducer
export const {
  reset,
  setFormFilter,
  checkDataSegmentList,
  setCampaignDetail,
  setInventoryProductList,
  setIsShowPopupDelete,
  addSelectedInventoryProduct,
  removeSelectedInventoryProductByCampaignInventoryId,
  bulkAddSelectedInventoryProduct,
  bulkRemoveSelectedInventoryProduct,
  setNeedToReload,
  resetPartial,
  setSkps,
  setSkpsSearch,
  setResetSkps,
  setSelectedTargetUserSegments,
  resetTargetedUserSegment,
  setBulkUpload,
} = campaignAddManual.actions
