import {
  SET_IS_START_HUB_DETAIL,
  SET_IS_FAILED_HUB_DETAIL,
  SET_IS_SUCCESS_HUB_DETAIL,
  SET_IS_NEED_TO_RELOAD_HUB_DETAIL,
  SET_PAGINATION_DATA_HUB_DETAIL,
  SET_SELECT_PRODUCT_DATA_HUB_DETAIL,
  SET_IS_SUCCESS_HUB_DETAIL_HISTORY,
  SET_IS_START_HUB_DETAIL_HISTORY,
  SET_IS_FAILED_HUB_DETAIL_HISTORY,
  SET_PAGINATION_DATA_HUB_DETAIL_HISTORY,
  SET_IS_SUCCESS_INVENTORY_LOCATION,
  SET_IS_FAILED_INVENTORY_LOCATION,
  SET_PAGINATION_INVENTORY_LOCATION,
  SET_SAVE_INVENTORY_LOCATION_START,
  SET_SAVE_INVENTORY_LOCATION_SUCCESS,
  SET_SAVE_INVENTORY_LOCATION_FAILED,
  SET_UPDATE_INVENTORY_LOCATION_START,
  SET_UPDATE_INVENTORY_LOCATION_SUCCESS,
  SET_UPDATE_INVENTORY_LOCATION_FAILED,
  SET_IS_SUCCESS_INVENTORY_STATUS,
  SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS,
  SET_IS_START_INVENTORY_SAVE,
  SET_IS_FAILED_INVENTORY_SAVE,
  SET_IS_SUCCESS_INVENTORY_SAVE,
  SET_IS_START_INVENTORY_NOTE_BY_STATUS,
  SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS,
  SET_MODAL_INFORMATION,
  SET_INVENTORY_NOTE,
  RESET_STATE_HUB_DETAIL,
} from 'storeContext/actionsType/hubDetail'

export const initialStateHubDetail = {
  isLoading: false,
  isLoadingGetNoteByStatus: false,
  productHubDetailData: [],
  inventoryStatusData: [],
  inventoryNoteByStatusData: [],
  hubDetailData: [],
  hubDetailHistoryData: [],
  paginationData: {},
  paginationDataStockHistory: {
    pageIndex: 1,
    pageSize: 20,
    numberOfElements: 0,
  },
  paginationInventoryLocation: {},
  needToReload: 1,
  selectedProduct: {},
  successCreateProductDetail: null,
  updateCreateProductDetail: null,
  modalInformation: {
    isOpen: false,
    message: '',
  },
  inventoryNote: {},
}

export default function hubDetail(state = initialStateHubDetail, { type, payload }) {
  switch (type) {
    case SET_IS_START_INVENTORY_SAVE:
    case SET_IS_START_HUB_DETAIL_HISTORY:
    case SET_SAVE_INVENTORY_LOCATION_START:
    case SET_UPDATE_INVENTORY_LOCATION_START:
    case SET_IS_START_HUB_DETAIL:
      return {
        ...state,
        isLoading: true,
      }

    case SET_IS_START_INVENTORY_NOTE_BY_STATUS:
      return {
        ...state,
        isLoadingGetNoteByStatus: true,
      }

    case SET_SAVE_INVENTORY_LOCATION_FAILED:
    case SET_UPDATE_INVENTORY_LOCATION_FAILED:
    case SET_IS_FAILED_INVENTORY_LOCATION:
    case SET_IS_FAILED_HUB_DETAIL_HISTORY:
    case SET_IS_FAILED_HUB_DETAIL:
    case SET_IS_FAILED_INVENTORY_SAVE:
    case SET_IS_SUCCESS_INVENTORY_SAVE:
      return {
        ...state,
        isLoading: false,
      }

    case SET_IS_FAILED_INVENTORY_NOTE_BY_STATUS:
      return {
        ...state,
        isLoadingGetNoteByStatus: false,
      }
    case SET_IS_SUCCESS_HUB_DETAIL:
      return {
        ...state,
        isLoading: false,
        hubDetailData: payload.content,
      }

    case SET_SAVE_INVENTORY_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successCreateProductDetail: payload,
      }

    case SET_UPDATE_INVENTORY_LOCATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        updateCreateProductDetail: payload,
      }

    case SET_IS_SUCCESS_INVENTORY_LOCATION:
      return {
        ...state,
        isLoading: false,
        productHubDetailData: payload.data.content,
      }

    case SET_IS_SUCCESS_INVENTORY_STATUS:
      return {
        ...state,
        isLoading: false,
        inventoryStatusData: payload.data.status,
      }
    case SET_IS_SUCCESS_INVENTORY_NOTE_BY_STATUS:
      return {
        ...state,
        isLoadingGetNoteByStatus: false,
        inventoryNoteByStatusData: payload.data.notes,
      }

    case SET_IS_SUCCESS_HUB_DETAIL_HISTORY:
      return {
        ...state,
        isLoading: false,
        hubDetailHistoryData: payload,
      }

    case SET_IS_NEED_TO_RELOAD_HUB_DETAIL:
      return {
        ...state,
        needToReload: state.needToReload + 1,
      }
    case SET_PAGINATION_DATA_HUB_DETAIL:
      return {
        ...state,
        paginationData: payload,
      }

    case SET_PAGINATION_DATA_HUB_DETAIL_HISTORY:
      return {
        ...state,
        paginationDataStockHistory: payload,
      }

    case SET_SELECT_PRODUCT_DATA_HUB_DETAIL:
      return {
        ...state,
        selectedProduct: {
          ...state.selectedProduct,
          ...payload,
        },
      }
    case SET_PAGINATION_INVENTORY_LOCATION:
      return {
        ...state,
        paginationInventoryLocation: payload,
      }
    case SET_MODAL_INFORMATION:
      return {
        ...state,
        modalInformation: payload,
      }
    case SET_INVENTORY_NOTE:
      return {
        ...state,
        inventoryNote: payload,
      }
    case RESET_STATE_HUB_DETAIL: {
      return JSON.parse(JSON.stringify(initialStateHubDetail))
    }
    default:
      return {
        ...state,
      }
  }
}
