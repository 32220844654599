import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const MasterPayrollRBAC = {
  pageID: 'b248f5d4691fc2e1df17708c2b0e1f0e',
  elementID: {
    ViewPayrollList: '0772292873df137838a62ae48cd4dd4c',
    PreviewAndDownloadPayroll: '5dc09f5b21e7bc795dad107ff1751444',
    ExportPayroll: '9cf5fab864e6541e4944cd3d01d64af3',
    BulkUploadPayroll: 'cb1df85b266e875bab98022524c0f824',
    PublishPayroll: '93ca664422afbfc489489c0f9bb877e2',
  },
} as const

export const useRBACMasterPayrollPage = createUseRBAC(MasterPayrollRBAC.elementID)

export type UseRBACMasterPayrollPageType = ReturnType<typeof useRBACMasterPayrollPage>
