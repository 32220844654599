import { AxiosRequestConfig } from 'axios'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Get, Post } from 'config/apiServiceConfig'
import {
  GetRackStorageResponseType,
  GetUomsResponseType,
  LocationType,
} from 'features/Rack/@types/rack'

export type GetRackListResponseType = {
  data: {
    utilization: number
    location: LocationType
    rack_count: number
    filled_rack: number
  }[]
  pagination: PaginationType
}

export type GetRackDetailByLocationIdResponseType = {
  data: {
    location: LocationType
    racks: {
      id: number
      is_empty: boolean
      position: number
      rack_name: string
      max_volume: number
      max_volume_uom: string
      max_quantity: number
      max_quantity_uom: string
      max_weight: number
      max_weight_uom: string
      active: boolean
      mix_product: boolean
    }[]
  }
  pagination: PaginationType
}

export type GetRackDetailByLocationIdParamsType = {
  page_index: number
  page_size: number
  rack_name?: string
  active?: string
  deleted?: string
}

export type GetLocationListParamsType = {
  name: string
}

type GetLocationListResponseType = { content: LocationType[] }

export type PostRackImportResponseType = {
  data: {
    status: string
    urlDownload: string
    rackTotal: number
    rackFailed: number
    rackSuccess: number
  }
}

type GetRackCsvTemplateResponseType = {
  data: {
    url: string
  }
}

export type GetRackStorageParamsType = {
  type: 'Environment' | 'Type'
}

type GetUomsParamsType = {
  pageSize: number
  group?: 'ITEM' | 'WEIGHT' | 'LENGTH'
}

export type GetRackListParamsType = {
  location_id?: number
  page_size: number
  page_index: number
}

export type GetRacksExportParamsType = {
  pageIndex?: number
  pageSize: number
  name?: string
  active?: string
}

export type GetElligibleRacksParamType = {
  page_size: number
  page_index: number
  location_id: number
  status_id: number
  product_id: number
  expiry_date?: string
  search?: string
  is_autofill_request?: boolean
  validate_existing_racks?: boolean
}

export type GetElligibleRacksResponseType = {
  pagination: PaginationType
  data: {
    created_by: string
    mix_attribute: boolean
    mix_product: boolean
    is_deleted: boolean
    location_id: number
    id: number
    active: boolean
    position: number
    rack_name: string
  }[]
}

export const getLocationList = (params?: GetLocationListParamsType) =>
  Get<GetLocationListResponseType>({
    url: '/api/location',
    params,
  })

export const getRackList = (params: GetRackListParamsType) =>
  axiosInstanceApiGateway.Get<GetRackListResponseType, true>({
    url: '/ims/internal/v1/rack/location',
    params,
  })

export const getRackDetailByLocationId = (
  locationId: number,
  params: GetRackDetailByLocationIdParamsType,
) =>
  axiosInstanceApiGateway.Get<GetRackDetailByLocationIdResponseType, true>({
    url: `/ims/internal/v1/rack/${locationId}`,
    params,
  })

export const getRacksExport = (locationId: number, params?: GetRacksExportParamsType) =>
  Get({ url: `/api/racks/${locationId}/export`, params })

export const putActivateDeactivateRack = (rackId: number, data: { active: boolean }) =>
  axiosInstanceApiGateway.Put({ url: `/ims/internal/v1/rack/${rackId}/active`, data })

export const postRackImport = (data: FormData, config: AxiosRequestConfig) =>
  Post<PostRackImportResponseType>({ url: `/api/racks/import`, data, config })

export const deleteRack = (locationId: number, queryStringParams: string) =>
  axiosInstanceApiGateway.Delete({
    url: `/ims/internal/v1/rack/${locationId}?${queryStringParams}`,
  })

export const getRackCsvTemplate = () =>
  Get<GetRackCsvTemplateResponseType>({ url: '/api/racks/csv/template' })

export const getRackStorage = (params: GetRackStorageParamsType) =>
  Get<GetRackStorageResponseType>({ url: '/api/racks/storage', params })

export const getUoms = (params: GetUomsParamsType) =>
  Get<GetUomsResponseType>({ url: '/api/uoms', params })

export const getElligibleRacks = (params: GetElligibleRacksParamType) =>
  axiosInstanceApiGateway.Get<GetElligibleRacksResponseType, true>({
    url: '/ims/internal/v1/rack/eligible',
    params,
  })
