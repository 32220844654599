/* eslint-disable @typescript-eslint/naming-convention */
import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetBrandListParamsType = {
  'pagination.page_size': number
  'pagination.page_index': number
  sort: string
  direction: string
  name?: string
}

export type BrandCompanyItemType = {
  id: number
  name: string
  active?: boolean
  created_at?: string
  created_by?: string
  updated_at?: string
  updated_by?: string
}

export type BrandItemType = {
  brand_id: number
  brand_name: string
  cloud_kitchen: boolean
  brand_company: BrandCompanyItemType
  created_by: string
}

export type GetBrandListResponseType = {
  data: BrandItemType[]
  pagination: {
    sort: string
    direction: string
    page_size: number
    page_index: number
    number_of_elements: number
  }
}

export type GetBrandDetailByIdResponseType = BrandItemType & {
  barcode_required: boolean
  bpom_required: boolean
  sni_required: boolean
  pirt_required: boolean
  mui_required: boolean
}

export type CreateOrUpdateBrandRequestType = {
  brand_id?: number
  brand_name: string
  cloud_kitchen: boolean
  brand_company_name: string
  barcode_required: boolean
  bpom_required: boolean
  sni_required: boolean
  pirt_required: boolean
  mui_required: boolean
  created_by?: string
  updated_by?: string
}

export type GetAllBrandCompanyRequestType = Partial<{
  name: string
  sort: string
  direction: DirectionType
  pageSize: number
}>

export type GetAllBrandCompanyResponseType = {
  data: BrandCompanyItemType[]
  pagination: PaginationType
}

export type GetAllBrandV2 = Partial<{
  pageIndex: number
  pageSize: number
  sort: string
  direction: DirectionType
  name: string
}>

export type GetAllBrandV2ResponseType = {
  data: {
    brand_id: number
    brand_name: string
    cloud_kitchen: boolean
    brand_company: {
      id: number
      name: string
    }
  }[]
}

export type PutCloudKitchenRequestType = {
  cloud_kitchen: boolean
  brand_id: number
  updated_by: string
}

export const getBrandList = (params: GetBrandListParamsType) =>
  axiosInstanceApiGateway.Get<GetBrandListResponseType, true>({
    url: '/ims/internal/v1/brands',
    params,
  })

export const getBrandDetailById = (id: number) =>
  axiosInstanceApiGateway.Get<GetBrandDetailByIdResponseType>({
    url: `/ims/internal/v1/brands/${id}`,
  })

export const getAllBrandCompany = (params: GetAllBrandCompanyRequestType) =>
  axiosInstanceApiGateway.Get<GetAllBrandCompanyResponseType, true>({
    url: '/ims/internal/v1/brands/companies',
    params,
  })

export const postCreateBrand = (data: CreateOrUpdateBrandRequestType) =>
  axiosInstanceApiGateway.Post({
    url: `/ims/internal/v1/brands`,
    data,
  })

export const putUpdateBrand = (id: number, data: CreateOrUpdateBrandRequestType) =>
  axiosInstanceApiGateway.Put({
    url: `/ims/internal/v1/brands/${id}`,
    data,
  })

export const putCloudKitchen = (id: number, data: PutCloudKitchenRequestType) =>
  axiosInstanceApiGateway.Put({
    url: `/ims/internal/v1/brands/${id}/cloud-kitchen`,
    data,
  })

export const getAllBrandV2 = (params: GetAllBrandV2) =>
  Get<GetAllBrandV2ResponseType>({ url: `/api/v2/brand`, params })
