/* eslint-disable @typescript-eslint/naming-convention */
import { AppDispatchType, RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import { getProductById } from 'features/Product/services/product'
import { toastSuccess } from 'utils/toast'
import {
  getPackageStructure,
  deletePackageStructure,
  savePackageStructure,
  SavePackageStructureRequestType,
} from '../../services/packageStructure'
import {
  setIsLoading,
  setSelectedProduct,
  setParamsPackageStructure,
  setPackageStructureList,
} from './slice'

type ParamsPackageStructureType = {
  pageIndex: number
  pageSize: number
  productId: number
}

const getParamsForPackageStructure = (params: Omit<ParamsPackageStructureType, 'productId'>) => ({
  'pagination.page_size': params.pageSize || 20,
  'pagination.page_index': params.pageIndex || 1,
})

type ActFetchPackageStructureParamsType = {
  productId: number
} & Partial<Omit<ParamsPackageStructureType, 'productId'>>

export const actFetchPackageStructure =
  (params: ActFetchPackageStructureParamsType) => async (dispatch: AppDispatchType) => {
    const { productId, pageSize, pageIndex } = params
    try {
      dispatch(setIsLoading(true))
      const { data } = await getPackageStructure(
        productId,
        getParamsForPackageStructure({ pageSize: pageSize || 20, pageIndex: pageIndex || 1 }),
      )
      dispatch(setParamsPackageStructure(params))
      dispatch(
        setPackageStructureList({
          data: data.data,
          pagination: data.pagination,
        }),
      )
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actRemovePackageStructure =
  (id: number) => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { params } = getState().packageStructure
    try {
      dispatch(setIsLoading(true))
      await deletePackageStructure(id)
      dispatch(actFetchPackageStructure(params))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actCreateOrUpdatePackageStructure =
  (formData: SavePackageStructureRequestType, callbackFunction: () => void) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { params } = getState().packageStructure
    try {
      dispatch(setIsLoading(true))
      const { data } = await savePackageStructure(formData)
      dispatch(actFetchPackageStructure(params))
      toastSuccess(data.message)
      if (callbackFunction) callbackFunction()
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setIsLoading(false))
    }
  }

export const actFetchProductById = (productId: number) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getProductById(productId)
    dispatch(setSelectedProduct(data))
  } catch (error) {
    callErrorMsg(error)
  }
}
