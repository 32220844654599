import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LocationType } from 'features/Rack/@types/rack'
import {
  GetRackListParamsType,
  GetRackListResponseType,
  GetRackDetailByLocationIdParamsType,
  GetRackDetailByLocationIdResponseType,
} from 'features/Rack/services/rackList'

export type BarcodeSlocToPrintType = {
  id: number
  rack_name: string
  location_name: string
  mix_product: boolean
}

export type RackInitialStateType = {
  isLoading: boolean
  rack: {
    listData: GetRackListResponseType['data']
    queryParamsRackList: GetRackListParamsType
    numberOfElements: number
  }
  location: {
    listData: LocationType[]
    selectedLocation: LocationType | null
    queryParams?: {
      name: string
    }
  }
  modalBulkUpload: {
    isOpen: boolean
    templateUrl: string
    fileCSV: {
      status: string
      rawFile?: File
      file?: string
      fileName?: string
      fileSize?: File['size']
      fileType?: File['type']
      url_download: string
      data_count: {
        total_data: number
        success_data: number
      }
    }
  }
  modalDelete: {
    isOpen: boolean
    title: string
    type: 'single' | 'multiple'
    content: string
    idToDelete: number[]
  }
  rackDetail: {
    numberOfElements: number
    location: GetRackDetailByLocationIdResponseType['data']['location']
    listData: GetRackDetailByLocationIdResponseType['data']['racks']
    params: GetRackDetailByLocationIdParamsType
  }
  checkedIds: number[]
  barcodeSlocToPrint: BarcodeSlocToPrintType[]
}

const initialState: RackInitialStateType = {
  isLoading: false,
  rack: {
    listData: [],
    queryParamsRackList: {
      page_index: 0,
      page_size: 20,
    },
    numberOfElements: 0,
  },
  location: {
    listData: [],
    selectedLocation: null,
    queryParams: {
      name: '',
    },
  },
  modalBulkUpload: {
    isOpen: false,
    templateUrl: '',
    fileCSV: {
      status: 'ON_CHOOSE_FILE',
      fileName: '',
      url_download: '',
      data_count: { total_data: 0, success_data: 0 },
    },
  },
  modalDelete: {
    isOpen: false,
    title: '',
    type: 'single',
    content: 'Data yg sudah dihapus tidak dapat dikembalikan lagi, yakin ingin hapus ?',
    idToDelete: [],
  },
  rackDetail: {
    numberOfElements: 0,
    location: {
      location_id: 0,
      location_name: '',
      location_type: '',
    },
    listData: [],
    params: {
      page_index: 1,
      page_size: 20,
    },
  },
  checkedIds: [],
  barcodeSlocToPrint: [],
}

export const SLICE_NAME = 'rack'

const rack = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setQueryParamsOfRackList: (state, action: PayloadAction<Partial<GetRackListParamsType>>) => {
      state.rack.queryParamsRackList = { ...state.rack.queryParamsRackList, ...action.payload }
    },
    setRack: (
      state,
      action: PayloadAction<{
        listData: GetRackListResponseType['data']
        numberOfElements: number
      }>,
    ) => {
      const { listData, numberOfElements } = action.payload
      state.rack.listData = listData
      state.rack.numberOfElements = numberOfElements
    },
    setLocation: (state, action) => {
      state.location = { ...state.location, ...action.payload }
    },
    setModalBulkUpload: (state, action) => {
      state.modalBulkUpload = { ...state.modalBulkUpload, ...action.payload }
    },
    setParamsOfRackDetail: (
      state,
      action: PayloadAction<Partial<GetRackDetailByLocationIdParamsType>>,
    ) => {
      state.rackDetail.params = { ...state.rackDetail.params, ...action.payload }
    },
    setRackDetail: (state, action: PayloadAction<Partial<RackInitialStateType['rackDetail']>>) => {
      state.rackDetail = {
        ...state.rackDetail,
        ...action.payload,
      }
    },
    setCheckedIds: (state, action) => {
      state.checkedIds = action.payload
    },
    setModalDelete: (state, action) => {
      state.modalDelete = { ...state.modalDelete, ...action.payload }
    },
    resetModalDelete: (state) => {
      state.modalDelete = initialState.modalDelete
    },
    resetModalBulkUpload: (state) => {
      state.modalBulkUpload = initialState.modalBulkUpload
    },
    setBarcodeSlocToPrint: (state, action) => {
      state.barcodeSlocToPrint = action.payload
    },
  },
})
export const {
  setQueryParamsOfRackList,
  setRack,
  resetState,
  setIsLoading,
  setLocation,
  setParamsOfRackDetail,
  setRackDetail,
  setModalBulkUpload,
  setCheckedIds,
  setModalDelete,
  resetModalDelete,
  resetModalBulkUpload,
  setBarcodeSlocToPrint,
} = rack.actions
export default rack.reducer
