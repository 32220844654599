const TypeTemplateList = {
  OrderPackerWaitingDriver: 'ORDER_PACKER_WAITING_DRIVER',
  OrderPickerPicking: 'ORDER_PICKER_PICKING',
  OrderPackerPacking: 'ORDER_PACKER_PACKING',
  InboundPo: 'INBOUNDPO',
  SupplyOrderCekList: 'SUPPLY_ORDER_CHECK_LIST',
  /*this old delivery note. now rename to cetak supply order*/
  SupplyOrderCetakSuratJalan: 'SUPPLY_ORDER_CETAK_SURAT_JALAN',
  /*this delivery note with bulk functionality*/
  SupplyOrderPrintDeliveryNoteV2: 'SUPPLY_ORDER_PRINT_DELIVERY_NOTE_V2',
  InboundCompleteSO: 'InboundCompleteSO',
  DeliveryOrder: 'DELIVERY_ORDER',
  PurchaseOrderLiteQRCodeExpire: 'PurchaseOrderLiteQRCodeExpire',
  PurchaseOrderLiteCreatePOLite: 'PurchaseOrderLiteCreatePOLite',
  PurchaseOrderLiteCreatePOLiteNewWithTax: 'PurchaseOrderLiteCreatePOLiteNewWithTax',
  PurchaseOrderLiteGeneratePackageID: 'PurchaseOrderLiteGeneratePackageID',
  PurchaseOrderLiteGeneratePackageIdMobile: 'PurchaseOrderLiteGeneratePackageIdMobile',
  PurchaseOrderLiteGenerateKoliID: 'PurchaseOrderLiteGenerateKoliID',
  SupplyOrderCheckQuantity: 'SupplyOrderCheckQuantity',
  PrintQRV2: 'PrintQRV2',
  FPOPrintBarcode: 'FPOPrintBarcode',
  StockOpname: 'StockOpname',
  StockOpnameBeritaAcara: 'StockOpnameBeritaAcara',
  RelabelAstroGoods: 'RelabelAstroGoods',
  GrabMart: 'GrabMart',
  AstroKitchen: 'AstroKitchen',
  SupplyOrderPrintDeliveryNoteOld: 'SupplyOrderPrintDeliveryNoteOld',
  GiftNote: 'GiftNote',
  KitchenModifierReceipt: 'KitchenModifierReceipt',
  PriceTagPrint: 'PriceTagPrint',
  PrintBarcodeSloc: 'PrintBarcodeSloc',
}

export default TypeTemplateList
