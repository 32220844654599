export function authAdminChecker(stateAuthUserData) {
  const listCurrentAuth = stateAuthUserData.authorities.map((item) => item.name)

  return listCurrentAuth.includes('AUTH_ADMIN') ? true : false
}

export function filterLocationList(
  defaultLocationList,
  stateAuthUserData,
  keyType = 'id', //
  config = {
    showDefaultList: false, ///SHOW default FOR OTHER THAN AUTH_ADMIN
  },
) {
  const listLocationFromAuth = stateAuthUserData.location_roles.map((item) => {
    let newObj
    if (keyType === 'id') {
      newObj = {
        id: item.location_id,
        name: item.location_name,
        type: item.location_type,
      }
    } else if (keyType === 'location_id') {
      //newObj = { ...item };
      newObj = {
        name: item.location_name,
        location_id: item.location_id,
        location_name: item.location_name,
        location_type: item.location_type,
      }
    }

    return newObj
  })

  if (authAdminChecker(stateAuthUserData)) {
    return defaultLocationList
  } else {
    if (config.showDefaultList) {
      return defaultLocationList
    } else {
      return listLocationFromAuth
    }
    //return listLocationFromAuth;
  }
}

export const getLocationIdByAccessRoleLocation = (userAuth, listLocationType = []) => {
  if (authAdminChecker(userAuth)) {
    return {
      locationId: '',
      type: listLocationType.toString(),
    }
  }

  const listLocation = userAuth.location_roles.filter((locationItem) =>
    listLocationType.includes(locationItem.location_type),
  )

  const locationIdList = listLocation.map((locationItem) => locationItem.location_id) || ''

  return {
    locationId: locationIdList.toString(),
    type: listLocationType.toString(),
  }
}

export function initialLocationQuery(
  //defaultLocationList,
  stateAuthUserData,
  withQuery = false,
  locationType = false,
  config = {
    showAll: false,
  },
  //keyType = "id"
) {
  if (authAdminChecker(stateAuthUserData)) {
    return ``
  } else if (config.showAll) {
    //
    return ``
  } else {
    if (withQuery === true) {
      if (locationType === 'HUB') {
        let tmpHubLoc = stateAuthUserData.location_roles.filter((el) => {
          return el.location_type === 'HUB'
        })

        if (tmpHubLoc.length) return tmpHubLoc.map((tmpItemHub) => tmpItemHub.location_id)
        return ''
        //
      } else if (locationType === 'WAREHOUSE') {
        //
        let tmpWarehouseLoc = stateAuthUserData.location_roles.filter((el) => {
          //console.log(el);
          return el.location_type === 'WAREHOUSE'
        })
        //console.log(tmpWarehouseLoc);

        return tmpWarehouseLoc
      } else {
        return stateAuthUserData.location_roles[0].location_id
      }
    } else {
      return `&locationId=${stateAuthUserData.location_roles[0].location_id}`
    }
  }
}

export const roleUserChecker = (userData, listOfAllowedUser = []) => {
  /***
   * CHECKING USER ROLE, IF LOGED USER ROLE EXIST / SAME WITH listOfAllowedUser
   * RETURN TRUE
   */
  const { authorities } = userData

  const currentUserAuth = authorities.map((items) => items.name)

  const findAllowedAccess = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item))
  }
  //console.log(listOfAllowedUser);
  //console.log(findAllowedAccess(currentUserAuth, listOfAllowedUser));
  return findAllowedAccess(listOfAllowedUser, currentUserAuth)
}

export function filterByUserLocationsList(
  defaultLocationList,
  stateAuthUserData,
  config = {
    showDefaultList: false, ///SHOW default FOR OTHER THAN AUTH_ADMIN
  },
) {
  const listUserLocationsFromAuth = stateAuthUserData.user_locations.map((item) => {
    let newObj
    newObj = {
      name: item.location_name,
      location_id: item.location_id,
      location_name: item.location_name,
      location_type: item.location_type,
    }

    return newObj
  })

  if (authAdminChecker(stateAuthUserData) || config.showDefaultList) {
    return defaultLocationList
  }
  return listUserLocationsFromAuth
}
