import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastSuccess } from 'utils/toast'
import {
  getProductListForSoDirect,
  getProductItemsForSoDirect,
  getConfigSoDirect,
  postAddSoProduct,
  putUpdateSoProduct,
  GetProductListForSoDirectResponseType,
  GetProductItemsForSoDirectResponseType,
  GetProductListForSoDirectRequestType,
  ProductResponseType,
  ProductItemResponseType,
  PostAddSoProductRequestType,
  PutSoProductRequestType,
} from 'utils/apiList/supplyOrderAddProductDirect'
import { getProductType } from 'features/Product/services/product'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'supplyOrderAddProductDirect'

export type ExtendedProductAttributeType = {
  isChecked: boolean
  qty: number
  errorMessage: string
}

export type ProductType = ProductResponseType &
  ExtendedProductAttributeType & {
    selectedItemList: SelectedItemType[]
    isInputDisabled: boolean
  }

export type SelectedItemType = ProductItemResponseType & {
  isChecked: boolean
  qty: number
  errorMessage: string
}

const getListProductForChildItem = (
  listIds: string[],
  itemList: GetProductItemsForSoDirectResponseType['data'],
  productItemIdWithItsData: Record<string, SelectedItemType>[],
) => {
  let products = []
  if (listIds.length)
    products = itemList.filter((itemData) => !listIds.includes(itemData.productUniqueKey))
  else products = [...itemList]

  return products.map((itemData) => {
    const { productUniqueKey } = itemData
    const objIdWithItsData = productItemIdWithItsData.find((item) => item[productUniqueKey])

    return {
      ...itemData,
      qty: objIdWithItsData ? objIdWithItsData[productUniqueKey].qty : 0,
      errorMessage: objIdWithItsData ? objIdWithItsData[productUniqueKey].errorMessage : '',
      isChecked: objIdWithItsData ? objIdWithItsData[productUniqueKey].isChecked : false,
    }
  })
}

const getListProductForParentItem = (
  itemList: GetProductListForSoDirectResponseType['data'],
  productGroupIdWithQty: Record<string, ProductType>[],
) =>
  itemList.map((itemData) => {
    const { productId, productInventoryStatusId, productInventoryStatusNoteId } = itemData

    const uniqueId =
      +productInventoryStatusId === 1
        ? `${productInventoryStatusId}-${productId}`
        : `${productInventoryStatusId}-${productId}-${productInventoryStatusNoteId}`

    const objIdWithQty = productGroupIdWithQty.find((item) => item[uniqueId])

    return {
      isChecked: objIdWithQty ? objIdWithQty[uniqueId].isChecked : false,
      ...itemData,
      qty: objIdWithQty ? objIdWithQty[uniqueId].qty : 0,
      errorMessage: objIdWithQty ? objIdWithQty[uniqueId].errorMessage : '',
      selectedItemList: objIdWithQty ? objIdWithQty[uniqueId].selectedItemList : [],
    }
  })

const WAREHOUSE_ID = {
  kos: 40,
  pgs: 160,
  cbn: 661,
  stl: 772,
}

export const actGetConfigOfSupplyOrder = createAsyncThunk(
  `${SLICE_NAME}/getConfigOfSupplyOrder`,
  async (destinationId: number, { dispatch, rejectWithValue }) => {
    try {
      const {
        data: { data },
      } = await getConfigSoDirect(destinationId)
      dispatch(
        fetchProductList({
          hideBusinessTaggingNull: data.hide_business_tagging_null,
          businessTaggings: data.business_taggings,
        }),
      )
      return data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductList = createAsyncThunk<
  GetProductListForSoDirectResponseType,
  Partial<GetProductListForSoDirectRequestType> | undefined
>(`${SLICE_NAME}/fetchProductList`, async (paramsToAppend, { getState, rejectWithValue }) => {
  try {
    const rootState = getState() as StoreStateType
    const { queryParamsProductList, productGroupIdWithItsData } =
      rootState.supplyOrderAddProductDirect

    let paramsToSubmit = { ...queryParamsProductList }

    if (paramsToAppend) {
      paramsToSubmit = { ...paramsToSubmit, ...paramsToAppend }
    }

    const {
      data: { data, pagination },
    } = await getProductListForSoDirect(paramsToSubmit)
    const listProduct = getListProductForParentItem(data, productGroupIdWithItsData)

    return {
      data: listProduct,
      pagination,
    }
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(err)
  }
})

export const fetchProductItemList = createAsyncThunk(
  `${SLICE_NAME}/fetchProductItemList`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const {
        queryParamsProductItems,
        productList,
        modalProduct: { indexProductList },
        productItemIdWithItsData,
      } = rootState.supplyOrderAddProductDirect

      const {
        data: { data, pagination },
      } = await getProductItemsForSoDirect(queryParamsProductItems)

      const listIdsInSelectedItems = productList[indexProductList].selectedItemList.map(
        (item: { productUniqueKey: string }) => item.productUniqueKey,
      )
      const listProductItems = getListProductForChildItem(
        listIdsInSelectedItems,
        data,
        productItemIdWithItsData,
      )

      let shouldFetchProductItemListForNextPage = false
      if (!listProductItems.length && data.length) shouldFetchProductItemListForNextPage = true

      return {
        data: listProductItems,
        pagination,
        shouldFetchProductItemListForNextPage,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const addProductSo = createAsyncThunk(
  `${SLICE_NAME}/addProductSo`,
  async (body: PostAddSoProductRequestType, { rejectWithValue }) => {
    try {
      const response = await postAddSoProduct(body)
      toastSuccess('Create supply order berhasil')
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export type UpdateProductSoRequestBodyType = PutSoProductRequestType & {
  supplyOrderId: number
}

export const updateProductSo = createAsyncThunk(
  `${SLICE_NAME}/updateProductSo`,
  async (body: UpdateProductSoRequestBodyType, { rejectWithValue }) => {
    const { supplyOrderId, ...rest } = body
    try {
      const response = await putUpdateSoProduct(supplyOrderId, rest)
      toastSuccess('Update supply order berhasil')
      return response.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchProductType = createAsyncThunk(
  `${SLICE_NAME}/fetchProductType`,
  async (_, { dispatch, getState, rejectWithValue }) => {
    const rootState = getState() as StoreStateType
    const { destinationId } = rootState.supplyOrderAddProductDirect

    const listProductType = [
      'dry',
      'fresh',
      'freebies',
      'raw material',
      'supplies',
      'gift customer',
      'pwp',
      'packaging',
      'general merchandise',
      'merchandise astro club',
      'astro kitchen - supplies',
      'astro kitchen - fixed assets',
      'astro kitchen - finished goods',
      'astro kitchen - raw material chilled/frozen',
      'astro kitchen - raw material dry',
      'astro kitchen - raw material packaging',
      'astro kitchen - packaging outer',
    ]

    try {
      const {
        data: { data },
      } = await getProductType()
      let modifiedProductTypeList = data.filter((itemData) =>
        listProductType.includes(itemData.product_type_name.toLowerCase()),
      )

      let productTypeToExclude = ''
      if ([WAREHOUSE_ID.kos, WAREHOUSE_ID.stl].includes(destinationId))
        productTypeToExclude = 'fresh'
      else if ([WAREHOUSE_ID.pgs, WAREHOUSE_ID.cbn].includes(destinationId))
        productTypeToExclude = 'dry'

      if (
        [WAREHOUSE_ID.kos, WAREHOUSE_ID.stl, WAREHOUSE_ID.pgs, WAREHOUSE_ID.cbn].includes(
          destinationId,
        )
      ) {
        modifiedProductTypeList = modifiedProductTypeList.filter(
          (item) => item.product_type_name.toLowerCase() !== productTypeToExclude,
        )
      }

      dispatch(
        fetchProductList({
          productTypeIds: modifiedProductTypeList.map((item) => item.product_type_id).toString(),
        }),
      )
      return modifiedProductTypeList
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
