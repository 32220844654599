import { AppDispatchType, RootStateType } from 'store'
import { getDiscoveryLabel } from 'utils/apiList/dicoveryLabel'
import { callErrorMsg } from 'helpers/errorMsg'
import { AssetType, SkuRegistrationItemType } from 'features/Product/@types/SkuRegistration'
import { submitProductRegistration } from 'features/Product/services/skuRegistration'
import { postImage } from 'utils/apiList/images'
import { toastSuccess, toastFailed } from 'utils/toast'
import {
  SkuRegistrationInitialStateType,
  setLabelList,
  setErrorSkuAssetForm,
  setModalSkuMerchandising,
  resetModalSkuMerchandising,
} from './slice'
import { actFetchSkuRegistration } from './thunk'

export const actFetchLabel =
  (typeId: number, name?: string) => async (dispatch: AppDispatchType) => {
    try {
      const { data } = await getDiscoveryLabel({
        name: name || '',
        pageIndex: 0,
        pageSize: 20,
        typeId,
      })
      dispatch(
        setLabelList({
          fieldName: typeId === 1 ? 'labelImageList' : 'labelPromotionList',
          listData: data.content,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

const isSkuAssetFormValid = (
  skuAsset: AssetType,
  modalSkuMerchandising: SkuRegistrationInitialStateType['modalSkuMerchandising'],
) => {
  const { productImageList, productDescription } = modalSkuMerchandising

  let isValid = true
  const newErrorSkuAssetForm = {
    image_paths: '',
    name: '',
    description: '',
  }

  const isImageValid = productImageList.some((item) => item.file || item.pathMedium)

  const validations = [
    {
      condition: !isImageValid,
      field: 'image_paths',
      message: 'Image wajib diupload minimal 1',
    },
    {
      condition: !skuAsset.name,
      field: 'name',
      message: 'Name wajib diisi',
    },
    {
      condition: !productDescription?.length,
      field: 'description',
      message: 'Description wajib diisi',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorSkuAssetForm[field as keyof typeof newErrorSkuAssetForm] = message
    }
  })

  return {
    isSkuAssetValid: isValid,
    newErrorSkuAssetForm,
  }
}

const isFieldRejectedOnSkuAssetForm = (skuAsset: AssetType) => {
  const paramsToCheck = [
    'sku_number_rejected',
    'image_paths_rejected',
    'name_rejected',
    'description_rejected',
    'label_image_rejected',
    'label_promotion_rejected',
    'is_qr_rejected',
  ] as const
  return paramsToCheck.some((fieldname) => skuAsset[fieldname])
}

const uploadImagePromises = (
  productImageList: SkuRegistrationInitialStateType['modalSkuMerchandising']['productImageList'],
  indexWithFile: number[],
  userData: { id: number },
) =>
  indexWithFile.map(async (index) => {
    const fileData = productImageList[index].file

    const {
      data: { data },
    } = await postImage('product-images', {
      file_input: fileData as never,
      created_by: userData.id,
    })

    const imagePath = {
      path_large: '',
      path_medium: '',
      path_small: '',
    }
    data.images.forEach((item) => {
      if (item.type === 'small') {
        imagePath.path_small = item.filePath
      }
      if (item.type === 'medium') {
        imagePath.path_medium = item.filePath
      }
      if (item.type === 'large') {
        imagePath.path_large = item.filePath
      }
    })
    return {
      imagePath,
      index,
    }
  })

export const uploadImageAndSubmitData =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail, modalSkuMerchandising } = getState().skuRegistration
    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType
    const { userData } = getState().auth
    const { productImageList, productDescription, skuAssetForm } = modalSkuMerchandising

    const indexWithFile = productImageList.reduce((acc: number[], item, index) => {
      if (item.file) {
        acc.push(index)
      }
      return acc
    }, [])

    const uploadPromises: Promise<{
      imagePath: {
        path_large: string
        path_medium: string
        path_small: string
      }
      index: number
    }>[] = uploadImagePromises(productImageList, indexWithFile, userData)

    dispatch(setModalSkuMerchandising({ isSubmittingData: true }))
    Promise.all(uploadPromises)
      .then((responseData) => {
        const imagePaths = productImageList.map((item) => {
          if (item.file)
            return {
              path_large: '',
              path_medium: '',
              path_small: '',
            }
          return {
            path_large: item.pathLarge,
            path_medium: item.pathMedium,
            path_small: item.pathSmall,
          }
        })

        responseData.forEach(({ imagePath, index }) => {
          imagePaths[index] = imagePath
        })
        const dataToSubmit = {
          ...skuRegisDetail,
          asset: {
            ...skuAssetForm,
            is_qr: false,
            is_qr_rejected: false,
            description: productDescription,
            label_image: !skuAssetForm.label_image?.id ? null : skuAssetForm.label_image,
            label_promotion: !skuAssetForm.label_promotion?.id
              ? null
              : skuAssetForm.label_promotion,
            image_paths: imagePaths.filter(
              (item) => item.path_small && item.path_medium && item.path_large,
            ),
          },
        }

        return submitProductRegistration(skuRegisDetail.id, dataToSubmit)
      })
      .then(() => {
        toastSuccess('Data sku registration berhasil disimpan')
        dispatch(resetModalSkuMerchandising())
        dispatch(actFetchSkuRegistration())
      })
      .catch((err) => {
        callErrorMsg(err)
      })
      .finally(() => {
        dispatch(setModalSkuMerchandising({ isSubmittingData: false }))
      })
  }

export const actValidateSkuAssetAndSubmit =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { modalSkuMerchandising } = getState().skuRegistration
    const { skuAssetForm } = modalSkuMerchandising

    const { isSkuAssetValid, newErrorSkuAssetForm } = isSkuAssetFormValid(
      skuAssetForm,
      modalSkuMerchandising,
    )
    dispatch(setErrorSkuAssetForm(newErrorSkuAssetForm))

    if (!isSkuAssetValid) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }

    const isRejectedSkuAsset = isFieldRejectedOnSkuAssetForm(skuAssetForm)
    if (isRejectedSkuAsset) {
      toastFailed('Ada field yg masih rejected, silahkan perbaiki terlebih dahulu')
      return
    }

    dispatch(uploadImageAndSubmitData())
  }
