import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GetInventoriesFeatureFlagResponseType } from 'features/Product/services/stockDetail'

export type ProductStockDetailSliceType = {
  isFetchingFeatureFlag: boolean
  inventoriesFeatureFlag: GetInventoriesFeatureFlagResponseType
}

const initialState: ProductStockDetailSliceType = {
  isFetchingFeatureFlag: false,
  inventoriesFeatureFlag: {
    is_qr: false,
    is_tanggung_renteng: false,
    manage_ed: false,
    is_multi_sku: false,
  },
}

const productStockDetailSlice = createSlice({
  name: 'productStockDetail',
  initialState,
  reducers: {
    setIsFetchingFeatureFlag: (state, action: PayloadAction<boolean>) => {
      state.isFetchingFeatureFlag = action.payload
    },
    setInventoriesFeatureFlag: (
      state,
      action: PayloadAction<GetInventoriesFeatureFlagResponseType>,
    ) => {
      state.inventoriesFeatureFlag = action.payload
    },
  },
})

export const { setIsFetchingFeatureFlag, setInventoriesFeatureFlag } =
  productStockDetailSlice.actions
export default productStockDetailSlice.reducer
