import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ServiceStateType,
  FeatureStateType,
  GetListBulkUploadResponseType,
  GetListBulkUploadRequestType,
} from 'utils/apiList/bulkUploadV2'
import {
  SLICE_NAME,
  fetchServiceList,
  fetchFeatureList,
  fetchBulkUploadTransactionList,
  createBulkUploadTransaction,
} from './bulkUploadV2Thunk'

export interface BulkUploadV2StateType {
  isLoading: {
    serviceList: boolean
    featureList: boolean
    bulkUploadList: boolean
    uploadingData: boolean
  }
  selectedService: ServiceStateType
  selectedFeature: FeatureStateType
  queryParams: GetListBulkUploadRequestType
  serviceList: ServiceStateType[]
  featureList: FeatureStateType[]
  bulkUploadTransactionList: GetListBulkUploadResponseType[]
  isModalUploadOpen: boolean
  needToReload: boolean
  hasAccessToBulkUploadPage: boolean
  hasAccessToBulkUploadFeature: boolean
  modalNoAccess: {
    isOpen: boolean
    featureName: string
  }
  modalInvoice: {
    isOpen: boolean
    invoiceList: string[]
  }
  hasErrorOnFetchBulkUploadList: boolean
}

const initialState: BulkUploadV2StateType = {
  isLoading: {
    serviceList: true,
    featureList: false,
    bulkUploadList: false,
    uploadingData: false,
  },
  selectedService: {
    id: 0,
    name: '',
  },
  selectedFeature: {
    id: 0,
    name: '',
    template_url: '',
    file_type: '',
  },
  queryParams: {
    page_index: 1,
    page_size: 20,
    service_id: 0,
    service_feature_id: 0,
  },
  serviceList: [],
  featureList: [],
  bulkUploadTransactionList: [],
  isModalUploadOpen: false,
  needToReload: false,
  hasAccessToBulkUploadPage: true,
  hasAccessToBulkUploadFeature: true,
  modalNoAccess: {
    isOpen: false,
    featureName: '',
  },
  modalInvoice: {
    isOpen: false,
    invoiceList: [],
  },
  hasErrorOnFetchBulkUploadList: false,
}

const bulkUploadV2Slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetState: () => initialState,
    setQueryParams: (
      state,
      action: PayloadAction<Partial<BulkUploadV2StateType['queryParams']>>,
    ) => {
      state.queryParams = { ...state.queryParams, ...action.payload }
    },
    setSelectedService: (state, action: PayloadAction<ServiceStateType>) => {
      const { id } = action.payload
      state.selectedService = action.payload
      state.queryParams.service_id = id
    },
    setSelectedFeature: (state, action: PayloadAction<FeatureStateType>) => {
      const { id } = action.payload
      state.selectedFeature = action.payload
      state.queryParams.service_feature_id = id
    },
    toggleModalUpload: (state, action: PayloadAction<boolean>) => {
      state.isModalUploadOpen = action.payload
    },
    toggleModalInvoice: (state, action: PayloadAction<{ isOpen: boolean; data: string[] }>) => {
      const { isOpen, data } = action.payload
      state.modalInvoice.isOpen = isOpen
      state.modalInvoice.invoiceList = data
    },
    toggleModalNoAccess: (
      state,
      action: PayloadAction<{ isOpen: boolean; featureName?: string }>,
    ) => {
      const { isOpen, featureName } = action.payload
      state.modalNoAccess.isOpen = isOpen
      state.modalNoAccess.featureName = featureName || ''
    },
    setNeedToReload: (state, action: PayloadAction<boolean>) => {
      state.needToReload = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceList.pending, (state) => {
        state.isLoading.serviceList = true
      })
      .addCase(fetchServiceList.fulfilled, (state, action) => {
        state.isLoading.serviceList = false
        state.serviceList = action.payload
        if (state.serviceList.length) {
          const dataIndexZero = state.serviceList.find((_, index) => index === 0)
          state.selectedService = dataIndexZero as never
          state.queryParams.service_id = dataIndexZero?.id || 0
        }
        state.hasAccessToBulkUploadPage = !!state.serviceList.length
      })
      .addCase(fetchServiceList.rejected, (state) => {
        state.isLoading.serviceList = false
      })
      .addCase(fetchFeatureList.pending, (state) => {
        state.isLoading.featureList = true
        state.selectedFeature = initialState.selectedFeature
        state.queryParams.service_feature_id = initialState.queryParams.service_feature_id

        state.hasAccessToBulkUploadFeature = true
        state.bulkUploadTransactionList = []
      })
      .addCase(fetchFeatureList.fulfilled, (state, action) => {
        const { data, featureIdToTriggerFetchList } = action.payload
        state.isLoading.featureList = false
        state.featureList = action.payload.data

        if (featureIdToTriggerFetchList) {
          const selectedFeature = data.find((item) => item.id === featureIdToTriggerFetchList)
          if (selectedFeature) {
            state.queryParams.service_feature_id = featureIdToTriggerFetchList
            state.selectedFeature = selectedFeature
          } else state.hasAccessToBulkUploadFeature = false
        }
      })
      .addCase(fetchFeatureList.rejected, (state) => {
        state.isLoading.featureList = false
      })
      .addCase(fetchBulkUploadTransactionList.pending, (state) => {
        state.isLoading.bulkUploadList = true
        state.hasErrorOnFetchBulkUploadList = false
      })
      .addCase(fetchBulkUploadTransactionList.fulfilled, (state, action) => {
        state.isLoading.bulkUploadList = false
        state.bulkUploadTransactionList = action.payload
        state.needToReload = false
      })
      .addCase(fetchBulkUploadTransactionList.rejected, (state) => {
        state.isLoading.bulkUploadList = false
        state.hasErrorOnFetchBulkUploadList = true
        state.needToReload = false
      })
      .addCase(createBulkUploadTransaction.pending, (state) => {
        state.isLoading.uploadingData = true
      })
      .addCase(createBulkUploadTransaction.fulfilled, (state) => {
        state.isLoading.uploadingData = false
        state.isModalUploadOpen = false
        state.needToReload = true
      })
      .addCase(createBulkUploadTransaction.rejected, (state) => {
        state.isLoading.uploadingData = false
      })
  },
})

export default bulkUploadV2Slice.reducer
export const {
  resetState,
  setQueryParams,
  setSelectedService,
  setSelectedFeature,
  toggleModalUpload,
  toggleModalInvoice,
  toggleModalNoAccess,
  setNeedToReload,
} = bulkUploadV2Slice.actions
