import { memo } from 'react'
import styled from 'styled-components'
import { TextBody, Toggle, SystemIcon, Tag, Button } from 'components'
import { formatMoneyRMG } from 'helpers'
import dayjs from 'dayjs'

function RowPromotion({
  data,
  handleChangeActive,
  handleEdit,
  isAllowedForUpdateStatusVoucher,
  isAllowedForEditVoucher,
}) {
  const convertToDate = () => `${dayjs(data?.voucher_created_at).format('DD MMM YYYY - HH:mm')} `
  return (
    <Tr>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.voucher_code}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {convertToDate()}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.benefit_type}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {data.targeting_type}
        </TextBody>
      </Td>
      <Td ratio="2" className="status-wrapper">
        {data.voucher_status && (
          <Tag
            color={
              data.voucher_status === 'Expired'
                ? 'gray'
                : data.voucher_status === 'Active'
                ? 'green'
                : 'gray'
            }
          >
            {data.voucher_status}
          </Tag>
        )}
      </Td>
      <Td ratio="2">
        <TextBody weight="light" color="textSoft">
          {data.voucher_created_by}
        </TextBody>
      </Td>
      <Td ratio="3">
        <TextBody weight="light" color="textSoft">
          {formatMoneyRMG(data.voucher_amount, 'Rp')}
        </TextBody>
      </Td>
      <Td ratio="1">
        <ActionWrapper>
          <Toggle
            active={data.voucher_active}
            onClick={() => handleChangeActive(data)}
            disabled={!isAllowedForUpdateStatusVoucher}
          />
          {isAllowedForEditVoucher && (
            <Button variant="wrapper" onClick={() => handleEdit(data)}>
              <SystemIcon className="pointer" colorIcon="main" iconName="edit" />
            </Button>
          )}
        </ActionWrapper>
      </Td>
    </Tr>
  )
}
export default memo(RowPromotion)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  .input-container {
    width: calc(100% - 20px);
  }
`

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  /* padding-right: ${({ pr }) => pr}; */
`

const ActionWrapper = styled.div`
  display: flex;
  i {
    margin-left: 12px;
  }
`
