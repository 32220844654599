/* eslint-disable @typescript-eslint/naming-convention */
import {
  GetSkuRegistrationListResponseType,
  GetCreatorsSkuRegistrationResponseType,
  GetAllConstantType,
  SkuRegistrationItemType,
} from 'features/Product/@types/SkuRegistration'

import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetSkuRegistrationListParamsType = {
  'pagination.page_index': number
  'pagination.page_size': number
  'pagination.direction': 'asc' | 'desc'
  'filter.sku_number'?: string
  'filter.status'?: string
  'filter.date_from'?: Nullable<string>
  'filter.date_to'?: Nullable<string>
  'filter.l1_category_id'?: Nullable<number>
  'filter.l2_category_id'?: Nullable<number>
  'filter.creator_id'?: Nullable<number>
}

export type GetSkuRegistrationCreatorListParamsType = {
  'pagination.page_index': number
  'pagination.page_size': number
  'filter.name'?: string
}

export const getSkuRegistrationList = (params: GetSkuRegistrationListParamsType) =>
  axiosInstanceApiGateway.Get<GetSkuRegistrationListResponseType, true>({
    url: `/ims/internal/v1/product-registrations`,
    params,
  })

export const getSkuRegistrationCreatorList = (params: GetSkuRegistrationCreatorListParamsType) =>
  axiosInstanceApiGateway.Get<GetCreatorsSkuRegistrationResponseType, true>({
    url: '/ims/internal/v1/product-registrations/creators',
    params,
  })

export const getSkuRegistrationDetail = (id: string) =>
  axiosInstanceApiGateway.Get<{ data: SkuRegistrationItemType }, true>({
    url: `/ims/internal/v1/product-registrations/${id}`,
  })

export const getAllConstant = () =>
  axiosInstanceApiGateway.Get<GetAllConstantType>({ url: `/ims/internal/v1/constants` })

export const submitProductRegistration = (id: string, data: SkuRegistrationItemType) =>
  axiosInstanceApiGateway.Put({
    url: `/ims/internal/v1/product-registrations/${id}/submit`,
    data,
  })

export const cancelSkuRegistration = (id: string) =>
  axiosInstanceApiGateway.Put({
    url: `/ims/internal/v1/product-registrations/${id}/cancel`,
  })

export const rejectSkuRegistration = (id: string, data: SkuRegistrationItemType) =>
  axiosInstanceApiGateway.Put({
    url: `/ims/internal/v1/product-registrations/${id}/reject`,
    data,
  })

export const approveSkuRegistration = (id: string) =>
  axiosInstanceApiGateway.Put({
    url: `ims/internal/v1/product-registrations/${id}/approve`,
  })
