/* eslint-disable @typescript-eslint/naming-convention */
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type PackageStructureItemType = {
  id: string
  to_unit: {
    id: string
    description: string
    group: string
    active: boolean
  }
  product_id: number
  multiplier: number
  barcode: string
  purchase_price: number
  purchase_currency: string
  is_selling_unit: boolean
  is_selling_unit_secondary: boolean
  multiplier_range_start: number
  multiplier_range_end: number
  width: number
  height: number
  length: number
  dimension_unit: {
    id: string
    description: string
    group: string
    active: boolean
  }
  volume: number
  volume_unit: {
    id: string
    description: string
    group: string
    active: boolean
  }
  net_weight: number
  gross_weight: number
  weight_unit: {
    id: string
    description: string
    group: string
    active: boolean
  }
  created_by: string
  updated_by: string
  created_at: number
  updated_at: number
}

export type PackageStructureResponseType = {
  pagination: PaginationType
  data: PackageStructureItemType[]
}

export type SavePackageStructureRequestType = {
  id?: string
  to_unit_id: string
  product_id: number
  multiplier: number
  barcode: string
  purchase_price: number
  purchase_currency: string
  is_selling_unit: boolean
  is_selling_unit_secondary: boolean
  multiplier_range_start?: number
  multiplier_range_end?: number
  width: number
  height: number
  length: number
  dimension_unit: string
  volume: number
  volume_unit: string
  net_weight: number
  gross_weight: number
  weight_unit: string
}

type GetPackageStructureParamsType = {
  'pagination.page_size': number
  'pagination.page_index': number
}

export const getPackageStructure = (productId: number, params: GetPackageStructureParamsType) =>
  axiosInstanceApiGateway.Get<PackageStructureResponseType, true>({
    url: `/ims/internal/v1/products/${productId}/package-structures`,
    params,
  })

export const deletePackageStructure = (id: number) =>
  axiosInstanceApiGateway.Delete({ url: `/ims/internal/v1/products/package-structures/${id}` })

export const savePackageStructure = (data: SavePackageStructureRequestType) =>
  axiosInstanceApiGateway.Put<{ message: string }, true>({
    url: `/ims/internal/v1/products/package-structures`,
    data,
  })
