import { memo, useContext, useEffect, useState, useMemo } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import styled from 'styled-components'
import { RowHubProductDetail } from 'components'
import store from 'storeContext'
import { getRakByLocationID } from 'storeContext/actions/rack'
import getParams from 'utils/getParams'
import useAllowedAccessFor from 'customHooks/useAllowedAccessFor'
import allowedRoleListhubProductDetail from 'middleware/allowedRoleOnFeature/productAndStock/hubProductDetail'
import allowedRoleListWarehouseProductDetail from 'middleware/allowedRoleOnFeature/productAndStock/warehouseProductDetail'
import { getInventoryStatusAction } from 'storeContext/actions/hubDetail'

import { Typography } from '@astro-ui/components'
import { useLocation } from 'react-router-dom'

function TableHubProductDetail({
  allowedForViewDetailInSearching,
  allowedForEditQuantity,
  allowedForUpdateFieldInRow,
  onPrint,
  onInlineUpdate,
  onUpdate,
  data,
  handleShowModalUpdateToAvailable,
  hanldeShowModalUpdateExpiryDate,
}) {
  const { dispatch: dispatchRak } = useContext(store.RakContext)
  const { state: stateHubDetail, dispatch: dispatchHubDetail } = useContext(store.HubDetailContext)
  const {
    userData: { authorities },
    productStockDetail,
  } = useSelector(
    (state) => ({
      userData: state.auth.userData,
      productStockDetail: state.productStockDetail,
    }),
    shallowEqual,
  )
  const { inventoriesFeatureFlag, isFetchingFeatureFlag } = productStockDetail

  const location = useLocation()

  const locationType = useMemo(() => {
    return location.pathname.split('/')[2]
  }, [location.pathname])

  const allowedForEditExpiryDateHub = useAllowedAccessFor(
    authorities,
    allowedRoleListhubProductDetail.updateExpiryDate,
  )

  const allowedForEditExpiryDateWarehouse = useAllowedAccessFor(
    authorities,
    allowedRoleListWarehouseProductDetail.updateExpiryDate,
  )

  const allowedForEditExpiryDate = useMemo(() => {
    if (locationType === 'hub') return allowedForEditExpiryDateHub
    if (locationType === 'warehouse') return allowedForEditExpiryDateWarehouse
    return false
  }, [authorities])

  const allowedForEditPopupUpdateLostToAvailableHub = useAllowedAccessFor(
    authorities,
    allowedRoleListhubProductDetail.updateLostToAvailable,
  )

  const allowedForEditPopupUpdateLostToAvailableWarehouse = useAllowedAccessFor(
    authorities,
    allowedRoleListWarehouseProductDetail.updateLostToAvailable,
  )
  const allowedForEditPopupUpdateLostToAvailable = useMemo(() => {
    if (locationType === 'hub') return allowedForEditPopupUpdateLostToAvailableHub
    if (locationType === 'warehouse') return allowedForEditPopupUpdateLostToAvailableWarehouse
    return false
  }, [authorities])

  const { inventoryStatusData, inventoryNoteByStatusData } = stateHubDetail

  let formatFormsListOfNumbAndPosRak = []

  let listStatus = []
  let listNoteByStatus = []

  const initFormsListOfNumbAndPosRak = [formatFormsListOfNumbAndPosRak]
  const [formsListOfNumbAndPosRak, setFormsListOfNumbAndPosRak] = useState(
    initFormsListOfNumbAndPosRak,
  )

  const localState = {
    setFormsListOfNumbAndPosRak: setFormsListOfNumbAndPosRak,
  }

  formatFormsListOfNumbAndPosRak = formsListOfNumbAndPosRak?.content?.map((value) => {
    return {
      name: value?.rack_name,
      id: value?.id,
    }
  })

  listStatus = inventoryStatusData?.map((value) => {
    return {
      name: value?.displayName,
      id: value?.id,
    }
  })

  listNoteByStatus = inventoryNoteByStatusData?.map((value) => {
    return {
      name: value?.displayName,
      id: value?.id,
    }
  })

  useEffect(() => {
    dispatchRak(getRakByLocationID(getParams('location_id'), localState))
    dispatchHubDetail(getInventoryStatusAction())
  }, [])

  return (
    <Container>
      <Table>
        <thead>
          <Tr>
            <Th ratio="1" minWidth="200px">
              <Typography variant="h6" color="grey">
                NOMOR SKU
              </Typography>
            </Th>
            <Th ratio="1" minWidth="115px">
              <Typography variant="h6" color="grey">
                JUMLAH
              </Typography>
            </Th>
            <Th ratio="1" minWidth="200px">
              <Typography variant="h6" color="grey">
                SLOC
              </Typography>
            </Th>
            <Th ratio="1" minWidth="150px">
              <Typography variant="h6" color="grey">
                PACKAGE ID
              </Typography>
            </Th>
            <Th ratio="2" minWidth="200px">
              <Typography variant="h6" color="grey">
                STATUS
              </Typography>
            </Th>
            <Th ratio="2" minWidth="200px">
              <Typography variant="h6" color="grey">
                CATATAN
              </Typography>
            </Th>
            {inventoriesFeatureFlag.is_tanggung_renteng && (
              <Th ratio="2" minWidth="200px">
                <Typography variant="h6" color="grey">
                  PERIODE
                </Typography>
              </Th>
            )}
            <Th ratio="2" minWidth="100px">
              <Typography variant="h6" color="grey">
                TGL EXP
              </Typography>
            </Th>
            <Th ratio="1" minWidth="220px">
              <Typography variant="h6" color="grey">
                ACTION
              </Typography>
            </Th>
          </Tr>
        </thead>
        <tbody>
          {!stateHubDetail.isLoading &&
            data?.map((items, index) => {
              if (parseInt(items?.stock) > 0) {
                return (
                  <RowHubProductDetail
                    isFetchingFeatureFlag={isFetchingFeatureFlag}
                    flagging={inventoriesFeatureFlag}
                    allowedForViewDetailInSearching={allowedForViewDetailInSearching}
                    allowedForUpdateFieldInRow={allowedForUpdateFieldInRow}
                    allowedForEditExpiryDate={allowedForEditExpiryDate}
                    allowedForEditQuantity={allowedForEditQuantity}
                    allowedForEditPopupUpdateLostToAvailable={
                      allowedForEditPopupUpdateLostToAvailable
                    }
                    onPrint={() => {
                      onPrint({
                        stock: items?.stock,
                        expiry_date: items?.expiry_date,
                        productId: items?.product_id,
                      })
                    }}
                    onInlineUpdate={(formData, toSloc, tanggungRenteng, options) =>
                      onInlineUpdate(formData, toSloc, tanggungRenteng, { ...options, index })
                    }
                    onUpdate={() => {
                      onUpdate({
                        stock: items?.stock,
                        rack_id: items?.rack_id,
                        rack_name: items?.rack_name,
                        expiry_date: items?.expiry_date,
                        sloc: items?.rack_name,
                        productDetailId: items?.product_detail_id,
                        status_name: items?.status_name,
                        status_id: items?.status_id,
                        status_notes_id: items?.status_notes_id,
                        status_notes_name: items?.status_notes_name,
                        package_id: items?.package_id,
                        package_id_enable: items?.package_id_enable,
                        updated_expiry_date: items?.updated_expiry_date,
                        required_expiry_date: items?.required_expiry_date,
                        enable_transfer: items?.enable_transfer,
                        package_label: items?.package_label,
                      })
                    }}
                    allData={data}
                    dataRack={formatFormsListOfNumbAndPosRak}
                    dataStatus={listStatus}
                    dataNote={listNoteByStatus}
                    data={items}
                    key={index + 'sas'}
                    handleShowModalUpdateToAvailable={handleShowModalUpdateToAvailable}
                    hanldeShowModalUpdateExpiryDate={hanldeShowModalUpdateExpiryDate}
                  />
                )
              }
            })}
        </tbody>
      </Table>
    </Container>
  )
}

export default memo(TableHubProductDetail)

const Container = styled.div`
  overflow: auto;
  min-height: ${({ minHeight }) => `${minHeight + 12}px`};
  border: 1px solid #e4eaf3;
  border-radius: 4px;
  background-color: white;
`

const Table = styled.table`
  width: 100%;
`

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`

const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding-right: 12px;
  min-width: ${({ minWidth }) => minWidth};
  white-space: nowrap;
`
