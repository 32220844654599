import { memo, useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { SystemIcon, Checkbox, TextBody, Input, Button, InputDropDown, Tooltip } from 'components'
import { formatMoneyRMG, numberOnly, numberDecimalOnly } from 'helpers'
import { getParams, checkMaxPriceAfterDiscount } from './helper'
import store from 'storeContext'
import {
  deleteCampaingSingleandAll_CAMPAIGN_ADD,
  editCampaignInventoryByItem_CAMPAIGN_ADD,
} from 'storeContext/actions/campaign/campaignAdd'
import chevronDown from 'assets/images/chevron_down.svg'
import { useRowCampaignAddManual } from './useRowCampaignAdd'

function RowCampaignAdd({
  data,
  stateCollections,
  actionList,
  isInProgressUpload,
  isChild,
  isShow,
  segmentOptions,
  isAllowedForSaveProductInvetory,
  isAllowedForDeleteProductInvetory,
}) {
  const { dispatch: dispatchCampaign, state: stateCampaign } = useContext(store.CampaignContext)

  const {
    state: { dataSegmentList },
  } = useRowCampaignAddManual()

  const handleClickClose = () => {
    actionList.handleClearListOfSelectedData()
  }

  const { listOfSelectedData } = stateCollections.getter
  const { setListOfSelectedData } = stateCollections.setter

  const initChecked = false
  const initCurrentForm = {
    discountedPrice: 0,
    discountPercentage: 0,
    discountQuota: 0,
    dailyQuota: 0,
    astroPercentage: 0,
    globalDailyQuota: 0,
  }

  const [checked, setChecked] = useState(initChecked)
  const [currentForm, setCurrentForm] = useState(initCurrentForm)

  const campaignPriceDetailLength = data.campaignPriceDetails.length

  //EFFECT HOOKS
  useEffect(() => {
    const initCheckedOnFirstRender = [...listOfSelectedData].some((val) => {
      if (!isChild && campaignPriceDetailLength > 1) return false
      if (!isChild && campaignPriceDetailLength === 1)
        return val.uniqueId === data.campaignPriceDetails[0].uniqueId
      return val.uniqueId === data.uniqueId
    })
    if (initCheckedOnFirstRender) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [listOfSelectedData])
  useEffect(() => {
    setCurrentForm((prev) => {
      return { ...data }
    })
  }, [stateCampaign.campaignAdd_PaginationData])

  const handleChecked = (e) => {
    const { value } = e.target
    const getCurrentRealValue = value === 'true' ? false : true

    setChecked((prev) => !prev)
    if (getCurrentRealValue) {
      setListOfSelectedData((prev) => {
        const modifiedData = () => {
          if (!isChild && campaignPriceDetailLength === 1) return data.campaignPriceDetails[0]
          return data
        }
        return [...prev, modifiedData()]
      })
    } else {
      setListOfSelectedData((prev) => {
        const newData = [...prev].filter((val) => {
          if (!isChild && campaignPriceDetailLength === 1)
            return val.uniqueId !== data.campaignPriceDetails[0].uniqueId
          return data.uniqueId !== val.uniqueId
        })
        return newData
      })
    }
  }

  const calcPricFromPercent = (percentage, origin_price) => {
    const percentPriceAfterDisc = (percentage / 100) * origin_price
    const resultFinalPrice = origin_price - percentPriceAfterDisc
    return resultFinalPrice
  }

  const caclulatePercentage = (current_price, original_price) => {
    const resultPerc = (current_price / original_price) * 100

    return 100 - resultPerc
  }

  const handleChange = (e) => {
    const { value, name } = e.target
    if (name === 'discountPercentage') {
      setCurrentForm((prev) => {
        return {
          ...prev,
          [name]: value,
          discountedPrice: calcPricFromPercent(value, data.originalPrice),
        }
      })
    } else if (name === 'discountedPrice') {
      setCurrentForm((prev) => {
        return {
          ...prev,
          [name]: value,
          discountPercentage: caclulatePercentage(value, data.originalPrice),
        }
      })
    } else if (name === 'astroPercentage') {
      setCurrentForm((prev) => {
        return {
          ...prev,
          [name]: value ? numberDecimalOnly(value) : 0,
        }
      })
    } else {
      setCurrentForm((prev) => {
        return {
          ...prev,
          [name]: value ? parseInt(numberOnly(value)) : 0,
        }
      })
    }
  }

  const handleClickEdit = () => {
    const campaignInventoryIds = data.campaignInventoryId
    const dataToSend = {
      discounted_price: currentForm.discountedPrice,
      discount_percentage: currentForm.discountPercentage,
      discount_stock: currentForm.discountQuota,
      daily_quota: currentForm.dailyQuota,
      user_segment: currentForm.userSegment,
      astro_percentage: currentForm.astroPercentage,
      segment_id: currentForm.segmentData.segmentId,
      segment_name: currentForm.segmentData.segmentName,
      global_daily_quota: currentForm.globalDailyQuota,
    }

    if (checkMaxPriceAfterDiscount(dataToSend.discountedPrice, data.originalPrice)) {
      dispatchCampaign(
        editCampaignInventoryByItem_CAMPAIGN_ADD(campaignInventoryIds, {
          dataToSend: dataToSend,
          handleClickClose: () => {},
        }),
      )
    }
  }

  const handleClickDelete = (e) => {
    const dataToSend = [data.campaignInventoryId]
    const localState = {
      dataToSend: dataToSend,
      handleClickClose: handleClickClose,
    }
    dispatchCampaign(deleteCampaingSingleandAll_CAMPAIGN_ADD(getParams('campaignId'), localState))
  }

  const handleClickSelectSegment = (val) => {
    setCurrentForm((prev) => {
      return { ...prev, userSegment: val.value }
    })
  }

  const handleSelectSegment = (val) => {
    setCurrentForm((prev) => {
      return {
        ...prev,
        segmentData: {
          segmentId: val.id,
          segmentName: val.name,
        },
      }
    })
  }

  const isRenderContentColumn =
    (campaignPriceDetailLength === 1 && !isChild && data.userSegment !== 'TARGETED_USER') ||
    ((campaignPriceDetailLength > 1 || data.userSegment === 'TARGETED_USER') && isChild)

  const isHeaderSingle =
    campaignPriceDetailLength === 1 && data.userSegment !== 'TARGETED_USER' && !isChild
  const isHeaderContentColumn =
    (campaignPriceDetailLength > 1 || data.userSegment === 'TARGETED_USER') && !isChild
  const isContentColumn =
    (campaignPriceDetailLength > 1 || data.userSegment === 'TARGETED_USER') && isChild

  return (
    <Tr isChild={isChild} isShow={!isChild ? true : isShow}>
      <Td ratio="1">
        <WrapperCheck>
          {isHeaderContentColumn && (
            <div onClick={() => actionList.handleClickShow(data.uniqueId)}>
              <S.ChevronImg
                isDown={data.isShowRow}
                src={chevronDown}
                style={{ width: '18px', height: '18px' }}
              />
            </div>
          )}
          {isHeaderSingle && (
            <Checkbox
              disabled={isInProgressUpload}
              value={checked}
              checked={checked}
              onChange={handleChecked}
            />
          )}
        </WrapperCheck>
      </Td>
      <Td ratio="3">
        {!isChild && (
          <TextBody size="smaller" weight="light">
            {data?.locationName}
          </TextBody>
        )}
        {isContentColumn && (
          <WrapperCheck>
            <Checkbox
              disabled={isInProgressUpload}
              value={checked}
              checked={checked}
              onChange={handleChecked}
            />
          </WrapperCheck>
        )}
      </Td>
      <Td ratio="5">
        {!isChild && (
          <ProductTextWrapper>
            <TextBody size="smaller" weight="light">
              {data?.productName}
            </TextBody>
            <SKUWrapper>
              <TextBody style={{ width: '50px' }} size="smaller" weight="light">
                SKU :
              </TextBody>
              <TextBody size="smaller" weight="light">
                {data?.productSkuNumber}
              </TextBody>
            </SKUWrapper>
          </ProductTextWrapper>
        )}
      </Td>
      <Td ratio="3">
        {!isChild && (
          <InputDropDown
            listOptions={dataSegmentList}
            value={
              campaignPriceDetailLength === 1 ? currentForm?.userSegment?.replace('_', ' ') : ''
            }
            placeholder={
              campaignPriceDetailLength > 1
                ? `${campaignPriceDetailLength} Segment Terpilih`
                : 'Pilih'
            }
            handleClickOption={handleClickSelectSegment}
          />
        )}
        {isChild &&
          (data?.userSegment === 'TARGETED_USER' ? (
            <Tooltip content={currentForm?.segmentData?.segmentName} direction={'top'}>
              <InputDropDown
                listOptions={segmentOptions}
                value={currentForm?.segmentData?.segmentName}
                placeholder={''}
                handleClickOption={(e) => {
                  handleSelectSegment(e)
                }}
              />
            </Tooltip>
          ) : (
            <TextBody
              size="smaller"
              weight="light"
              style={{ textTransform: 'capitalize' }}
              color="blueSoft"
            >
              {data?.userSegment.replace('_', ' ').toLowerCase()}
            </TextBody>
          ))}
      </Td>
      <Td ratio="2">
        <TextBody size="smaller" weight="light">
          {formatMoneyRMG(data?.originalPrice, 'Rp')}
        </TextBody>
      </Td>
      <Td ratio="1">
        <TextBody size="smaller" weight="light">
          {isRenderContentColumn && data?.productStock}
        </TextBody>
      </Td>
      <Td ratio="3">
        {!isChild && (
          <StyledInput
            disabled={isInProgressUpload}
            customIcon="%"
            bgIcon="body"
            iconPos="right"
            name="astroPercentage"
            value={currentForm?.astroPercentage}
            onChange={handleChange}
            max="150px"
          />
        )}
      </Td>
      <Td ratio="4">
        {isRenderContentColumn && (
          <StyledInput
            disabled={isInProgressUpload}
            customIcon="Rp"
            bgIcon="body"
            iconPos="left"
            name="discountedPrice"
            value={currentForm?.discountedPrice}
            onChange={handleChange}
            max="150px"
          />
        )}
        {isHeaderContentColumn &&
          currentForm?.astroPercentage !== data?.astroPercentage &&
          !isChild && (
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '12px' }}>
              <Button disabled={isInProgressUpload} size="small" onClick={handleClickEdit}>
                Simpan
              </Button>
            </div>
          )}
      </Td>
      <Td ratio="3">
        {isRenderContentColumn && (
          <StyledInput
            disabled={isInProgressUpload}
            customIcon="%"
            bgIcon="body"
            iconPos="right"
            name="discountPercentage"
            value={currentForm?.discountPercentage}
            onChange={handleChange}
            max="110px"
          />
        )}
      </Td>
      <Td ratio="3">
        {isRenderContentColumn && (
          <StyledInput
            disabled={isInProgressUpload}
            value={currentForm?.discountQuota}
            onChange={handleChange}
            name="discountQuota"
            placeholder="0"
            max="65px"
          />
        )}
      </Td>{' '}
      <Td ratio="3">
        {isRenderContentColumn && (
          <ActionWrapper>
            <StyledInput
              disabled={isInProgressUpload}
              name="globalDailyQuota"
              value={currentForm?.globalDailyQuota}
              onChange={handleChange}
              placeholder="0"
              max="100px"
            />
          </ActionWrapper>
        )}
      </Td>
      <Td ratio="3">
        {isRenderContentColumn && (
          <>
            <ActionWrapper>
              <StyledInput
                disabled={isInProgressUpload}
                name="dailyQuota"
                value={currentForm?.dailyQuota}
                onChange={handleChange}
                placeholder="0"
                max="100px"
              />
              {isAllowedForDeleteProductInvetory && (
                <div
                  onClick={!isInProgressUpload ? handleClickDelete : undefined}
                  style={{ cursor: 'pointer' }}
                >
                  <SystemIcon iconName="trash" />
                </div>
              )}
            </ActionWrapper>

            {(currentForm?.dailyQuota !== data?.dailyQuota ||
              currentForm?.kuotaHarian !== data?.kuotaHarian ||
              currentForm?.userSegment !== data?.userSegment ||
              currentForm?.segmentData.segmentId.toString() !==
                data?.segmentData.segmentId.toString() ||
              currentForm?.discountQuota !== data?.discountQuota ||
              currentForm?.astroPercentage !== data?.astroPercentage ||
              currentForm?.discountPercentage?.toString() !==
                data?.discountPercentage?.toString() ||
              currentForm?.globalDailyQuota?.toString() !== data?.globalDailyQuota?.toString() ||
              currentForm?.discountedPrice !== data?.discountedPrice) &&
              isAllowedForSaveProductInvetory && (
                <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '12px' }}>
                  <Button
                    disabled={isInProgressUpload || data.isFlashSale}
                    size="small"
                    onClick={handleClickEdit}
                  >
                    Simpan
                  </Button>
                </div>
              )}
          </>
        )}
      </Td>
    </Tr>
  )
}

RowCampaignAdd.defaultProps = {
  data: {},
  isChild: false,
  isShow: true,
}

export default memo(RowCampaignAdd)

const S = {
  ChevronImg: styled.img`
    width: 18px;
    height: 18px;
    ${({ isDown }) => {
      return isDown ? `rotate: 180deg;` : 'rotate: 0deg'
    }}
  `,
}

const Tr = styled.tr`
  display: ${({ isShow }) => (isShow ? 'flex' : 'none')};
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
  width: 100%;
  background: ${({ isChild, theme: { colors } }) => (isChild ? '#EDF4FD' : colors.white)};
`

const Td = styled.td`
  color: ${({ theme: { colors }, color = 'textSoft' }) => colors[color]};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.normal};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding: 12px 4px;
`

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-left: 20px;
  }
`

const WrapperCheck = styled.div`
  display: grid;
  place-items: self-start;
`

const StyledInput = styled(Input)`
  max-width: ${({ max }) => max};
  input {
    padding: 6px 12px;
    height: 40px;
    font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  }
`

const ProductTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const SKUWrapper = styled.div`
  display: flex;
  margin-top: 6px;
`
