import { AppDispatchType } from 'store'
import { getInventoriesFeatureFlag } from 'features/Product/services/stockDetail'
import { callErrorMsg } from 'helpers/errorMsg'
import { setIsFetchingFeatureFlag, setInventoriesFeatureFlag } from './slice'

type ActGetInventoriesFeatureFlagRequestType = {
  productId: number
  locationId: number
  callbackFunction?: (isTanggungRentengActive: boolean) => void
}

export const actGetInventoriesFeatureFlag =
  (params: ActGetInventoriesFeatureFlagRequestType) => async (dispatch: AppDispatchType) => {
    const { callbackFunction, productId, locationId } = params
    dispatch(setIsFetchingFeatureFlag(true))
    try {
      const {
        data: { data },
      } = await getInventoriesFeatureFlag({
        product_id: productId,
        location_id: locationId,
      })
      dispatch(setInventoriesFeatureFlag(data))
      if (callbackFunction) callbackFunction(data.is_tanggung_renteng || false)
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsFetchingFeatureFlag(false))
    }
  }
