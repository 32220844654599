import { AxiosResponse } from 'axios'
import { AppDispatchType, RootStateType } from 'store'
import { CategoryL1Type } from 'features/Product/@types/Product'
import {
  AdditionalInformationType,
  SellingDetailType,
  SkuRegistrationItemType,
} from 'features/Product/@types/SkuRegistration'
import { getProductType, getProductAttributeOption } from 'features/Product/services/product'
import { submitProductRegistration } from 'features/Product/services/skuRegistration'
import { getVendorDetails } from 'features/Enterprise/Vendor/services/addEditVendor'
import { getCategory } from 'utils/api'
import { callErrorMsg } from 'helpers/errorMsg'
import { toastSuccess, toastFailed } from 'utils/toast'
import {
  getValue,
  valueNumberIsEqualToZero,
  valueNumberIsGreaterThanZero,
  getNumberValueIfOptional,
  getStringValueIfOptional,
  isGeneralFormValid,
  isHandlingFormValid,
  isGrammationFormValid,
  isPricingFormValid,
  isFieldRejectedOnGeneralForm,
  isFieldRejectedOnHandlingForm,
  isFieldRejectedOnGrammationForm,
  isFieldRejectedOnPricingForm,
  getDataForSubmitGeneralDataUntilPricing,
  actFetchCategoryChild,
  actResetFilterAndFetchListSkuRegistration,
  actGetLocationDetail,
  actGetLocationListByParams,
} from './thunk'
import {
  SkuRegistrationInitialStateType,
  setBusinessTaggingList,
  setProductTypeList,
  setAdditionalForm,
  setModalAdditionalDetails,
  setSellingDetailForm,
  resetModalUpdateSku,
  resetModalAdditionalDetails,
  setLocationList,
  setVendorDetail,
  setErrorGeneralForm,
  setErrorHandlingForm,
  setErrorGrammationForm,
  setErrorPricingForm,
  setErrorAdditionalForm,
  setErrorSellingDetailForm,
  setModalSelectLocation,
  resetModalSelectLocation,
  setModalUpdateSku,
} from './slice'

export const actFetchBusinessTaggings = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getProductAttributeOption({ type: 'BUSINESS_TAGGING' })
    dispatch(setBusinessTaggingList(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchProductType = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getProductType()
    dispatch(setProductTypeList(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actFetchCategoryDetail = (id: number) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = (await getCategory(id)) as AxiosResponse<{
      min_shelf_life_on_delivery: number
      min_shelf_life_on_receiving: number
      min_shelf_life_to_customer: number
    }>
    dispatch(
      setAdditionalForm({
        mslod: data.min_shelf_life_on_delivery?.toString(),
        mslor: data.min_shelf_life_on_receiving?.toString(),
        msltc: data.min_shelf_life_to_customer?.toString(),
      }),
    )
  } catch (err) {
    callErrorMsg(err)
  }
}

export const actAutofillMslodMslorMsltc =
  () => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { categoryL2List, modalAdditionalDetails } = getState().skuRegistration
    const { additionalForm } = modalAdditionalDetails

    if (
      categoryL2List.length &&
      additionalForm?.l2_category?.id &&
      additionalForm.use_standard_ned
    ) {
      dispatch(actFetchCategoryDetail(additionalForm.l2_category.id))
    }
    if (
      !categoryL2List.length &&
      additionalForm.l1_category.id &&
      additionalForm.use_standard_ned
    ) {
      dispatch(actFetchCategoryDetail(additionalForm.l1_category.id))
    }
    if (additionalForm.use_standard_ned) {
      dispatch(setAdditionalForm({ mslor: '', msltc: '', mslod: '' }))
    }
  }

export const actFetchVendorDetailById = (vendorId: number) => async (dispatch: AppDispatchType) => {
  try {
    const {
      data: { data },
    } = await getVendorDetails(vendorId.toString())
    dispatch(setVendorDetail(data))
    const indexFound = data.vendor_shipping_info.findIndex(
      (item) => item.location_type.toUpperCase() === 'WAREHOUSE',
    )
    if (
      indexFound === -1 ||
      (indexFound !== -1 && !data.vendor_shipping_info[indexFound].order_requirement)
    ) {
      dispatch(
        setErrorSellingDetailForm({
          min_order_by: 'Anda harus melengkapi data warehouse pada vendor detail',
        }),
      )
      return
    }

    if (indexFound !== -1) {
      const { order_requirement, moq_qty, mov } = data.vendor_shipping_info[indexFound]
      dispatch(
        setSellingDetailForm({
          min_order_by: order_requirement,
          min_order_by_rejected: false,
          moq: moq_qty ? +moq_qty : 0,
          moq_rejected: false,
          mov: mov ? +mov : 0,
          mov_rejected: false,
          is_consignment: data.terms.toLowerCase() === 'consignment',
          is_consignment_rejected: false,
        }),
      )
    }
  } catch (err) {
    callErrorMsg(err)
  }
}

const isAdditionalFormValid = (
  additionalData: AdditionalInformationType,
  categoryL2List: CategoryL1Type[],
  // eslint-disable-next-line sonarjs/cognitive-complexity
) => {
  let isValid = true
  const newErrorAdditionalForm = {
    l1_category: '',
    l2_category: '',
    business_taggings: '',
    product_type: '',
    mslod: '',
    msltc: '',
    mslor: '',
    inbound_locations: '',
  }
  const validations = [
    {
      condition: !additionalData.l1_category.id,
      field: 'l1_category',
      message: 'Kategori L1 wajib diisi',
    },
    {
      condition: categoryL2List.length && !additionalData?.l2_category?.id,
      field: 'l2_category',
      message: 'Kategori L2 wajib diisi',
    },
    {
      condition: additionalData.business_taggings.length === 0,
      field: 'business_taggings',
      message: 'Business Tagging wajib dipilih',
    },
    {
      condition: !additionalData.product_type.product_type_id,
      field: 'product_type',
      message: 'Product Type wajib diisi',
    },
    {
      condition:
        !additionalData.use_standard_ned &&
        (!additionalData.mslod || valueNumberIsEqualToZero(additionalData.mslod)),
      field: 'mslod',
      message: 'MSLOD wajib diisi',
    },
    {
      condition:
        !additionalData.use_standard_ned &&
        (!additionalData.msltc || valueNumberIsEqualToZero(additionalData.msltc)),
      field: 'msltc',
      message: 'MSLTC wajib diisi',
    },
    {
      condition:
        !additionalData.use_standard_ned &&
        (!additionalData.mslor || valueNumberIsEqualToZero(additionalData.mslor)),
      field: 'mslor',
      message: 'MSLOR wajib diisi',
    },
    {
      condition: !additionalData.inbound_locations.length,
      field: 'inbound_locations',
      message: 'Inbound WH Destination wajib diisi',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorAdditionalForm[field as keyof typeof newErrorAdditionalForm] = message
    }
  })

  if (additionalData.mslor && additionalData.msltc && additionalData.mslod) {
    const isMslodValidConditionOne = +additionalData.mslod < +additionalData.mslor
    const isMslodValidConditionTwo = +additionalData.mslod > +additionalData.msltc
    const isMsltcValid = +additionalData.msltc < +additionalData.mslod

    if (!additionalData.use_standard_ned && !isMslodValidConditionOne) {
      isValid = false
      newErrorAdditionalForm.mslod = 'MSLOD wajib lebih kecil dari MSLOR'
    } else if (!additionalData.use_standard_ned && !isMslodValidConditionTwo) {
      isValid = false
      newErrorAdditionalForm.mslod = 'MSLOD wajib lebih besar dari MSLTC'
    } else if (!additionalData.use_standard_ned && !isMsltcValid) {
      isValid = false
      newErrorAdditionalForm.msltc = 'MSLTC wajib lebih kecil dari MSLOD'
    }
  }

  return {
    isAdditonalDataValid: isValid,
    newErrorAdditionalForm,
  }
}

const isFieldRejectedOnAdditionalForm = (additionalData: AdditionalInformationType) => {
  const paramsToCheck = [
    'l1_category_rejected',
    'l2_category_rejected',
    'business_taggings_rejected',
    'product_type_rejected',
    'product_age_rejected',
    'use_standard_ned_rejected',
    'msltc_rejected',
    'mslor_rejected',
    'mslod_rejected',
    'inbound_locations_rejected',
  ] as const
  return paramsToCheck.some((param) => additionalData[param])
}

const isSellingDetailFormValid = (
  sellingDetail: SellingDetailType,
  modalSelectLocation: SkuRegistrationInitialStateType['modalSelectLocation'],
) => {
  let isValid = true
  const newErrorSellingDetailForm = {
    proposed_qty: '',
    proposed_location_listing: '',
    proposed_qty_reason: '',
    product_needed_reason: '',
    promotion_term_on_discount: '',
    promotion_term_period: '',
    socmed_presence: '',
    num_of_followers: '',
    min_order_by: '',
  }

  const validations = [
    {
      condition: !sellingDetail.proposed_qty,
      field: 'proposed_qty',
      message: 'Qty Proposed wajib diisi',
    },
    {
      condition: !modalSelectLocation.selectedLocation.length,
      field: 'proposed_location_listing',
      message: 'Proposed # of Hubs Listing wajib diisi',
    },
    {
      condition: !sellingDetail.proposed_qty_reason,
      field: 'proposed_qty_reason',
      message: 'Reason for Qty Proposed wajib diisi',
    },
    {
      condition: !sellingDetail.product_needed_reason,
      field: 'product_needed_reason',
      message: 'Why Astro Need This Product wajib diisi',
    },
    {
      condition:
        (sellingDetail.promotion_term_period_start_date ||
          sellingDetail.promotion_term_period_end_date) &&
        !sellingDetail.promotion_term_on_discount,
      field: 'promotion_term_on_discount',
      message: 'Promotion Term On Discount wajib diisi',
    },

    {
      condition:
        sellingDetail.promotion_term_on_discount &&
        (!sellingDetail.promotion_term_period_start_date ||
          !sellingDetail.promotion_term_period_end_date),
      field: 'promotion_term_period',
      message: 'Promotion Term Period wajib diisi',
    },
    {
      condition:
        sellingDetail.socmed_presence &&
        (!sellingDetail.num_of_followers ||
          valueNumberIsEqualToZero(sellingDetail.num_of_followers)),
      field: 'num_of_followers',
      message: 'Number of Social Media Followers wajib diisi',
    },
    {
      condition:
        !sellingDetail.socmed_presence &&
        valueNumberIsGreaterThanZero(sellingDetail.num_of_followers),
      field: 'socmed_presence',
      message: 'Social media presence waib diisi',
    },
    {
      condition: !sellingDetail.min_order_by,
      field: 'min_order_by',
      message: 'Anda harus melengkapi data warehouse pada vendor detail',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorSellingDetailForm[field as keyof typeof newErrorSellingDetailForm] = message
    }
  })

  return {
    isSellingDataValid: isValid,
    newErrorSellingDetailForm,
  }
}

const isFieldRejectedOnSellingDetailForm = (sellingDetail: SellingDetailType) => {
  const paramsToCheck = [
    'proposed_qty_rejected',
    'proposed_location_listing_rejected',
    'proposed_qty_reason_rejected',
    'min_order_by_rejected',
    'moq_rejected',
    'mov_rejected',
    'is_consignment_rejected',
    'returnable_rejected',
    'product_needed_reason_rejected',
    'promotion_term_on_discount_rejected',
    'promotion_term_period_rejected',
    'socmed_presence_rejected',
    'num_of_followers_rejected',
    'jbp_rejected',
    'exclusive_launch_rejected',
    'pack_size_change_rejected',
  ] as const

  return paramsToCheck.some((param) => sellingDetail[param])
}

const getDataForSubmitAdditionalSellingDetail = (
  modalAdditionalDetails: SkuRegistrationInitialStateType['modalAdditionalDetails'],
  modalSelectLocation: SkuRegistrationInitialStateType['modalSelectLocation'],
) => {
  const { additionalForm, sellingDetailForm } = modalAdditionalDetails
  return {
    additional_information: {
      ...additionalForm,
      l2_category: additionalForm.l2_category?.id ? additionalForm.l2_category : null,
      product_age: getNumberValueIfOptional(additionalForm.product_age),
      msltc: +additionalForm.msltc,
      mslor: +additionalForm.mslor,
      mslod: +additionalForm.mslod,
    },
    selling_detail: {
      ...sellingDetailForm,
      proposed_location_listing: modalSelectLocation.selectedLocation,
      proposed_qty: +sellingDetailForm.proposed_qty,
      promotion_term_on_discount: getStringValueIfOptional(
        sellingDetailForm.promotion_term_on_discount,
      ),
      socmed_presence: getStringValueIfOptional(sellingDetailForm.socmed_presence),
      num_of_followers: getNumberValueIfOptional(sellingDetailForm.num_of_followers),
    },
  }
}

export const actValidateAndSubmitAdditionalModalData =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail, categoryL2List, modalAdditionalDetails, modalSelectLocation } =
      getState().skuRegistration
    const { additionalForm, sellingDetailForm } = modalAdditionalDetails
    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType

    const { isAdditonalDataValid, newErrorAdditionalForm } = isAdditionalFormValid(
      additionalForm,
      categoryL2List,
    )

    const { isSellingDataValid, newErrorSellingDetailForm } = isSellingDetailFormValid(
      sellingDetailForm,
      modalSelectLocation,
    )

    dispatch(setErrorAdditionalForm(newErrorAdditionalForm))
    dispatch(setErrorSellingDetailForm(newErrorSellingDetailForm))

    if (!isAdditonalDataValid || !isSellingDataValid) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }

    const dataToSubmit = {
      ...skuRegisDetail,
      ...getDataForSubmitAdditionalSellingDetail(modalAdditionalDetails, modalSelectLocation),
    }

    dispatch(setModalAdditionalDetails({ isSubmittingData: true }))
    try {
      await submitProductRegistration(skuRegisDetail.id, dataToSubmit)
      dispatch(resetModalAdditionalDetails())
      dispatch(resetModalSelectLocation())
      toastSuccess('Berhasil mengupdate data sku registration')
      dispatch(actResetFilterAndFetchListSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalAdditionalDetails({ isSubmittingData: false }))
    }
  }

export const actInitializeModalAdditionalDetails =
  (responseData: SkuRegistrationItemType) => (dispatch: AppDispatchType) => {
    const { general_data, additional_information, selling_detail } = responseData
    const startDate = selling_detail.promotion_term_period_start_date
    const endDate = selling_detail.promotion_term_period_end_date
    if (startDate && endDate) {
      dispatch(
        setModalAdditionalDetails({
          selectedPromotionTermPeriod: [new Date(startDate), new Date(endDate)],
        }),
      )
    }

    const vendorId = general_data.vendor?.id
    if (vendorId) {
      dispatch(actFetchVendorDetailById(+vendorId))
    }

    const businessTaggingKey = additional_information.business_taggings.map((item) => item.key)
    const listLocationType = []
    if (businessTaggingKey.includes('QUICK_COMMERCE')) {
      listLocationType.push('HUB')
    }
    if (businessTaggingKey.includes('SCHEDULE_DELIVERY')) {
      listLocationType.push('MEGAHUB 2.0')
    }
    if (businessTaggingKey.includes('ASTRO_GO')) {
      listLocationType.push('ASTRO OFFLINE')
    }

    dispatch(
      setModalSelectLocation({
        source: 'additionalSellingDetail',
        locationTypeByBusinessTagging: listLocationType,
        selectedLocationIds: selling_detail.proposed_location_listing.map((item) => item.id),
        selectedLocation: selling_detail.proposed_location_listing,
      }),
    )

    if (listLocationType.length > 0) {
      dispatch(
        setModalSelectLocation({
          selectedLocationType: listLocationType[0],
        }),
      )
      dispatch(actGetLocationListByParams())
    }

    dispatch(
      setModalAdditionalDetails({
        additionalForm: {
          ...additional_information,
          product_age: getValue(additional_information.product_age),
          msltc: getValue(additional_information.msltc),
          mslor: getValue(additional_information.mslor),
          mslod: getValue(additional_information.mslod),
        },
        sellingDetailForm: {
          ...selling_detail,
          proposed_qty: getValue(selling_detail.proposed_qty),
          promotion_term_on_discount: getValue(selling_detail.promotion_term_on_discount),
          socmed_presence: getValue(selling_detail.socmed_presence),
          num_of_followers: getValue(selling_detail.num_of_followers),
        },
      }),
    )
  }

export const actFetchInboundWarehouseList =
  (searchName?: string) => (dispatch: AppDispatchType) => {
    dispatch(
      actGetLocationListByParams(searchName || '', 'WAREHOUSE', (locationList) => {
        const newList = locationList.map((item) => ({
          id: item.location_id,
          name: item.location_name,
          type: item.location_type,
        }))
        dispatch(
          setLocationList({
            fieldName: 'inboundWarehouseList',
            listData: newList,
          }),
        )
      }),
    )
  }

export const actInitializeListDataOfModalAdditionalDetails = () => (dispatch: AppDispatchType) => {
  dispatch(actFetchCategoryChild())
  dispatch(actFetchBusinessTaggings())
  dispatch(actFetchProductType())
  dispatch(actGetLocationDetail())
  dispatch(actFetchInboundWarehouseList())
}

const actSubmitGeneralDataUntilSellingDetail =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail, modalUpdateSku, modalAdditionalDetails, modalSelectLocation } =
      getState().skuRegistration

    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType
    const dataToSubmit = {
      ...skuRegisDetail,
      ...getDataForSubmitGeneralDataUntilPricing(modalUpdateSku),
      ...getDataForSubmitAdditionalSellingDetail(modalAdditionalDetails, modalSelectLocation),
    }

    dispatch(setModalUpdateSku({ isSubmittingData: true }))
    try {
      await submitProductRegistration(skuRegisDetail.id, dataToSubmit)
      dispatch(resetModalUpdateSku())
      dispatch(resetModalAdditionalDetails())
      dispatch(resetModalSelectLocation())
      toastSuccess('Berhasil mengupdate data sku registration')
      dispatch(actResetFilterAndFetchListSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalUpdateSku({ isSubmittingData: false }))
    }
  }

export const actValidateSubmitGeneralDataUntilSellingDetail =
  () => (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const {
      skuRegistrationDetail,
      modalUpdateSku,
      brandDetail,
      categoryL2List,
      modalAdditionalDetails,
      modalSelectLocation,
    } = getState().skuRegistration

    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType
    const { additionalForm, sellingDetailForm } = modalAdditionalDetails

    const { isGeneralDataValid, newErrorGeneralDataForm } = isGeneralFormValid(
      brandDetail,
      modalUpdateSku.generalDataForm,
      modalUpdateSku.isAutoGenerateBarcode,
      skuRegisDetail.status,
    )
    const { isHandlingDataValid, newErrorHandlingDataForm } = isHandlingFormValid(
      modalUpdateSku.handlingDataForm,
    )
    const { isGrammationDataValid, newErrorGrammationForm } = isGrammationFormValid(
      modalUpdateSku.grammationForm,
    )
    const { isPricingDataValid, newErrorPricingForm } = isPricingFormValid(
      modalUpdateSku.pricingForm,
    )

    const { isAdditonalDataValid, newErrorAdditionalForm } = isAdditionalFormValid(
      additionalForm,
      categoryL2List,
    )

    const { isSellingDataValid, newErrorSellingDetailForm } = isSellingDetailFormValid(
      sellingDetailForm,
      modalSelectLocation,
    )

    dispatch(setErrorGeneralForm(newErrorGeneralDataForm))
    dispatch(setErrorHandlingForm(newErrorHandlingDataForm))
    dispatch(setErrorGrammationForm(newErrorGrammationForm))
    dispatch(setErrorPricingForm(newErrorPricingForm))
    dispatch(setErrorAdditionalForm(newErrorAdditionalForm))
    dispatch(setErrorSellingDetailForm(newErrorSellingDetailForm))

    if (
      !isGeneralDataValid ||
      !isHandlingDataValid ||
      !isGrammationDataValid ||
      !isPricingDataValid ||
      !isAdditonalDataValid ||
      !isSellingDataValid
    ) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }

    const isGeneralDataRejected = isFieldRejectedOnGeneralForm(modalUpdateSku.generalDataForm)
    const isHandlingDataRejected = isFieldRejectedOnHandlingForm(modalUpdateSku.handlingDataForm)
    const isGrammationDataRejected = isFieldRejectedOnGrammationForm(modalUpdateSku.grammationForm)
    const isPricingDataRejected = isFieldRejectedOnPricingForm(modalUpdateSku.pricingForm)
    const isAdditionalDataRejected = isFieldRejectedOnAdditionalForm(additionalForm)
    const isSellingDetailRejected = isFieldRejectedOnSellingDetailForm(sellingDetailForm)

    if (
      isGeneralDataRejected ||
      isHandlingDataRejected ||
      isGrammationDataRejected ||
      isPricingDataRejected ||
      isAdditionalDataRejected ||
      isSellingDetailRejected
    ) {
      toastFailed('Ada field yg masih rejected, silahkan perbaiki terlebih dahulu')
      return
    }

    dispatch(actSubmitGeneralDataUntilSellingDetail())
  }
