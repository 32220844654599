import axiosInstanceApiGateway from 'config/apiServiceApiGateway'

export type GetProductStockDetailParamsType = {
  product_id: number
  location_id: number
}

export type GetInventoriesFeatureFlagResponseType = {
  is_qr: boolean
  is_tanggung_renteng: boolean
  manage_ed: boolean
  is_multi_sku: boolean
}

export const getInventoriesFeatureFlag = (params: GetProductStockDetailParamsType) =>
  axiosInstanceApiGateway.Get<GetInventoriesFeatureFlagResponseType>({
    url: '/ims/internal/v1/inventories/feature-flag',
    params,
  })
