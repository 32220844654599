import { ActionReducerMapBuilder, PayloadAction, createSlice } from '@reduxjs/toolkit'
import { withLoadingReducer } from 'utils/reducerHandler'
import type {
  GetInventoryLocationAPIResponseType,
  GetCheckPackageIdResponseType,
  GetInventoryStatusResponseType,
  GetInventoryStatusNotesResponseType,
  GetHubLocationsResponseType,
  GetWarehouseLocationsResponseType,
} from 'utils/apiList/productTanggungRenteng'
import {
  SLICE_NAME,
  fetchGetProductStockList,
  fetchGetPackageLabel,
  fetchGetCheckPackageId,
  fetchGetInventoryStatus,
  fetchGetInventoryStatusNotes,
  fetchPutTransferProductDetail,
  fetchGetHubLocations,
  setQuery,
  fetchGetWarehouseLocations,
} from './productTanggungRentengAsyncThunk'

export type ProductTanggungRentengStateType = {
  isLoading: boolean
  constants: {
    locations: GetHubLocationsResponseType['data'] | GetWarehouseLocationsResponseType
  }
  query: {
    locationId: number
    productId: number
    pageIndex: number
  }
  pagination: {
    pageSize: number
    pageIndex: number
    numberOfElements: number
  }
  data: GetInventoryLocationAPIResponseType['data']
  modalUpdate: {
    isOpen: boolean
    constants: {
      status: GetInventoryStatusResponseType['data']['status']
      notes: GetInventoryStatusNotesResponseType['data']['notes']
    }
    item: Nullable<GetInventoryLocationAPIResponseType['data']['items'][number]>
    state: {
      isToSlocDisabled: boolean
      isTooltipOpen: boolean
      shouldValidateQty: boolean
      maxTransferQty: number
      packageLabel: Nullable<GetCheckPackageIdResponseType['data']>
    }
    form: {
      quantity: number
      toExpiryDate: number
      toPackageId: string
      toSloc: Nullable<{
        value: number
        name: string
      }>
      toStatus: Nullable<{
        value: number
        name: string
      }>
      toNotes: Nullable<{
        value: number
        name: string
      }>
    }
    error: {
      [key: string]: string
    }
  }
}

const initialState: ProductTanggungRentengStateType = {
  isLoading: false,
  constants: {
    locations: [],
  },
  query: {
    locationId: 0,
    productId: 0,
    pageIndex: 1,
  },
  pagination: {
    pageSize: 20,
    pageIndex: 1,
    numberOfElements: 0,
  },
  data: {
    cutoffDate: 0,
    items: [],
    lostPeriodFrom: 0,
    lostPeriodTo: 0,
  },
  modalUpdate: {
    isOpen: false,
    constants: {
      status: [],
      notes: [],
    },
    item: null,
    state: {
      isToSlocDisabled: true,
      isTooltipOpen: false,
      shouldValidateQty: false,
      packageLabel: null,
      maxTransferQty: 0,
    },
    form: {
      quantity: 0,
      toExpiryDate: 0,
      toPackageId: '',
      toNotes: null,
      toSloc: null,
      toStatus: null,
    },
    error: {
      quantity: '',
      rackId: '',
    },
  },
}

const productTanggungRentengSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setShowModalUpdate: (
      state,
      action: PayloadAction<{
        isOpen: boolean
        item?: ProductTanggungRentengStateType['modalUpdate']['item']
      }>,
    ) => {
      const { isOpen, item } = action.payload
      state.modalUpdate.isOpen = isOpen

      if (!isOpen) {
        state.modalUpdate = initialState.modalUpdate
      }

      if (isOpen && item) {
        state.modalUpdate.item = item

        state.modalUpdate.form.toStatus = {
          value: item.statusId,
          name: item.statusName,
        }

        if (item.statusNotesId && item.statusNotesName) {
          state.modalUpdate.form.toNotes = {
            value: item.statusNotesId,
            name: item.statusNotesName,
          }
        }
      }
    },
    setForm: (
      state,
      action: PayloadAction<{
        key: keyof ProductTanggungRentengStateType['modalUpdate']['form']
        value: never
        isMultipleSku?: boolean
      }>,
    ) => {
      const { key, value, isMultipleSku } = action.payload
      state.modalUpdate.form[key] = value
      state.modalUpdate.error.quantity = ''

      if (key === 'toExpiryDate' && isMultipleSku) {
        state.modalUpdate.form.toSloc = null
      }
      if (
        key === 'quantity' &&
        state.modalUpdate.state.shouldValidateQty &&
        +value > state.modalUpdate.state.maxTransferQty
      ) {
        state.modalUpdate.error.quantity = `Qty tidak boleh melebihi max transfer qty ${state.modalUpdate.state.maxTransferQty}`
      }

      if (key === 'toStatus') {
        const statusSelected = value as { name: string; value: number }

        state.modalUpdate.form.toSloc = null
        state.modalUpdate.form.toNotes = null

        state.modalUpdate.state.isToSlocDisabled = statusSelected.name.toLowerCase() === 'lost'
        state.modalUpdate.state.isTooltipOpen = statusSelected.name.toLowerCase() === 'available'
      }
    },
    setOpenTooltip: (state, action) => {
      state.modalUpdate.state.isTooltipOpen = action.payload
    },
    setError: (
      state,
      action: PayloadAction<{
        key: keyof ProductTanggungRentengStateType['modalUpdate']['form']
        error: string
      }>,
    ) => {
      const { key, error } = action.payload
      state.modalUpdate.error[key] = error
    },
  },
  extraReducers: withLoadingReducer(
    (builder: ActionReducerMapBuilder<ProductTanggungRentengStateType>) => {
      builder
        .addCase(setQuery, (state, action) => {
          state.query = {
            ...state.query,
            ...action.payload,
          }
        })
        // ==== fetchGetProductStockList
        .addCase(fetchGetProductStockList.fulfilled, (state, action) => {
          state.data = action.payload.data
          state.pagination = action.payload.pagination
        })
        // ==== fetchGetPackageLabel
        .addCase(fetchGetPackageLabel.pending, (state) => {
          state.modalUpdate.state.shouldValidateQty = false
        })
        .addCase(fetchGetPackageLabel.fulfilled, (state, action) => {
          const { data } = action.payload
          state.modalUpdate.state.packageLabel = data

          if (data.rack) {
            state.modalUpdate.state.isToSlocDisabled = true
          }

          if (data.rack?.maxTransferQty) {
            state.modalUpdate.state.shouldValidateQty = true
            state.modalUpdate.state.maxTransferQty = data.rack.maxTransferQty
          }
        })
        // ==== fetchGetCheckPackageId
        .addCase(fetchGetCheckPackageId.pending, (state) => {
          state.modalUpdate.state.packageLabel = null
          state.modalUpdate.state.shouldValidateQty = false
          state.modalUpdate.state.isToSlocDisabled = false
          state.modalUpdate.state.maxTransferQty = 0
          state.modalUpdate.form.toSloc = null
        })
        .addCase(fetchGetCheckPackageId.fulfilled, (state, action) => {
          const { data } = action.payload
          state.modalUpdate.state.packageLabel = data

          if (data.rack) {
            state.modalUpdate.form.toSloc = {
              value: data.rack.rackId,
              name: data.rack.rackName,
            }
            state.modalUpdate.form.quantity = 0
            state.modalUpdate.state.isToSlocDisabled = true
          }

          if (data.rack?.maxTransferQty) {
            state.modalUpdate.state.shouldValidateQty = true
            state.modalUpdate.state.maxTransferQty = data.rack.maxTransferQty
          }
        })
        // ==== fetchGetInventoryStatus
        .addCase(fetchGetInventoryStatus.fulfilled, (state, action) => {
          state.modalUpdate.constants.status = action.payload.data.status
        })
        // ==== fetchGetInventoryStatusNotes
        .addCase(fetchGetInventoryStatusNotes.fulfilled, (state, action) => {
          state.modalUpdate.constants.notes = action.payload.data.notes
        })
        // ==== fetchGetLocation
        .addCase(fetchGetHubLocations.fulfilled, (state, action) => {
          state.constants.locations = action.payload
        })
        .addCase(fetchGetWarehouseLocations.fulfilled, (state, action) => {
          state.constants.locations = action.payload as
            | GetHubLocationsResponseType['data']
            | GetWarehouseLocationsResponseType
        })
        .addCase(fetchPutTransferProductDetail.fulfilled, (state) => {
          state.modalUpdate.isOpen = false
        })
    },
    [
      fetchGetHubLocations,
      fetchGetWarehouseLocations,
      fetchGetProductStockList,
      fetchGetCheckPackageId,
      fetchGetInventoryStatus,
      fetchGetInventoryStatusNotes,
      fetchPutTransferProductDetail,
    ],
  ),
})

export const { reset, setShowModalUpdate, setForm, setError, setOpenTooltip } =
  productTanggungRentengSlice.actions
export default productTanggungRentengSlice.reducer
