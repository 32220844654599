export const yesOrNoList = [
  {
    value: true,
    name: 'Ya',
  },
  {
    value: false,
    name: 'Tidak',
  },
]

export const initialErrorGeneralForm = {
  name: '',
  image_from_vendor_url: '',
  brand: '',
  vendor: '',
  sku_number: '',
  bpom: '',
  sni: '',
  pirt: '',
  mui: '',
}

export const initialErrorHandlingForm = {
  shelf: '',
  storage_handling: '',
  special_handling: '',
}

export const initialErrorGrammationForm = {
  primary_barcode: '',
  primary_multiplier: '',
  primary_multiplier_range_start: '',
  primary_multiplier_range_end: '',
  primary_uom: '',
  secondary_barcode: '',
  secondary_multiplier: '',
  secondary_multiplier_range_start: '',
  secondary_multiplier_range_end: '',
  secondary_uom: '',
  weight: '',
  length: '',
  width: '',
  height: '',
  sku_uom: '',
  outer_barcode: '',
  vendor_per_uom_qty: '',
  vendor_length: '',
  vendor_width: '',
  vendor_height: '',
  vendor_uom: '',
  vendor_volume_uom: '',
  net_weight: '',
  gross_weight: '',
  weight_grammation_uom: '',
}

export const initialErrorPricingForm = {
  cogs: '',
  srp: '',
  harga_eceran_tertinggi: '',
  harga_eceran_terendah: '',
  het_docs_agreement: '',
  competitor_price: '',
  competitor_link: '',
}

export const initialStateErrorAdditionalForm = {
  l1_category: '',
  l2_category: '',
  business_taggings: '',
  product_type: '',
  mslod: '',
  msltc: '',
  mslor: '',
  inbound_locations: '',
}

export const initialStateErrorSellingDetailForm = {
  proposed_qty: '',
  proposed_location_listing: '',
  proposed_qty_reason: '',
  min_order_by: '',
  product_needed_reason: '',
  promotion_term_on_discount: '',
  promotion_term_period: '',
  socmed_presence: '',
  num_of_followers: '',
}

export const initialStateErrorSkuPortfolio = {
  product: '',
  expected_daily_sold_qty: '',
  approved_first_allocation_to_hub: '',
  buffer_qty_wh: '',
  location_type: '',
  locations: '',
  reason: '',
}
export const initialStateGeneralDataForm = {
  name: '',
  name_rejected: false,
  image_path: {
    path_large: '',
    path_medium: '',
    path_small: '',
  },
  image_path_rejected: false,
  image_from_vendor_url: '',
  image_from_vendor_url_rejected: false,
  brand: {
    id: 0,
    name: '',
  },
  brand_rejected: false,
  vendor: {
    id: 0,
    name: '',
  },
  vendor_rejected: false,
  sku_number: '',
  sku_number_rejected: false,
  bpom: '',
  bpom_rejected: false,
  sni: '',
  sni_rejected: false,
  pirt: '',
  pirt_rejected: false,
  mui: '',
  mui_rejected: false,
  ppn: {
    id: 0,
    ppn: 0,
  },
  ppn_rejected: false,
  product_for_testing: false,
  product_for_testing_rejected: false,
}

export const initialStateHandlingDataForm = {
  shelf: '',
  shelf_rejected: false,
  is_special_handling: false,
  is_special_handling_rejected: false,
  special_handling: {
    is_fragile: false,
    is_melting: false,
    is_rotten: false,
    is_eggs: false,
  },
  special_handling_rejected: false,
  storage_handling: {
    code: '',
    name: '',
  },
  storage_handling_rejected: false,
}

export const initialStateGrammationForm = {
  primary: {
    barcode: '',
    barcode_rejected: false,
    uom: {
      id: '',
      description: '',
    },
    uom_rejected: false,
    multiplier: '',
    multiplier_rejected: false,
    multiplier_range_start: '',
    multiplier_range_start_rejected: false,
    multiplier_range_end: '',
    multiplier_range_end_rejected: false,
    use_multiplier_range: false,
  },
  secondary: {
    barcode: '',
    barcode_rejected: false,
    uom: {
      id: '',
      description: '',
    },
    uom_rejected: false,
    multiplier: '',
    multiplier_rejected: false,
    multiplier_range_start: '',
    multiplier_range_start_rejected: false,
    multiplier_range_end: '',
    multiplier_range_end_rejected: false,
    use_multiplier_range: false,
  },
  weight: '',
  weight_rejected: false,
  length: '',
  length_rejected: false,
  width: '',
  width_rejected: false,
  height: '',
  height_rejected: false,
  volume: 0,
  volume_uom: {
    id: '',
    description: '',
  },
  weight_uom: {
    id: '',
    description: '',
  },
  dimension_uom: {
    id: '',
    description: '',
  },
  sku_uom: {
    id: '',
    description: '',
  },
  sku_uom_rejected: false,
  outer_barcode: '',
  outer_barcode_rejected: false,
  vendor_per_uom_qty: '',
  vendor_per_uom_qty_rejected: false,
  vendor_length: '',
  vendor_length_rejected: false,
  vendor_width: '',
  vendor_width_rejected: false,
  vendor_height: '',
  vendor_height_rejected: false,
  vendor_uom: {
    id: '',
    description: '',
  },
  vendor_uom_rejected: false,
  vendor_volume: 0,
  vendor_volume_uom: {
    id: '',
    description: '',
  },
  vendor_volume_uom_rejected: false,
  net_weight: '',
  net_weight_rejected: false,
  gross_weight: '',
  gross_weight_rejected: false,
  weight_grammation_uom: {
    id: '',
    description: '',
  },
  weight_grammation_uom_rejected: false,
}

export const initialStatePricingForm = {
  cogs: '',
  cogs_rejected: false,
  srp: '',
  srp_rejected: false,
  have_het: false,
  harga_eceran_tertinggi: '',
  harga_eceran_tertinggi_rejected: false,
  harga_eceran_terendah: '',
  harga_eceran_terendah_rejected: false,
  het_docs_agreement: '',
  het_docs_agreement_rejected: false,
  competitor_price: '',
  competitor_price_rejected: false,
  competitor_link: '',
  competitor_link_rejected: false,
  proposed_margin: 0,
  proposed_price_index: 0,
}

export const initialStateAdditionalForm = {
  l1_category: {
    id: 0,
    name: '',
    parent_id: 0,
  },
  l1_category_rejected: false,
  l2_category: {
    id: 0,
    name: '',
    parent_id: 0,
  },
  l2_category_rejected: false,
  business_taggings: [],
  business_taggings_rejected: false,
  product_type: {
    product_type_id: 0,
    product_type_name: '',
  },
  product_type_rejected: false,
  product_age: '',
  product_age_rejected: false,
  use_standard_ned: false,
  use_standard_ned_rejected: false,
  msltc: '',
  msltc_rejected: false,
  mslor: '',
  mslor_rejected: false,
  mslod: '',
  mslod_rejected: false,
  inbound_locations: [],
  inbound_locations_rejected: false,
}

export const initialStateSellingDetailForm = {
  proposed_qty: '',
  proposed_qty_rejected: false,
  proposed_location_listing: [],
  proposed_location_listing_rejected: false,
  proposed_qty_reason: '',
  proposed_qty_reason_rejected: false,
  min_order_by: '',
  min_order_by_rejected: false,
  moq: 0,
  moq_rejected: false,
  mov: 0,
  mov_rejected: false,
  is_consignment: false,
  is_consignment_rejected: false,
  returnable: false,
  returnable_rejected: false,
  product_needed_reason: '',
  product_needed_reason_rejected: false,
  promotion_term_on_discount: '',
  promotion_term_on_discount_rejected: false,
  promotion_term_period_start_date: '2024/12/01',
  promotion_term_period_end_date: '2024/12/12',
  promotion_term_period_rejected: false,
  socmed_presence: '',
  socmed_presence_rejected: false,
  num_of_followers: '',
  num_of_followers_rejected: false,
  jbp: false,
  jbp_rejected: false,
  exclusive_launch: false,
  exclusive_launch_rejected: false,
  pack_size_change: false,
  pack_size_change_rejected: false,
}

// const imageOne =
//   'product-images/2024/12/450771cacity1607816dde32561e_fab0809e-14a2-4988-91b5-3abf826722c3_700x367.jpeg'
// const imageTwo = 'product-images/2024/12/_0ae163cf-367c-4c24-aad5-ffaa9bd1cadc_560x700.jpeg'
// const imageThree = 'product-images/2024/12/_56252e59-65e5-4439-bee7-ca827a60906d_560x700.jpeg'

export const initialStateSkuAssetForm = {
  sku_number: '',
  sku_number_rejected: false,
  image_paths: [],
  // image_paths: [
  //   {
  //     path_large: imageOne,
  //     path_medium: imageOne,
  //     path_small: imageOne,
  //   },
  //   {
  //     path_large: imageTwo,
  //     path_medium: imageTwo,
  //     path_small: imageTwo,
  //   },
  //   {
  //     path_large: imageThree,
  //     path_medium: imageThree,
  //     path_small: imageThree,
  //   },
  // ],
  image_paths_rejected: false,
  name: '',
  name_rejected: true,
  description: '',
  description_rejected: false,
  label_image: {
    id: 0,
    name: '',
  },
  label_image_rejected: false,
  label_promotion: {
    id: 0,
    name: '',
  },
  label_promotion_rejected: false,
  source_status: {
    code: '',
    name: '',
  },
  source_status_rejected: false,
  is_qr: false,
  is_qr_rejected: true,
}

export const initialStateExchangeProductForm = {
  exchange_product: false,
  exchange_product_rejected: false,
  product: {
    product_id: 0,
    product_name: '',
    sku_number: '',
  },
  product_rejected: false,
  l30d_gmv: 0,
}

export const initialStateAssortmentDetailForm = {
  expected_daily_sold_qty: '',
  expected_daily_sold_qty_rejected: false,
  approved_first_allocation_to_hub: '',
  approved_first_allocation_to_hub_rejected: false,
  buffer_qty_wh: '',
  buffer_qty_wh_rejected: false,
  l2_avg_l30d: 0,
}

export const initialStateAssortmentPlanningForm = {
  location_type: '',
  location_type_rejected: true,
  clusters: [],
  locations: [],
  locations_rejected: false,
  reason: '',
  reason_rejected: true,
  total_qty_to_order: 0,
  total_qty_to_order_rejected: false,
  estimated_order_value: 0,
  estimated_order_value_rejected: false,
}

export const initialStateSkuRegistrationDetail = {
  id: 'string-id',
  status: 'PENDING_APPROVAL_MERCHANDISING',
  general_data: initialStateGeneralDataForm,
  handling: initialStateHandlingDataForm,
  grammation: initialStateGrammationForm,
  pricing: initialStatePricingForm,
  additional_information: initialStateAdditionalForm,
  selling_detail: initialStateSellingDetailForm,
  asset: initialStateSkuAssetForm,
  product_substitution: initialStateExchangeProductForm,
  assortment_detail: initialStateAssortmentDetailForm,
  assortment_planning: initialStateAssortmentPlanningForm,
  final_pricing: {
    rsp: 0,
    margin: 0,
  },
  reject_reason: '',
  created_at: '',
  created_by: '',
  updated_at: '',
  updated_by: '',
  activities: [],
}

export const initialStateFailedDataCreationForm = {
  product_name: '',
  product_name_rejected: false,
  primary_grammation_barcode: '',
  primary_grammation_barcode_rejected: false,
  secondary_grammation_barcode: '',
  secondary_grammation_barcode_rejected: false,
}
