import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const SkuRegistrationPageRBAC = {
  pageID: '49c0db6c7d9efc81916d3ff57121c13d',
  elementID: {
    BtnBulkUpload: '648af58db1d644f316d732b1ead27cbc',
    BtnCancelSkuRegistration: 'e176615b509c74f4030c46aebd4deedf',
    BtnFillDataLevelCategory: '50f049f78e7154ca6faa4b7e6b8a3bea',
    BtnApprovalCategoryL1: '0a492ed4b6c15a2166352c6119b5870f',
    BtnApprovalCategoryL2: '0a492ed4b6c15a2166352c6119b5870c',
    BtnApprovalCategoryL3: '0a492ed4b6c15a2166352c6119b5870d',
    BtnFillDataLevelPortofolio: '88737e0ad9ea234564ab82a29a38cbbd',
    BtnFillDataLevelMerchandising: 'ef490741aaddddd0bd17309a08955a9e',
    BtnApprovalPortofolio: '2d8d4ea9abc1c7f62532518e54a3a315',
    BtnApprovalMerchandising: '429131826c1d69bfa30bdfbd4bcb33e6',
  },
} as const

export const useRBACSkuRegistrationPage = createUseRBAC(SkuRegistrationPageRBAC.elementID)

export type UseRBACSkuRegistrationPageType = ReturnType<typeof useRBACSkuRegistrationPage>
