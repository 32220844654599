import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const kittingRBAC = {
  pageID: 'a76ab7773055618ad3260950f3ba0b37',
  elementID: {
    TableList: '5f8ca40b0e2236c3397afbfbdb6557e5',
    KittingDetail: '777eec6b57f0f9cc8aed772ecdacba8a',
    ButtonCetakPickingList: '17d56a1033d3868f8e05f2a2bf003d5a',
    ButtonCheckQuantity: 'e2f318e5a980da9a230861b7d03e0dbe',
    ButtonCompleted: 'f94c1305021d0d0858849e65aabaec72',
    ButtonCancel: '86404a7a6220e510e89384c577a0e42d',
    ButtonBuatOrderBaru: 'af80493042bbb35091a298174046a9f9',
    ButtonConfirm: '0e16e55e7679dbce2d8464cd1d202569',
  },
} as const

export const useRBACKittingPage = createUseRBAC(kittingRBAC.elementID)

export type UseRBACKittingPageType = ReturnType<typeof useRBACKittingPage>
