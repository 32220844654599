/* eslint-disable @typescript-eslint/naming-convention */
import { Get } from 'config/apiServiceConfig'
import axiosInstanceApiGateway from 'config/apiServiceApiGateway'
import { Product } from '../@types'
import { CategoryL1Type } from '../@types/Product'

type GetProductsResponseType = {
  data: Product.ProductType[]
  pagination: Product.PaginationType
}

export type GetProductTypeResponseType = {
  data: {
    product_type_id: number
    product_type_name: string
  }[]
}

type GetProductInventoryStatusResponseType = {
  data: {
    status: Product.ProductInventoryStatusType[]
  }
}

export type GetProductStockHistoryV2ResponseType = {
  data: Product.ProductStockHistoryV2Type[]
  pagination: {
    direction: string
    number_of_elements: number
    page_index: number
    page_size: number
    sort: string
    total_elements: number
    total_pages: number
  }
}

type GetProductStockHistoryV2ParamsType = {
  'pagination.page_size': number
  'pagination.page_index': number
  product_id: number
  location_id: number
  status_id?: number
  created_date_from?: number
  created_date_to?: number
}

export type GetProductListResponseType = {
  data: Product.ProductListType
  pagination: Product.PaginationInKongType
}

export type GetProductListParamsType = {
  'pagination.sort'?: string
  'pagination.direction'?: string
  'pagination.page_size': number
  'pagination.page_index': number
  'filter.name'?: string
  'filter.category_ids'?: string
  'filter.is_active'?: boolean
  'filter.product_type'?: string
  'filter.bom_category'?: string
  'filter.brand_ids'?: string
  'filter.product_type_ids'?: string
}

type GetProductCategoryL1ResponseType = CategoryL1Type[]

export type GetProductAttributeOptionResponseType = {
  data: {
    id: number
    type: string
    key: string
    value: string
  }[]
}

type GetProductAttributeOptionRequestType = {
  type: string
}

export type GetUomListParamType = {
  groups: string
  active?: boolean
  pageSize: number
}

export type GetCategoriesChildResponseType = CategoryL1Type & {
  category_childs: CategoryL1Type[]
}

export const getProducts = (params: Partial<Product.QueryType>) =>
  Get<GetProductsResponseType>({
    url: '/api/ims/:version/products',
    version: 'v2',
    params,
  })

export const getSkuRegistrationList = () => Get
export const getProductType = () =>
  Get<GetProductTypeResponseType>({
    url: '/api/product/type',
  })

export const getProductById = (productId: number) =>
  Get<Product.ProductV1Type>({
    url: '/api/product/:productId',
    urlParams: { productId },
  })

export const getProductInventoryStatus = () =>
  Get<GetProductInventoryStatusResponseType>({
    url: '/api/inventory-status',
  })

export const getProductStockHistoryV2 = (params: GetProductStockHistoryV2ParamsType) =>
  axiosInstanceApiGateway.Get({
    url: '/ims/internal/v2/inventories/stock-history',
    params,
  })

export const getProductList = (params: GetProductListParamsType) =>
  axiosInstanceApiGateway.Get<GetProductListResponseType, true>({
    url: '/ims/internal/v2/products',
    params,
  })

export const getProductListInQueryString = (qs: string) =>
  axiosInstanceApiGateway.Get<GetProductListResponseType, true>({
    url: `/ims/internal/v2/products?${qs}`,
  })

export const getProductCategoryL1 = () =>
  Get<GetProductCategoryL1ResponseType>({
    url: '/api/categories',
  })

export const getProductAttributeOption = (params: GetProductAttributeOptionRequestType) =>
  Get<GetProductAttributeOptionResponseType>({
    url: `/api/ims/v2/product/attribute-option`,
    params,
  })

export const getStorageHandlingList = () =>
  Get<{ data: Product.StorageHandlingItemType[] }>({ url: `/api/product/storage-handling` })

export const getUomList = (params: GetUomListParamType) =>
  Get<{ data: Product.GetUomResponseType }>({ url: `/api/uoms`, params })

export const getCategoriesChild = () =>
  Get<GetCategoriesChildResponseType[]>({ url: '/api/categories/child' })

export const getProductExchangeInfo = (id: number) =>
  axiosInstanceApiGateway.Get<Product.ProductExchangeInfoDetailType>({
    url: `/ims/internal/v1/products/${id}/exchange-info`,
  })
