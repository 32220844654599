import { Box } from '@astro-ui/components'
import styled from 'styled-components'
import { useAppSelector, shallowEqual } from 'store'
import QRCode from 'react-qr-code'

const TemplatePrintBarcodeSloc = () => {
  const barcodeSlocToPrint = useAppSelector((state) => state.rack.barcodeSlocToPrint, shallowEqual)

  return (
    <div>
      {barcodeSlocToPrint.map((item) => (
        <StyledContainerBarcode key={item.id}>
          <StyledLabelTitle>{item.rack_name}</StyledLabelTitle>
          <Box display="flex" alignItems="center">
            <StyledContainerQrCode>
              <QRCode value={item.id?.toString()} size={120} />
            </StyledContainerQrCode>
            <StyledContainerLabelContent>
              <StyledLabelDetails>
                <div style={{ visibility: item.mix_product ? 'visible' : 'hidden' }}>
                  MIX PRODUCT
                </div>
                <Box marginTop="1mm">SLOC ID: {item.id}</Box>
              </StyledLabelDetails>
              <StyledLabelLocation>{item.location_name}</StyledLabelLocation>
            </StyledContainerLabelContent>
          </Box>
        </StyledContainerBarcode>
      ))}
    </div>
  )
}

const StyledContainerBarcode = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0
  width: 60mm;
  height: 40mm;
  padding: 2mm 4mm;
  box-sizing: border-box;  

  @media print {
    -webkit-print-color-adjust: exact;
    page-break-inside: avoid;
    break-inside: avoid;
    clear: both;
    @page {
      size: 62mm 42mm;
      margin: 1mm;
    }
  }
`

const StyledLabelTitle = styled.div`
  font-size: 4.5mm;
  font-weight: bold;
  white-space: nowrap;
  margin-bottom: 1mm;
`

const StyledContainerQrCode = styled.div`
  width: 50%;
  height: 28mm;
  display: flex;
  align-items: center;
  margin-right: 3mm;
`

const StyledContainerLabelContent = styled.div`
  width: 50%;
  height: 28mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledLabelDetails = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 2.8mm;
  line-height: 1.4;
`

const StyledLabelLocation = styled.div`
  font-size: 2.5mm;
`
export default TemplatePrintBarcodeSloc
