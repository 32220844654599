import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RolesKeyType } from 'constant/roles'

type LocationType = 'WAREHOUSE' | 'MEGAHUB' | 'HUB' | 'OFFICE' | 'VIRTUAL'
type LocationRolesType = {
  location_id: number
  location_name: string
  location_type: LocationType
  role_names: string[]
}

export type AuthInitialStateType = {
  userData: {
    id: number
    is_active: boolean
    isSuperAdmin: boolean
    email: string
    full_name: string
    phone_number: string
    authorities: { name: RolesKeyType }[]
    location_roles: LocationRolesType[]
    location_type: LocationType[]
    hubs: (LocationRolesType & { name: string })[]
    roles: { name: RolesKeyType }[]
    warehouses: (LocationRolesType & { name: string })[]
    position: string
    pages: {
      is_main_menu: boolean
      name: string
      page_id: string
    }[]
    user_locations: Omit<LocationRolesType, 'role_names'>[]
  }
  isLogin: boolean
  RBAC: {
    authorized: boolean
    element_ids: string[]
    page_id: string
  }
}

const initialState: AuthInitialStateType = {
  userData: {} as AuthInitialStateType['userData'],
  RBAC: {
    authorized: false,
    element_ids: [],
    page_id: '',
  },
  isLogin: false,
}

export const SLICE_NAME = 'authSlice'

const auth = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setUserData: (state, action) => {
      const userData = action.payload
      state.userData = userData
      state.isLogin = true
    },
    setRBAC: (state, action: PayloadAction<AuthInitialStateType['RBAC']>) => {
      state.RBAC = action.payload
    },
  },
})

export const { setUserData, reset, setRBAC } = auth.actions

export default auth.reducer
