import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { MasterPayrollRBAC } from './rbac'

const MasterPayroll = lazy(() => import('../pages/MasterPayroll/MasterPayrollList'))
const MasterPayrollDetail = lazy(() => import('../pages/MasterPayroll/MasterPayrollDetail'))

export const MasterPayrollRouter: RouterFeatureInterface<
  'MasterPayroll',
  '/dashboard/master-payroll'
> = {
  name: 'MasterPayroll',
  Component: MasterPayroll,
  path: '/dashboard/master-payroll',
  routeType: 'PRIVATE',
  allowedRoles: [],
  rbacPageID: MasterPayrollRBAC.pageID,
  isDashboard: true,
}

export const MasterPayrollDetailsRouter: RouterFeatureInterface<
  'MasterPayrollDetail',
  '/dashboard/master-payroll/:id'
> = {
  name: 'MasterPayrollDetail',
  Component: MasterPayrollDetail,
  path: '/dashboard/master-payroll/:id',
  routeType: 'PRIVATE',
  allowedRoles: [],
  isDashboard: true,
  rbacPageID: MasterPayrollRBAC.pageID,
}

const PayrollRouter = [MasterPayrollRouter, MasterPayrollDetailsRouter]

export const PayrollRouterDetail = generateRouterDetail(PayrollRouter)

export default PayrollRouter
