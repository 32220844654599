import generateRouterDetail from 'utils/helpers/generateRouterDetail'

import { ProductAssetRouter } from './productAssetRouter'
import { AssetMasterRouter } from './assetMasterRouter'
import { PurchaseOrderRouter } from './purchaseOrderRouter'
import { SupplyOrderRouter } from './supplyOrderRouter'

const AssetManagementRouter = [
  ...ProductAssetRouter,
  ...AssetMasterRouter,
  ...PurchaseOrderRouter,
  ...SupplyOrderRouter,
]

export const AssetManagementRouterDetail = generateRouterDetail(AssetManagementRouter)
export default AssetManagementRouter
