import {
  FailedMasterDataCreationType,
  SkuRegistrationItemType,
} from 'features/Product/@types/SkuRegistration'
import { AppDispatchType, RootStateType } from 'store'
import { submitProductRegistration } from 'features/Product/services/skuRegistration'
import {
  setErrorFailedDataCreation,
  setModalFailedDataCreation,
  resetModalFailedDataCreation,
} from 'features/Product/redux/skuRegistration/slice'
import { toastFailed, toastSuccess } from 'utils/toast'
import { callErrorMsg } from 'helpers/errorMsg'
import { getStringValueIfOptional, actFetchSkuRegistration } from './thunk'

export const isFailedDataCreationFormValid = (failedDataCreation: FailedMasterDataCreationType) => {
  let isValid = true

  const newFailedDataCreationErrorForm = {
    product_name: '',
    primary_grammation_barcode: '',
    secondary_grammation_barcode: '',
  }

  const isNotNumber = (value: string | null) => value && Number.isNaN(value)

  const validations = [
    {
      condition: !failedDataCreation.product_name.trim(),
      field: 'product_name',
      message: 'Product name wajib diisi',
    },
    {
      condition: !failedDataCreation.primary_grammation_barcode,
      field: 'primary_grammation_barcode',
      message: 'Primary barcode wajib diisi',
    },
    {
      condition: isNotNumber(failedDataCreation.primary_grammation_barcode),
      field: 'primary_grammation_barcode',
      message: 'Primary barcode harus berupa angka',
    },
    {
      condition: isNotNumber(failedDataCreation?.secondary_grammation_barcode),
      field: 'secondary_grammation_barcode',
      message: 'Secondary barcode harus berupa angka',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newFailedDataCreationErrorForm[field as keyof typeof newFailedDataCreationErrorForm] = message
    }
  })
  return {
    isFailedDataCreationValid: isValid,
    newFailedDataCreationErrorForm,
  }
}

export const isFieldRejectedOnFailedDataCreation = (
  failedDataCreation: FailedMasterDataCreationType,
) => {
  const paramsToCheck = [
    'product_name',
    'primary_grammation_barcode',
    'secondary_grammation_barcode',
  ] as const
  return paramsToCheck.some((fieldname) => failedDataCreation[fieldname])
}

export const actValidateModalFailedDataAndSubmit =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const { skuRegistrationDetail, modalFailedDataCreation } = getState().skuRegistration
    const { isFailedDataCreationValid, newFailedDataCreationErrorForm } =
      isFailedDataCreationFormValid(modalFailedDataCreation.failedDataCreationForm)
    const skuRegisDetail = skuRegistrationDetail as SkuRegistrationItemType

    dispatch(setErrorFailedDataCreation(newFailedDataCreationErrorForm))

    if (!isFailedDataCreationValid) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }
    const isFailedDataCreationRejected = isFieldRejectedOnFailedDataCreation(
      modalFailedDataCreation.failedDataCreationForm,
    )
    if (isFailedDataCreationRejected) {
      toastFailed('Ada field yg masih rejected, silahkan perbaiki terlebih dahulu')
      return
    }

    const dataToSubmit = {
      ...skuRegisDetail,
      failed_master_data_creation: {
        ...modalFailedDataCreation.failedDataCreationForm,
        secondary_grammation_barcode: getStringValueIfOptional(
          modalFailedDataCreation.failedDataCreationForm?.secondary_grammation_barcode,
        ),
      },
    }

    try {
      dispatch(setModalFailedDataCreation({ isSubmittingData: true }))
      await submitProductRegistration(skuRegisDetail.id as string, dataToSubmit)
      toastSuccess('Berhasil mengupdate data sku registration')
      dispatch(resetModalFailedDataCreation())
      dispatch(actFetchSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalFailedDataCreation({ isSubmittingData: false }))
    }
  }
