import { getInventoriesFeatureFlag } from 'features/Product/services/stockDetail'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'tanggungRenteng'

type ActGetInventoriesFeatureFlagRequestType = {
  productId: number
  locationId: number
  callbackFunction?: (isTanggungRentengActive: boolean) => void
}

export const actGetInventoriesFeatureFlag =
  (params: ActGetInventoriesFeatureFlagRequestType) => async () => {
    const { callbackFunction, productId, locationId } = params
    try {
      const {
        data: { data },
      } = await getInventoriesFeatureFlag({
        product_id: productId,
        location_id: locationId,
      })
      if (callbackFunction) callbackFunction(data.is_tanggung_renteng || false)
    } catch (err) {
      callErrorMsg(err)
    }
  }
