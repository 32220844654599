import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type {
  ProductRegistrationItemType,
  ProductRegistrationDetailType,
  RejectReasonItemType,
  CompetitorOptionType,
  CompetitorListItemType,
  UomOptionType,
} from '../../services/newSkus'
import {
  fetchNewSkus,
  fetchNewSkuDetail,
  approveSku,
  rejectSku,
  fetchRejectReasons,
  fetchCompetitorList,
  fetchCompetitorOptions,
  fetchUomOptions,
  postCompetitorDraft,
  postCompetitorSubmit,
  calculateRSP,
  submitRSP,
} from './thunk'

const ERROR_MSG = 'An error occurred'

export interface NewSkusStateType {
  newSkus: ProductRegistrationItemType[]
  selectedSkus: number[]
  pagination: {
    prev_cursor: string
    next_cursor: string
  }
  isLoading: boolean
  error: string | null
  selectedSkuDetail: ProductRegistrationDetailType | null
  isLoadingDetail: boolean
  detailError: string | null
  isReviewLoading: boolean
  reviewError: Nullable<unknown>
  rejectReasons: RejectReasonItemType[]
  competitors: CompetitorListItemType[]
  competitorOptions: CompetitorOptionType[]
  uomOptions: UomOptionType[]
  reviewed: {
    requestIds: (string | number)[]
    /**
     * should be in format: REQUEST_IDS-STATUS
     * and statuses should be in format:
     * "IMS_APPROVED" | "IMS_REJECTED" | "COMPETITOR_SUBMITTED" | "RSP_SUBMITTED"
     */
    history: string[]
  }
  isCalculating: boolean
}

const initialState: NewSkusStateType = {
  newSkus: [],
  selectedSkus: [],
  pagination: {
    prev_cursor: '',
    next_cursor: '',
  },
  isLoading: false,
  error: null,
  selectedSkuDetail: null,
  isLoadingDetail: false,
  detailError: null,
  isReviewLoading: false,
  reviewError: null,
  rejectReasons: [],
  competitorOptions: [],
  competitors: [],
  uomOptions: [],
  reviewed: {
    requestIds: [],
    history: [],
  },
  isCalculating: false,
}

const newSkusSlice = createSlice({
  name: 'pricingEngineNewSkus',
  initialState,
  reducers: {
    selectSku: (state, action: PayloadAction<number>) => {
      if (!state.selectedSkus.includes(action.payload)) {
        state.selectedSkus.push(action.payload)
      }
    },
    deselectSku: (state, action: PayloadAction<number>) => {
      state.selectedSkus = state.selectedSkus.filter((id) => id !== action.payload)
    },
    selectAllSkus: (state, action: PayloadAction<number[]>) => {
      state.selectedSkus = action.payload
    },
    clearSelectedSkus: (state) => {
      state.selectedSkus = []
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNewSkus.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchNewSkus.fulfilled, (state, action) => {
        state.isLoading = false
        state.newSkus = action.payload.data
        state.pagination = action.payload.pagination
        /**
         * Let's check whether action.meta.arg contains ids
         * If it does, let's assume it is called within review page
         * we should append the ids to reviewed.requestIds
         */
        if (action.meta.arg?.ids) {
          const ids = action.meta.arg.ids.split(',')
          state.reviewed.requestIds = Array.from(new Set([...state.reviewed.requestIds, ...ids]))

          /**
           * If request's status is within this set, assume it's already completed/reviewed
           * although, this should be confirmed with product team
           * whether only accept RSP_SUBMITTED as completed status to show checkmarks
           *
           * TBC: should we only select the RSP_SUBMITTED state as completed state?
           * let the rest commented for now :pepehands:
           */
          const assumeReviewedStatuses = [
            // 'IMS_APPROVED',
            'IMS_REJECTED',
            // 'COMPETITOR_SUBMITTED',
            'RSP_SUBMITTED',
          ]

          /* Should only select the previously reviewed state */
          const prevReviewedIds = action.payload.data.filter((req) =>
            assumeReviewedStatuses.includes(req.status),
          )

          state.reviewed.history = Array.from(
            new Set([
              ...state.reviewed.history,
              ...prevReviewedIds.map((req) => `${req.id}-${req.status}`),
            ]),
          )
        }

        state.error = null
      })
      .addCase(fetchNewSkus.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || ERROR_MSG
      })
      .addCase(fetchNewSkuDetail.pending, (state) => {
        state.isLoadingDetail = true
        state.detailError = null
      })
      .addCase(fetchNewSkuDetail.fulfilled, (state, action) => {
        state.isLoadingDetail = false
        state.selectedSkuDetail = action.payload.data
        state.detailError = null
      })
      .addCase(fetchNewSkuDetail.rejected, (state, action) => {
        state.isLoadingDetail = false
        state.detailError = action.error.message || ERROR_MSG
      })
      .addCase(approveSku.pending, (state) => {
        state.isReviewLoading = true
        state.reviewError = null
      })
      .addCase(approveSku.fulfilled, (state) => {
        state.isReviewLoading = false
      })
      .addCase(approveSku.rejected, (state, action) => {
        state.isReviewLoading = false
        state.reviewError = action.error.message || ERROR_MSG
      })
      .addCase(rejectSku.pending, (state) => {
        state.isReviewLoading = true
        state.reviewError = null
      })
      .addCase(rejectSku.fulfilled, (state) => {
        state.isReviewLoading = false
      })
      .addCase(rejectSku.rejected, (state, action) => {
        state.isReviewLoading = false
        state.reviewError = action.error.message || ERROR_MSG
      })
      .addCase(fetchRejectReasons.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchRejectReasons.fulfilled, (state, action) => {
        state.isLoading = false
        state.rejectReasons = action.payload.data
        state.error = null
      })
      .addCase(fetchRejectReasons.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || ERROR_MSG
      })
      .addCase(fetchCompetitorList.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitorList.fulfilled, (state, action) => {
        state.isLoading = false
        state.competitors = action.payload.competitors
        state.error = null
      })
      .addCase(fetchCompetitorList.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || ERROR_MSG
      })
      .addCase(fetchCompetitorOptions.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchCompetitorOptions.fulfilled, (state, action) => {
        state.isLoading = false
        state.competitorOptions = action.payload.data
        state.error = null
      })
      .addCase(fetchCompetitorOptions.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || ERROR_MSG
      })
      .addCase(fetchUomOptions.pending, (state) => {
        state.isLoading = true
        state.error = null
      })
      .addCase(fetchUomOptions.fulfilled, (state, action) => {
        state.isLoading = false
        state.uomOptions = action.payload.data
        state.error = null
      })
      .addCase(fetchUomOptions.rejected, (state, action) => {
        state.isLoading = false
        state.error = action.error.message || ERROR_MSG
      })
      .addCase(postCompetitorDraft.pending, (state) => {
        state.isReviewLoading = true
        state.reviewError = null
      })
      .addCase(postCompetitorDraft.fulfilled, (state) => {
        state.isReviewLoading = false
      })
      .addCase(postCompetitorDraft.rejected, (state, action) => {
        state.isReviewLoading = false
        state.reviewError = action.error.message || ERROR_MSG
      })
      .addCase(postCompetitorSubmit.pending, (state) => {
        state.isReviewLoading = true
        state.reviewError = null
      })
      .addCase(postCompetitorSubmit.fulfilled, (state) => {
        state.isReviewLoading = false
      })
      .addCase(postCompetitorSubmit.rejected, (state, action) => {
        state.isReviewLoading = false
        state.reviewError = action.error.message || ERROR_MSG
      })
      .addCase(calculateRSP.pending, (state) => {
        state.isCalculating = true
        state.reviewError = null
      })
      .addCase(calculateRSP.fulfilled, (state) => {
        state.isCalculating = false
      })
      .addCase(calculateRSP.rejected, (state, action) => {
        state.isCalculating = false
        state.reviewError = action.error.message || ERROR_MSG
      })
      .addCase(submitRSP.pending, (state) => {
        state.isReviewLoading = true
        state.reviewError = null
      })
      .addCase(submitRSP.fulfilled, (state) => {
        state.isReviewLoading = false
      })
      .addCase(submitRSP.rejected, (state, action) => {
        state.isReviewLoading = false
        state.reviewError = action.error.message || ERROR_MSG
      })
  },
})

export const { selectSku, deselectSku, selectAllSkus, clearSelectedSkus, clearState } =
  newSkusSlice.actions
export default newSkusSlice.reducer
