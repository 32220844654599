import { lazy } from 'react'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { SkuRegistrationPageRBAC } from 'features/Product/pages/SkuRegistration/rbac'
import ModifierRouterList, { ModifierNamedRoutesType, ModifierPathListsType } from './modifier'

type NamedRoutesType =
  | 'VariantModuleRouter'
  | 'ProductVariantRouter'
  | 'AddProductVariantRouter'
  | 'EditProductVariantRouter'
  | 'MasterVariantRouter'
  | 'ConversionRuleRouter'
  | ModifierNamedRoutesType
  | 'PriceTagRouter'
  | 'HubProductStockHistory'
  | 'WarehouseProductStockHistory'
  | 'SkuRegistrationList'

type PathListsType =
  | '/dashboard/product-variant'
  | '/dashboard/product-variant/add'
  | '/dashboard/product-variant/edit/:productVariantId'
  | '/dashboard/master-variant'
  | '/dashboard/conversion-rule'
  | ModifierPathListsType
  | '/dashboard/price-tag'
  | '/dashboard/hub/stock-detail'
  | '/dashboard/warehouse/stock-detail'
  | '/dashboard/sku-registration'

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

const ProductVariantPage = lazy(() => import('../pages/ProductVariant'))
const AddProductVariantPage = lazy(() => import('../pages/AddProductVariant'))
const MasterVariant = lazy(() => import('../pages/MasterVariant'))
const ConversionRule = lazy(() => import('../pages/ConversionRule'))
const PriceTag = lazy(() => import('../pages/PriceTag'))
const ProductStockHistory = lazy(() => import('../pages/ProductStockHistory'))
const SkuRegistration = lazy(() => import('../pages/SkuRegistration'))

export const VariantModuleRouter: RouterInstanceType = {
  name: 'VariantModuleRouter',
  Component: ProductVariantPage,
  path: '/dashboard/product-variant',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_PRODUCT_MANAGER',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_HO_MERCHANDISING',
  ],
  isDashboard: false,
}

const ProductVariantRouter: RouterInstanceType = {
  name: 'ProductVariantRouter',
  Component: ProductVariantPage,
  path: '/dashboard/product-variant',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const AddProductVariantRouter: RouterInstanceType = {
  name: 'AddProductVariantRouter',
  Component: AddProductVariantPage,
  path: '/dashboard/product-variant/add',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const EditProductVariantRouter: RouterInstanceType = {
  name: 'EditProductVariantRouter',
  Component: AddProductVariantPage,
  path: '/dashboard/product-variant/edit/:productVariantId',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_PRODUCT_MANAGER', 'AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB'],
  isDashboard: true,
}

const MasterVariantRouter: RouterInstanceType = {
  name: 'MasterVariantRouter',
  Component: MasterVariant,
  path: '/dashboard/master-variant',
  routeType: 'PRIVATE',
  allowedRoles: ['AUTH_MERCHANDISING_WAREHOUSE', 'AUTH_MERCHANDISING_HUB', 'AUTH_HO_MERCHANDISING'],
  isDashboard: true,
}

const ConversionRuleRouter: RouterInstanceType = {
  name: 'ConversionRuleRouter',
  Component: ConversionRule,
  path: '/dashboard/conversion-rule',
  routeType: 'PRIVATE',

  allowedRoles: [
    'AUTH_ADMIN',
    'AUTH_PRODUCT_MANAGER',
    'AUTH_QUALITY_ENGINEERING',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_HO_MERCHANDISING',
    'AUTH_HO_SUPPLY_CHAIN',
    'AUTH_SUPPLY_CHAIN',
  ],
  isDashboard: true,
}

const PriceTagRouter: RouterInstanceType = {
  name: 'PriceTagRouter',
  Component: PriceTag,
  path: '/dashboard/price-tag',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_ASTRO_GO_MANAGER',
    'AUTH_ASTRO_GO_SPV',
    'AUTH_ASTRO_GO_SHIFT_LEAD',
    'AUTH_ASTRO_GO_STAFF',
  ],
  isDashboard: true,
}

const HubProductStockHistoryRouter: RouterInstanceType = {
  name: 'HubProductStockHistory',
  Component: ProductStockHistory,
  path: '/dashboard/hub/stock-detail',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_HO_MERCHANDISING',
    'AUTH_MERCHANDISING_HUB',
    'AUTH_PRICING',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_CS_CAPTAIN',
    'AUTH_CUSTOMER_SERVICE',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_STOCK_COUNT_AUDITOR',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HUB_AREA_MANAGER_VIEWONLY',
    'AUTH_HUB_INV_MANAGER',
    'AUTH_HUB_INV_MANAGER_VIEWONLY',
    'AUTH_HUB_INV_SPV',
    'AUTH_HUB_INV_STAFF',
    'AUTH_QA_MANAGER',
    'AUTH_QA_SUPERVISOR',
    'AUTH_QA_STAFF',
    'AUTH_SUPER_AREA_MANAGER',
    'AUTH_WH_MANAGER',
    'AUTH_WH_MANAGER_VIEWONLY',
    'AUTH_WH_INBOUND_SPV',
    'AUTH_WH_INBOUND_STAFF',
    'AUTH_WH_OUTBOUND_SPV',
    'AUTH_WH_INV_MANAGER',
    'AUTH_WH_INV_MANAGER_VIEWONLY',
    'AUTH_WH_INV_SPV',
    'AUTH_WH_INV_STAFF',
    'AUTH_HO_MERCHANDISING',
    'AUTH_HO_FINANCE',
    'AUTH_HO_TAX',
    'AUTH_HO_ADJUSTMENT',
    'AUTH_HO_STOCK_COUNT_AUDITOR',
    'AUTH_HO_GA',
    'AUTH_HO_EXPANSION',
    'AUTH_HO_LP',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PARTNERSHIP',
    'AUTH_HO_ASTRO_KITCHEN_MANAGER',
    'AUTH_HO_ASTRO_KITCHEN_STAFF',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
    'AUTH_HO_SUPPLY_CHAIN',

    // Old Role
    'AUTH_EXPANSION',
    'AUTH_LP',
    'AUTH_INBOUND_STAFF',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_QA_FRESH_INBOUND',
    'AUTH_QA_FRESH_OUTBOUND',
    'AUTH_ADJUSTMENT',
  ],
  isDashboard: true,
}

const WarehouseProductStockHistoryRouter: RouterInstanceType = {
  name: 'WarehouseProductStockHistory',
  Component: ProductStockHistory,
  path: '/dashboard/warehouse/stock-detail',
  routeType: 'PRIVATE',
  allowedRoles: [
    'AUTH_CENTRAL_SUPERVISOR',
    'AUTH_MERCHANDISING_ADMIN',
    'AUTH_MERCHANDISING_WAREHOUSE',
    'AUTH_CAPTAIN',
    'AUTH_PRICING',
    'AUTH_SUPPLY_CHAIN',
    'AUTH_CS_CAPTAIN',
    'AUTH_CUSTOMER_SERVICE',
    'AUTH_INVENTORY_CONTROLLER',
    'AUTH_STOCK_COUNT_AUDITOR',
    'AUTH_HUB_AREA_MANAGER',
    'AUTH_HUB_AREA_MANAGER_VIEWONLY',
    'AUTH_HUB_INV_MANAGER',
    'AUTH_HUB_INV_MANAGER_VIEWONLY',
    'AUTH_HUB_INV_SPV',
    'AUTH_HUB_INV_STAFF',
    'AUTH_QA_MANAGER',
    'AUTH_QA_SUPERVISOR',
    'AUTH_QA_STAFF',
    'AUTH_SUPER_AREA_MANAGER',
    'AUTH_WH_MANAGER',
    'AUTH_WH_MANAGER_VIEWONLY',
    'AUTH_WH_INBOUND_SPV',
    'AUTH_WH_INBOUND_STAFF',
    'AUTH_WH_OUTBOUND_SPV',
    'AUTH_WH_INV_MANAGER',
    'AUTH_WH_INV_MANAGER_VIEWONLY',
    'AUTH_WH_INV_SPV',
    'AUTH_WH_INV_STAFF',
    'AUTH_HO_MERCHANDISING',
    'AUTH_HO_FINANCE',
    'AUTH_HO_TAX',
    'AUTH_HO_ADJUSTMENT',
    'AUTH_HO_STOCK_COUNT_AUDITOR',
    'AUTH_HO_GA',
    'AUTH_HO_EXPANSION',
    'AUTH_HO_LP',
    'AUTH_HO_CAMPAIGN',
    'AUTH_HO_PARTNERSHIP',
    'AUTH_HO_ASTRO_KITCHEN_MANAGER',
    'AUTH_HO_ASTRO_KITCHEN_STAFF',
    'AUTH_HO_PRODUCT_MANAGER',
    'AUTH_HO_QUALITY_ENGINEERING',
    'AUTH_HO_SUPPLY_CHAIN',
    // Old Role
    'AUTH_EXPANSION',
    'AUTH_LP',
    'AUTH_INBOUND_STAFF',
    'AUTH_OUTBOUND_STAFF',
    'AUTH_QA_FRESH_INBOUND',
    'AUTH_QA_FRESH_OUTBOUND',
    'AUTH_ADJUSTMENT',
  ],
  isDashboard: true,
}

const SkuRegistrationListRouter: RouterInstanceType = {
  name: 'SkuRegistrationList',
  Component: SkuRegistration,
  path: '/dashboard/sku-registration',
  routeType: 'PRIVATE',
  allowedRoles: [],
  rbacPageID: SkuRegistrationPageRBAC.pageID,
  isDashboard: true,
}

const ProductRouter: RouterInstanceType[] = [
  ProductVariantRouter,
  AddProductVariantRouter,
  EditProductVariantRouter,
  MasterVariantRouter,
  ConversionRuleRouter,
  ...ModifierRouterList,
  PriceTagRouter,
  HubProductStockHistoryRouter,
  WarehouseProductStockHistoryRouter,
  SkuRegistrationListRouter,
]

export const ProductRouterDetail = generateRouterDetail(ProductRouter)

export default ProductRouter
