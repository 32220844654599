import { memo, useState, useEffect, useContext, useMemo } from 'react'
import { Box, Button, MenuAction, Select, Typography, Tooltip } from '@astro-ui/components'
import { Information } from '@astronautsid/wpe-icons'
import { Input } from 'components'
import styled from 'styled-components'
import dayjs from 'dayjs'
import getParams from 'utils/getParams'
import store from 'storeContext'
import { toastFailed } from 'utils/toast'
import useGetElligibleRackInKong from 'customHooks/useGetElligibleRackInKong'
import { dateFormat } from '@astronautsid/wpe-utils'
import {
  VALIDATION_MESSAGE,
  useAccessRoleTanggungRenteng,
} from 'customHooks/useAccessRoleTanggungRenteng'
import { useHistory } from 'react-router-dom'
import { productHubTanggungRenteng, productWarehouseTanggungRenteng } from 'config/routes'
import { usePopupConfirmation } from '../PopupConfirmation'

function RowHubProductDetail({
  isFetchingFeatureFlag,
  flagging,
  allData,
  data,
  dataRack,
  onUpdate,
  onInlineUpdate,
  onPrint,
  dataStatus,
  allowedForUpdateFieldInRow,
  allowedForEditQuantity,
  allowedForEditExpiryDate,
  allowedForEditPopupUpdateLostToAvailable,
  handleShowModalUpdateToAvailable,
  hanldeShowModalUpdateExpiryDate,
  allowedForViewDetailInSearching,
}) {
  const {
    isSlocOpen,
    setIsSlocOpen,
    slocList,
    initializeSloc,
    slocNoDataText,
    setSelectedSloc,
    getIsSlocValid,
    handleSearchSloc,
    handleValidateSloc,
    isExpiryDateRequired,
  } = useGetElligibleRackInKong()

  const {
    nextStatus,
    shouldTriggerModalStatusChange,
    saveTemporaryCurrentStatus,
    determineAuthUserRolesByLocation,
    isStatusChanged,
    isNotAllowedToChangeStatusFromLostHilang,
    handleChangeNextStatus,
    handleChangeNextStatusNotes,
  } = useAccessRoleTanggungRenteng()

  const { state: stateHubDetail } = useContext(store.HubDetailContext)

  const history = useHistory()

  const { inventoryNote } = stateHubDetail
  const [toSloc, setToSloc] = useState('')
  const [isToSlocDisabled, setIsToSlocDisabled] = useState(false)
  const [isShowTooltipRelatedRelatedMultiSku, setIsShowTooltipRelatedMultiSku] = useState(false)
  const { handleOpenConfirmation } = usePopupConfirmation()

  const locationType = useMemo(() => {
    return location.pathname.split('/')[2]
  }, [location.pathname])

  const handleSelectSloc = (selected) => {
    const hasSameSkuAndAvalibaleStatus =
      data.status_name === 'Available' &&
      allData.filter(
        (item) =>
          item.product_sku_number === data.product_sku_number && item.status_name === 'Available',
      )

    setSelectedSloc(selected)
    const isSlocValid = getIsSlocValid(selected)

    const newFormValue = {
      ...formValue,
      ['rack_name']: selected?.rack_name,
      ['rackId']: selected?.id,
    }

    if (isSlocValid) {
      setToSloc(selected.rack_name)
      setFormValue(newFormValue)
    }

    handleValidateSloc()

    if (selected && hasSameSkuAndAvalibaleStatus.length) {
      const uniqueNames = Array.from(
        new Set(hasSameSkuAndAvalibaleStatus.map((obj) => obj.rack_name)),
      )
      handleOpenConfirmation({
        title: 'Konfirmasi SLOC',
        message: (
          <span>
            Semua Available Stock yang ada saat ini akan berpindah dari SLOC{' '}
            <b>{uniqueNames.join(', ')}</b>, ke SLOC <b>{selected?.rack_name}</b>, Silahkan
            Pindahkan Fisik Barang!
          </span>
        ),
        onSubmit: () => {
          updateAction(newFormValue)
        },
      })
    }
  }

  const qrCode = data?.qr_code.split(';')
  const [formValue, setFormValue] = useState(null)
  const [errorMessage, setErrorMessasge] = useState({ stock: '' })

  useEffect(() => {
    setFormValue({ ...data, rackId: data.rack_id })

    if (
      !isFetchingFeatureFlag &&
      ((flagging.is_multi_sku && data.expiry_date) || !flagging.is_multi_sku)
    ) {
      const queryParamSloc = {
        page_index: 1,
        page_size: 20,
        location_id: getParams('location_id'),
        product_id: getParams('product_id'),
        status_id: data.status_id,
        ...(data.status_id === 1 &&
          data.expiry_date &&
          flagging.is_multi_sku && {
            expiry_date: dateFormat({
              date: new Date(data.expiry_date),
              format: 'YYYY-MM-DD',
              locale: 'id',
            }),
          }),
      }
      initializeSloc(queryParamSloc)
    }

    saveTemporaryCurrentStatus({
      statusName: data.status_name.toLowerCase(),
      statusNotes: data.status_notes_name?.toLowerCase(),
    })
    determineAuthUserRolesByLocation(data.location_id)

    const statusName = data.status_name?.toLowerCase()
    const isSlocDisabledRelatedMultiSku =
      flagging.is_multi_sku && data.status_id === 1 && !data.expiry_date

    if (isSlocDisabledRelatedMultiSku) {
      setIsShowTooltipRelatedMultiSku(true)
    }

    if (statusName === 'lost' || isSlocDisabledRelatedMultiSku) {
      setIsToSlocDisabled(true)
    }
  }, [data, flagging, isFetchingFeatureFlag])

  const handleChangeQty = (e) => {
    const {
      target: { value },
    } = e
    setFormValue({ ...formValue, stock: value })
    setErrorMessasge((prev) => ({ ...prev, stock: '' }))

    if (data?.maxAdjustQty && data?.maxAdjustQty.toString()) {
      const totalMaxQtyToInput = data.stock + data.maxAdjustQty

      if (value > totalMaxQtyToInput) {
        setErrorMessasge((prev) => ({
          ...prev,
          stock: `Qty tidak boleh melebihi ${totalMaxQtyToInput}`,
        }))
      }
    }
  }

  const handleClickOptionStatus = async (valueOption) => {
    const selectedStatusName = valueOption.name?.toLowerCase()
    if (
      allowedForEditPopupUpdateLostToAvailable &&
      data.status_name === 'Lost' &&
      data.status_notes_name === 'Dalam Pencarian' &&
      selectedStatusName === 'available'
    ) {
      handleShowModalUpdateToAvailable({ isOpen: true, data })
    } else {
      setIsShowTooltipRelatedMultiSku(false)
      setIsToSlocDisabled(false)
      const isSlocDisabledRelatedMultiSku =
        flagging.is_multi_sku && valueOption.id === 1 && !data.expiry_date

      if (isSlocDisabledRelatedMultiSku) {
        setIsShowTooltipRelatedMultiSku(true)
      }

      if (selectedStatusName === 'lost' || isSlocDisabledRelatedMultiSku) {
        setIsToSlocDisabled(true)
      }

      setFormValue({
        ...formValue,
        ['status_name']: valueOption.name,
        ['status_id']: valueOption.id,
        ['status_notes_name']: '',
        ['status_notes_id']: null,
        ['rack_name']: '',
        ['rackId']: 0,
      })

      initializeSloc({
        page_index: 1,
        page_size: 20,
        location_id: getParams('location_id'),
        product_id: getParams('product_id'),
        status_id: valueOption.id,
        ...(valueOption.id === 1 &&
          data.expiry_date &&
          flagging.is_multi_sku && {
            expiry_date: data.expiry_date,
          }),
      })

      handleChangeNextStatus(valueOption.name.toLowerCase())
    }
  }

  const handleClickOptionNote = (valueOption) => {
    setFormValue({
      ...formValue,
      ['status_notes_name']: valueOption.name,
      ['status_notes_id']: valueOption.id,
    })

    handleChangeNextStatusNotes(valueOption.name.toLowerCase())
  }

  const cancelInlineEdit = () => {
    setFormValue({ ...data, rackId: data.rack_id })
  }

  const handleCancelRow = () => {
    cancelInlineEdit()
  }

  const updateAction = (newFormValue) => {
    const tanggungRenteng = {
      isModalOpen: false,
      nextStatusName: '',
      from: '',
    }

    const paramToCheckForEd = {
      expiryDate: data.expiry_date,
      statusId: data.status_id,
      locationType: data.location_type,
    }
    if (isExpiryDateRequired(paramToCheckForEd, flagging)) {
      toastFailed('Expiry date harus diisi')
      return
    }

    if (isStatusChanged() && isNotAllowedToChangeStatusFromLostHilang()) {
      toastFailed(VALIDATION_MESSAGE.lostBarangHilang)
      return
    }

    if (isStatusChanged() && shouldTriggerModalStatusChange()) {
      tanggungRenteng.isModalOpen = true
      tanggungRenteng.from = 'inline-update'

      if (nextStatus.statusName === 'available') tanggungRenteng.nextStatusName = 'available'
      else if (nextStatus.statusName === 'lost')
        tanggungRenteng.nextStatusName = nextStatus.statusNotes
    }
    onInlineUpdate(newFormValue, toSloc, tanggungRenteng, { isToSlocDisabled })
  }

  const handleGoToStockDetail = () => {
    if (locationType === 'hub') {
      history.push({
        pathname: productHubTanggungRenteng,
        search: `?locationId=${data.location_id}&locationType=${data.location_type}&productId=${data.product_id}`,
      })
    } else {
      history.push({
        pathname: productWarehouseTanggungRenteng,
        search: `?locationId=${data.location_id}&locationType=${data.location_type}&productId=${data.product_id}`,
      })
    }
  }

  const handleSelectMenu = (key) => {
    if (key === 'UPDATE_PRODUCT') {
      onUpdate()
    }
    if (key === 'CETAK_QR') onPrint()
    if (key === 'UBAH_TANGGAL_EXPIRED')
      hanldeShowModalUpdateExpiryDate({
        isOpen: true,
        expiryDateFrom: data.expiry_date,
        productDetailId: data.product_detail_id,
        stock: data.stock,
      })
    if (key === 'STOCK_DALAM_PENCARIAN') handleGoToStockDetail()
  }

  return (
    <Tr>
      <Td ratio="1" minWidth="200px">
        <Typography variant="body2">{qrCode[0]}</Typography>
      </Td>
      <Td ratio="1" minWidth="115px">
        <Input
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          name={'stock'}
          value={formValue?.stock}
          onChange={handleChangeQty}
          disabled={!allowedForEditQuantity}
          width="100px"
        />
        <Typography variant="body2" color="red">
          {errorMessage.stock}
        </Typography>
      </Td>
      <Td ratio="1" minWidth="200px">
        <Box display="flex" alignItems="center" gap="8px">
          <Select
            disableAutoSelectedOnClick
            open={isSlocOpen}
            onOpen={() => setIsSlocOpen(true)}
            onClose={handleValidateSloc}
            value={formValue?.rack_name || ''}
            placeholder="Cari Rak"
            fullWidth
            displayKey="rack_name"
            options={slocList}
            onChange={handleSelectSloc}
            onSearch={handleSearchSloc}
            withSearch
            dataNotFoundText={slocNoDataText}
            disabled={isToSlocDisabled || !allowedForUpdateFieldInRow}
          />
          {isShowTooltipRelatedRelatedMultiSku && (
            <Tooltip
              placement="top"
              title="Untuk memilih sloc, Expiry Date wajib dipilih terlebih dahulu"
            >
              <Box display="inline-block">
                <Information size={15} />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Td>
      <Td ratio="1" minWidth="150px">
        <Typography variant="body2">{data?.package_label || '-'}</Typography>
      </Td>
      <Td ratio="2" minWidth="200px">
        <Select
          fullWidth
          required
          options={dataStatus}
          disabled={!allowedForUpdateFieldInRow}
          onChange={(e) => {
            handleClickOptionStatus(e)
          }}
          value={
            formValue?.status_id
              ? {
                  id: formValue.status_id,
                  name: formValue.status_name,
                }
              : null
          }
        />
      </Td>
      <Td ratio="2" minWidth="200px">
        <Select
          required
          fullWidth
          options={inventoryNote[formValue?.status_id]}
          value={formValue?.status_notes_name || ''}
          onChange={handleClickOptionNote}
          disabled={!allowedForUpdateFieldInRow}
        />
      </Td>
      {flagging.is_tanggung_renteng && (
        <Td ratio="2" minWidth="200px">
          <Box p="4px 12px" borderRadius="4px" bg="#FEEDEF" width="fit-content">
            <Typography color="#F94D63" variant="body2">
              {data.lost_period_from ? dayjs(data.lost_period_from).format('DD MMM') : ''}
              {' - '}
              {data.lost_period_to ? dayjs(data.lost_period_to).format('DD MMM') : ''}
            </Typography>
          </Box>
        </Td>
      )}
      <Td ratio="2" minWidth="100px">
        <Typography variant="body2">
          {data.expiry_date ? dayjs(data?.expiry_date).format('DD/MM/YYYY') : '-'}
        </Typography>
      </Td>
      <Td ratio={1} minWidth="220px">
        {(data?.status_notes_name !== formValue?.status_notes_name ||
          data?.status_id?.toString() !== formValue?.status_id?.toString() ||
          data?.rack_id?.toString() !== formValue?.rackId?.toString() ||
          data?.stock?.toString() !== formValue?.stock?.toString() ||
          data?.expiry_date?.toString() !== formValue?.expiry_date?.toString()) &&
          (allowedForUpdateFieldInRow || allowedForEditQuantity) && (
            <Box display="flex" gap="8px">
              <Button variant="outlined" p="2px 28px" onClick={() => updateAction(formValue)}>
                Submit
              </Button>
              <CancelButton variant="outlined" p="2px 28px" onClick={handleCancelRow}>
                Cancel
              </CancelButton>
            </Box>
          )}
        {!(
          data?.status_notes_name !== formValue?.status_notes_name ||
          data?.status_id?.toString() !== formValue?.status_id?.toString() ||
          data?.rack_id?.toString() !== formValue?.rackId?.toString() ||
          data?.stock?.toString() !== formValue?.stock?.toString() ||
          data?.expiry_date?.toString() !== formValue?.expiry_date?.toString()
        ) && (
          <MenuAction
            label="Atur"
            options={[
              ...(allowedForUpdateFieldInRow
                ? [
                    {
                      name: 'Update Product',
                      value: 'UPDATE_PRODUCT',
                    },
                    {
                      name: 'Cetak QR',
                      value: 'CETAK_QR',
                    },
                  ]
                : []),
              ...(allowedForEditExpiryDate && data?.updated_expiry_date
                ? [
                    {
                      name: 'Ubah Tanggal Expired',
                      value: 'UBAH_TANGGAL_EXPIRED',
                    },
                  ]
                : []),
              ...(allowedForViewDetailInSearching && flagging.is_tanggung_renteng
                ? [
                    {
                      name: 'Stock dalam Pencarian',
                      value: 'STOCK_DALAM_PENCARIAN',
                    },
                  ]
                : []),
            ]}
            onSelect={({ value }) => handleSelectMenu(value)}
          />
        )}
      </Td>
    </Tr>
  )
}

export default memo(RowHubProductDetail)

const Tr = styled.tr`
  display: flex;
  padding: 12px 32px;
  background-color: ${({ theme: { colors } }) => colors.white};
  border-bottom: 1px solid #f3f6fa;
  align-items: center;
`

const Td = styled.td`
  color: ${({ theme: { colors } }) => colors.grey};
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  padding-right: 12px;
  min-width: ${({ minWidth }) => minWidth};
`

const CancelButton = styled(Button)`
  color: grey;
  border-color: grey;
  :hover {
    border-color: grey;
  }
`
