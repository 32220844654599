import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const CampaignSegmentListRolesRBAC = {
  pageID: '0d7f3994ebe70e9b717b247405068b9e',
  elementID: {
    ViewSegment: '4f1189858b52b8f1a32bb77835a26ae4',
    DownloadSegment: '633c035488330d4d6700dd151069e06e',
  },
} as const

export const useRBACCampaignSegmentListRolesPage = createUseRBAC(
  CampaignSegmentListRolesRBAC.elementID,
)

export type UseRBACCampaignSegmentListRolesPageType = ReturnType<
  typeof useRBACCampaignSegmentListRolesPage
>
