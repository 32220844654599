/* eslint-disable @typescript-eslint/naming-convention */
import { getProductExchangeInfo, getProductList } from 'features/Product/services/product'
import { callErrorMsg } from 'helpers/errorMsg'
import { submitProductRegistration } from 'features/Product/services/skuRegistration'
import { getListLocationByClusterId } from 'features/Location/services/location'
import { AppDispatchType, RootStateType } from 'store'
import { SkuRegistration } from 'features/Product/@types'
import { toastFailed, toastSuccess } from 'utils/toast'
import {
  setProductExchangeInfoDetail,
  setProductList,
  setErrorSkuPortfolio,
  setModalSkuPortfolio,
  setAssortmentPlanningForm,
  resetModalSkuPortfolio,
  resetModalSelectLocation,
  setModalSelectLocation,
  SkuRegistrationInitialStateType,
} from './slice'
import { getValue, actFetchSkuRegistration, actFetchAllCluster } from './thunk'

export const actFetchLocationListByMultipleCluster =
  (clusterIdList: string[]) => async (dispatch: AppDispatchType) => {
    try {
      const promises = clusterIdList.map((clusterId) => getListLocationByClusterId(clusterId))
      const responses = await Promise.all(promises)
      const combinedResponses = responses.flatMap((response) => response.data.data.location)
      const listLocation = combinedResponses.map((item) => ({
        id: item.id,
        name: item.name,
        type: item.type,
      }))
      dispatch(setAssortmentPlanningForm({ locations: listLocation, locations_rejected: false }))
    } catch (err) {
      callErrorMsg(err)
    }
  }

export const actInitializeDataOfModalPortofolio =
  (responseData: SkuRegistration.SkuRegistrationItemType) => (dispatch: AppDispatchType) => {
    const { additional_information, product_substitution, assortment_detail, assortment_planning } =
      responseData

    const businessTaggingKey = additional_information.business_taggings.map((item) => item.key)

    dispatch(
      setModalSkuPortfolio({
        isOpen: true,
        exchangeProductForm: product_substitution,
        assortmentDetailForm: {
          ...assortment_detail,
          expected_daily_sold_qty: getValue(assortment_detail.expected_daily_sold_qty),
          approved_first_allocation_to_hub: getValue(assortment_detail.buffer_qty_wh),
          buffer_qty_wh: getValue(assortment_detail.buffer_qty_wh),
        },
        assortmentPlanningForm: assortment_planning,
      }),
    )
    dispatch(actFetchLocationForHubLevelLocation())
    dispatch(actFetchAllCluster())

    dispatch(
      setModalSelectLocation({
        source: 'exchangeAssortment',
        selectedLocationIds: assortment_planning.locations.map((item) => item.id),
        selectedLocation: assortment_planning.locations,
      }),
    )

    if (
      businessTaggingKey.includes('ASTRO_GO') ||
      businessTaggingKey.includes('SCHEDULE_DELIVERY')
    ) {
      dispatch(setAssortmentPlanningForm({ location_type: 'HUB' }))
      dispatch(setModalSkuPortfolio({ isDisabledClusterOrHubLevel: true }))
    }
  }

export const actGetProductExchangeInfo =
  (productId: number) => async (dispatch: AppDispatchType) => {
    try {
      const { data } = await getProductExchangeInfo(productId)
      dispatch(setProductExchangeInfoDetail(data.data))
    } catch (error) {
      callErrorMsg(error)
    }
  }

export const actGetProductList = (name?: string) => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getProductList({
      'pagination.page_size': 20,
      'pagination.page_index': 1,
      'filter.name': name || '',
    })
    dispatch(setProductList(data.data))
  } catch (error) {
    callErrorMsg(error)
  }
}

export const actFetchLocationForHubLevelLocation =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { skuRegistrationDetail, modalSkuPortfolio } = getState().skuRegistration
      const skuRegisDetail = skuRegistrationDetail as SkuRegistration.SkuRegistrationItemType
      const businessTaggingKey = skuRegisDetail.additional_information.business_taggings.map(
        (item) => item.key,
      )

      const listLocationType = []
      if (businessTaggingKey.includes('ASTRO_GO')) {
        listLocationType.push('ASTRO OFFLINE')
      }
      if (businessTaggingKey.includes('SCHEDULE_DELIVERY')) {
        listLocationType.push('MEGAHUB 2.0')
      }
      if (
        businessTaggingKey.includes('QUICK_COMMERCE') &&
        (!modalSkuPortfolio.assortmentPlanningForm.location_type ||
          modalSkuPortfolio.assortmentPlanningForm.location_type === 'HUB')
      ) {
        listLocationType.push('HUB')
      }
      if (!listLocationType.length) return

      dispatch(
        setModalSelectLocation({
          locationTypeByBusinessTagging: listLocationType,
        }),
      )
    } catch (err) {
      callErrorMsg(err)
    }
  }

const valueNumberIsEqualToZero = (value: string) => !!(value && +value === 0)
const valueNumberIsGreaterThanZero = (value: string) => !!(value && +value > 0)

export const getValueOfTotalQtyOrder = (
  assortmentDetailForm: SkuRegistration.AssortmentDetailType,
  assortmentPlanningForm: SkuRegistration.AssortmentPlanningType,
  modalSelectLocation: SkuRegistrationInitialStateType['modalSelectLocation'],
) => {
  const locationList =
    assortmentPlanningForm.location_type.toUpperCase() === 'CLUSTER'
      ? assortmentPlanningForm.locations
      : modalSelectLocation.selectedLocation
  if (
    valueNumberIsGreaterThanZero(assortmentDetailForm.approved_first_allocation_to_hub) &&
    valueNumberIsGreaterThanZero(assortmentDetailForm.buffer_qty_wh) &&
    locationList.length > 0
  ) {
    return (
      +assortmentDetailForm.approved_first_allocation_to_hub * locationList.length +
      +assortmentDetailForm.buffer_qty_wh
    )
  }
  return 0
}

export const getValueOfEstOrderValue = (
  assortmentDetailForm: SkuRegistration.AssortmentDetailType,
  assortmentPlanningForm: SkuRegistration.AssortmentPlanningType,
  modalSelectLocation: SkuRegistrationInitialStateType['modalSelectLocation'],
  pricing: Nullable<SkuRegistration.PricingSubmitType> | undefined,
) => {
  if (!pricing) return 0
  if (
    getValueOfTotalQtyOrder(assortmentDetailForm, assortmentPlanningForm, modalSelectLocation) === 0
  )
    return 0
  return (
    getValueOfTotalQtyOrder(assortmentDetailForm, assortmentPlanningForm, modalSelectLocation) *
    +pricing.cogs
  )
}

const isAssortmentDetailValid = (assortmentDetail: SkuRegistration.AssortmentDetailType) => {
  let isValid = true
  const newErrorAssortmentDetailForm = {
    expected_daily_sold_qty: '',
    approved_first_allocation_to_hub: '',
    buffer_qty_wh: '',
  }

  const validations = [
    {
      condition:
        !assortmentDetail.expected_daily_sold_qty ||
        valueNumberIsEqualToZero(assortmentDetail.expected_daily_sold_qty),
      field: 'expected_daily_sold_qty',
      message: 'Expected Daily Sold Qty wajib lebih besar dari 0',
    },
    {
      condition:
        !assortmentDetail.approved_first_allocation_to_hub ||
        valueNumberIsEqualToZero(assortmentDetail.approved_first_allocation_to_hub),
      field: 'approved_first_allocation_to_hub',
      message: 'Approved First Allocation to Hub wajib lebih besar dari 0',
    },
    {
      condition:
        !assortmentDetail.buffer_qty_wh || valueNumberIsEqualToZero(assortmentDetail.buffer_qty_wh),
      field: 'buffer_qty_wh',
      message: 'Buffer Qty WH wajib lebih besar dari 0',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorAssortmentDetailForm[field as keyof typeof newErrorAssortmentDetailForm] = message
    }
  })
  return {
    isAssortmentDetailDataValid: isValid,
    newErrorAssortmentDetailForm,
  }
}

const isExchangeProductValid = (exchangeProduct: SkuRegistration.ProductSubtitutionType) => {
  let isValid = true
  const newErrorExchangeProduct = {
    product: '',
  }
  const validations = [
    {
      condition:
        exchangeProduct.exchange_product &&
        (!exchangeProduct.product || !exchangeProduct.product?.product_id),
      field: 'product',
      message: 'Product wajib diisi apabila Exchange Product yes',
    },
  ]
  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorExchangeProduct[field as keyof typeof newErrorExchangeProduct] = message
    }
  })
  return {
    isExchangeProductDataValid: isValid,
    newErrorExchangeProduct,
  }
}

const isFieldRejectedOnExchangeProductForm = (
  exchangeProduct: SkuRegistration.ProductSubtitutionType,
) => {
  const paramsToCheck = ['exchange_product_rejected', 'product_rejected'] as const
  return paramsToCheck.some((fieldname) => exchangeProduct[fieldname])
}

const isAssortmentPlanningValid = (
  assortmentPlanning: SkuRegistration.AssortmentPlanningType,
  modalSelectLocation: SkuRegistrationInitialStateType['modalSelectLocation'],
) => {
  let isValid = true
  const newErrorAssortmentPlanning = {
    location_type: '',
    locations: '',
    reason: '',
  }

  const validations = [
    {
      condition: !assortmentPlanning.location_type,
      field: 'location_type',
      message: 'Cluster atau Hub Level wajib dipilih',
    },
    {
      condition:
        (assortmentPlanning?.location_type?.toUpperCase() === 'CLUSTER' &&
          !assortmentPlanning.locations.length) ||
        (assortmentPlanning?.location_type?.toUpperCase() === 'HUB' &&
          !modalSelectLocation.selectedLocation.length),
      field: 'locations',
      message: 'Location wajib dipilih',
    },
  ]

  validations.forEach(({ condition, field, message }) => {
    if (condition) {
      isValid = false
      newErrorAssortmentPlanning[field as keyof typeof newErrorAssortmentPlanning] = message
    }
  })

  return {
    isAssortmentPlanningDataValid: isValid,
    newErrorAssortmentPlanning,
  }
}

const isFieldRejectedOnAssortmentDetailForm = (
  assortmentDetail: SkuRegistration.AssortmentDetailType,
) => {
  const paramsToCheck = [
    'expected_daily_sold_qty_rejected',
    'approved_first_allocation_to_hub_rejected',
    'buffer_qty_wh_rejected',
  ] as const
  return paramsToCheck.some((fieldname) => assortmentDetail[fieldname])
}

const isFieldRejectedOnAssortmentPlanningForm = (
  assortmentPlanning: SkuRegistration.AssortmentPlanningType,
) => {
  const paramsToCheck = ['location_type_rejected', 'locations_rejected', 'reason_rejected'] as const
  return paramsToCheck.some((fieldname) => assortmentPlanning[fieldname])
}

export const actValidateAndSubmitDataSkuPortfolio =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    const {
      skuRegistrationDetail,
      productExchangeInfoDetail,
      modalSkuPortfolio,
      modalSelectLocation,
    } = getState().skuRegistration
    const skuRegisDetail = skuRegistrationDetail as SkuRegistration.SkuRegistrationItemType
    const { exchangeProductForm, assortmentDetailForm, assortmentPlanningForm } = modalSkuPortfolio

    const { isExchangeProductDataValid, newErrorExchangeProduct } =
      isExchangeProductValid(exchangeProductForm)
    const { isAssortmentDetailDataValid, newErrorAssortmentDetailForm } =
      isAssortmentDetailValid(assortmentDetailForm)
    const { isAssortmentPlanningDataValid, newErrorAssortmentPlanning } = isAssortmentPlanningValid(
      assortmentPlanningForm,
      modalSelectLocation,
    )

    const newError = {
      ...newErrorExchangeProduct,
      ...newErrorAssortmentDetailForm,
      ...newErrorAssortmentPlanning,
    }
    dispatch(setErrorSkuPortfolio(newError))
    if (
      !isExchangeProductDataValid ||
      !isAssortmentDetailDataValid ||
      !isAssortmentPlanningDataValid
    ) {
      toastFailed('Ada field yg masih belum sesuai, silahkan perbaiki terlebih dahulu')
      return
    }

    const isExchangeRejected = isFieldRejectedOnExchangeProductForm(exchangeProductForm)
    const isAssortmentDetailRejected = isFieldRejectedOnAssortmentDetailForm(assortmentDetailForm)
    const isAssortmentPlanningRejected =
      isFieldRejectedOnAssortmentPlanningForm(assortmentPlanningForm)

    if (isExchangeRejected || isAssortmentDetailRejected || isAssortmentPlanningRejected) {
      toastFailed('Ada field yg masih rejected, silahkan perbaiki terlebih dahulu')
      return
    }

    const isCluster = assortmentPlanningForm.location_type.toUpperCase() === 'CLUSTER'

    const dataToSubmit = {
      ...skuRegisDetail,
      product_substitution: {
        ...exchangeProductForm,
        product: exchangeProductForm?.product?.product_id ? exchangeProductForm.product : null,
        l30d_gmv: exchangeProductForm?.product?.product_id
          ? productExchangeInfoDetail?.l30d_gmv || 0
          : 0,
      },
      assortment_detail: {
        ...assortmentDetailForm,
        expected_daily_sold_qty: +assortmentDetailForm.expected_daily_sold_qty,
        approved_first_allocation_to_hub: +assortmentDetailForm.approved_first_allocation_to_hub,
        buffer_qty_wh: +assortmentDetailForm.buffer_qty_wh,
        l2_avg_l30d: exchangeProductForm?.product?.product_id
          ? productExchangeInfoDetail?.l2_avg_l30d || 0
          : 0,
      },
      assortment_planning: {
        ...assortmentPlanningForm,
        locations: isCluster
          ? assortmentPlanningForm.locations
          : modalSelectLocation.selectedLocation,
        total_qty_to_order: getValueOfTotalQtyOrder(
          assortmentDetailForm,
          assortmentPlanningForm,
          modalSelectLocation,
        ),
        estimated_order_value: getValueOfEstOrderValue(
          assortmentDetailForm,
          assortmentPlanningForm,
          modalSelectLocation,
          skuRegisDetail.pricing,
        ),
      },
    }

    try {
      dispatch(setModalSkuPortfolio({ isSubmittingData: true }))
      await submitProductRegistration(skuRegisDetail.id, dataToSubmit)
      toastSuccess('Berhasil mengupdate data sku registration')
      dispatch(resetModalSkuPortfolio())
      dispatch(resetModalSelectLocation())
      dispatch(actFetchSkuRegistration())
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setModalSkuPortfolio({ isSubmittingData: false }))
    }
  }
