import { AppDispatchType, RootStateType } from 'store'
import { callErrorMsg } from 'helpers/errorMsg'
import {
  getBrandList,
  getBrandDetailById,
  GetBrandDetailByIdResponseType,
  getAllBrandCompany,
  postCreateBrand,
  putUpdateBrand,
  putCloudKitchen,
  GetBrandListParamsType,
  GetAllBrandCompanyRequestType,
} from 'features/Product/services/brand'
import { toastSuccess } from 'utils/toast'
import {
  setShowDialog,
  setBrandList,
  setIsLoadingBrand,
  setIsLoadingCompany,
  setIsSubmittingData,
  setBrandCompanies,
  setFormData,
  resetFormData,
  incrementCountReload,
  setBrandCompanyName,
} from './slice'

export const fetchGetAllBrand =
  (params: GetBrandListParamsType) => async (dispatch: AppDispatchType) => {
    try {
      dispatch(setIsLoadingBrand(true))
      const { data } = await getBrandList(params)
      dispatch(setBrandList(data))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoadingBrand(false))
    }
  }

export const fetchGetBrandCompany =
  (params: GetAllBrandCompanyRequestType) => async (dispatch: AppDispatchType) => {
    try {
      const { data } = await getAllBrandCompany(params)
      dispatch(setBrandCompanies(data.data))
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsLoadingCompany(false))
    }
  }

export const fetchBrandDetailById =
  (brandId: number, callbackFunction?: (detailData: GetBrandDetailByIdResponseType) => void) =>
  async (dispatch: AppDispatchType) => {
    try {
      const {
        data: { data },
      } = await getBrandDetailById(brandId)

      dispatch(
        setFormData({
          brand_id: data.brand_id || 0,
          brand_name: data.brand_name,
          cloud_kitchen: data.cloud_kitchen,
          brand_company_name: data.brand_company?.name || '',
          barcode_required: data.barcode_required,
          bpom_required: data.bpom_required,
          sni_required: data.sni_required,
          pirt_required: data.pirt_required,
          mui_required: data.mui_required,
        }),
      )
      dispatch(setBrandCompanyName(data.brand_company?.name || ''))
      if (callbackFunction) callbackFunction(data)
    } catch (err) {
      callErrorMsg(err)
    }
  }

const resetFormTriggerReload = () => async (dispatch: AppDispatchType) => {
  dispatch(resetFormData())
  dispatch(setShowDialog(false))
  dispatch(setBrandCompanyName(''))
  dispatch(incrementCountReload())
}

export const createBrand =
  () => async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      dispatch(setIsSubmittingData(true))
      const { formData } = getState().brandMaster
      const { userData } = getState().auth
      const dataToSubmit = {
        ...formData,
        created_by: userData.full_name,
      }
      await postCreateBrand(dataToSubmit)
      dispatch(resetFormTriggerReload())
      toastSuccess('Brand berhasil ditambahkan')
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsSubmittingData(false))
    }
  }

export const updateBrand =
  (payloadData?: Partial<GetBrandDetailByIdResponseType>) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      dispatch(setIsSubmittingData(true))
      const { formData } = getState().brandMaster
      const { userData } = getState().auth
      const dataToSubmit = {
        ...formData,
        ...payloadData,
        updated_by: userData.full_name,
      }
      await putUpdateBrand(formData.brand_id as number, dataToSubmit)
      dispatch(resetFormTriggerReload())
      toastSuccess('Brand berhasil diupdate')
    } catch (err) {
      callErrorMsg(err)
    } finally {
      dispatch(setIsSubmittingData(false))
    }
  }

export const actToggleCloudKitchen =
  ({ id, cloudKitchen }: { id: number; cloudKitchen: boolean }) =>
  async (dispatch: AppDispatchType, getState: () => RootStateType) => {
    try {
      const { userData } = getState().auth
      await putCloudKitchen(id, {
        brand_id: id,
        cloud_kitchen: cloudKitchen,
        updated_by: userData.full_name,
      })
      toastSuccess('Cloud Kitchen berhasil diupdate')
      dispatch(incrementCountReload())
    } catch (err) {
      callErrorMsg(err)
    }
  }
