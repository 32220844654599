import { memo } from 'react'
import styled from 'styled-components'
import { SystemIcon, RowAdmin } from 'components'

function TableAdmin({ handleClickEdit, adminData, isAllowedForUpdateAccount }) {
  //FUNCTION RENDER
  const renderRowAdmin = () => {
    return adminData.map((items, index) => (
      <RowAdmin
        key={index + items.full_name}
        adminData={items}
        handleClickEdit={handleClickEdit}
        isAllowedForUpdateAccount={isAllowedForUpdateAccount}
      />
    ))
  }
  return (
    <Container>
      <Thead>
        <Tr>
          <Th ratio="3">Nama</Th>
          <Th ratio="3">No HP</Th>
          <Th ratio="6">Email</Th>
          <Th ratio="3">Jabatan</Th>
          <Th ratio="4">Lokasi</Th>
          <Th ratio="4">
            Roles
            <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
          </Th>
          <Th ratio="1">Aksi</Th>
        </Tr>
      </Thead>
      <Tbody>{renderRowAdmin()}</Tbody>
    </Container>
  )
}

export default memo(TableAdmin)

const Container = styled.table`
  width: 100%;
`

const Thead = styled.thead`
  display: block;
`
const Tbody = styled.tbody`
  display: block;
`

const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
`
