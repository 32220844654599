import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const CustomerRBAC = {
  pageID: 'd7c4ac855e65995a3c23797933a0e2c1',
  elementID: {
    ViewCustomerList: '6875c35ee0048694393bf9461a50c315',
    ViewSaldo: '865cc234e08f80e8b0bfc6e6359c118a',
    ViewAstroPoint: '3dd12871c22685b195a9a91fe85eefc2',
    UpdateCustomerStatus: '1bf3a5392f01bf9bf08420aaa4181324',
  },
} as const

export const useRBACCustomerPage = createUseRBAC(CustomerRBAC.elementID)

export type UseRBACBuyerPageType = ReturnType<typeof useRBACCustomerPage>
