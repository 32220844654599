import { lazy } from 'react'

import { roles } from 'constant/roles'
import {
  masterPayroll,
  masterPayrollDetail,
  hubStaffScheduleBulkUpload,
  mpBudget,
  mpPlanning,
  hubStaff,
  centralWarehouseStaff,
} from 'config/routes'
import generateRouterDetail from 'utils/helpers/generateRouterDetail'

const ManPowerBulkUpload = lazy(() => import('../pages/ManPowerBulkUpload'))
const HubStaff = lazy(() => import('../pages/Account/HubStaff'))
const CentralWarehouseStaff = lazy(() => import('../pages/Account/CentralWarehouseStaff'))
const MPBudget = lazy(() => import('../pages/Account/MPBudget'))

type NamedRoutesType =
  | 'MasterPayroll'
  | 'MasterPayrollDetail'
  | 'ScheduleBulkUploadRouter'
  | 'MPPlanningRouter'
  | 'MPBudgetRouter'
  | 'HubStaffRouter'
  | 'CentralWarehouseRouter'

type PathListsType =
  | typeof masterPayroll
  | typeof masterPayrollDetail
  | typeof hubStaffScheduleBulkUpload
  | typeof mpBudget
  | typeof mpPlanning
  | typeof hubStaff
  | typeof centralWarehouseStaff

type RouterInstanceType = RouterFeatureInterface<NamedRoutesType, PathListsType>

export const ScheduleBulkUploadRouter: RouterInstanceType = {
  name: 'ScheduleBulkUploadRouter',
  Component: () => <ManPowerBulkUpload type="workingSchedule" />,
  path: hubStaffScheduleBulkUpload,
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_MARKETING,
    roles.AUTH_HO_HR,
    roles.AUTH_SPV_HUB,
    roles.AUTH_TEMP_MASTER_APPROVAL,
    roles.AUTH_ASTRO_GO_SPV,
    roles.AUTH_ASTRO_GO_MANAGER,
  ],
  isDashboard: true,
}

export const MPPlanningRouter: RouterInstanceType = {
  name: 'MPPlanningRouter',
  Component: () => <ManPowerBulkUpload type="planning" />,
  path: mpPlanning,
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_CAPTAIN, roles.AUTH_DRIVER_COORDINATOR, roles.AUTH_MARKETING],
  isDashboard: true,
}

export const MPBudgetRouter: RouterFeatureInterface<'MPBudgetRouter', '/dashboard/mp-budget'> = {
  name: 'MPBudgetRouter',
  Component: MPBudget,
  path: '/dashboard/mp-budget',
  routeType: 'PRIVATE',
  allowedRoles: [roles.AUTH_HO_OPX_MID_MILE, roles.AUTH_HO_OPX_HUB],
  isDashboard: true,
}

export const HubStaffRouter: RouterInstanceType = {
  name: 'HubStaffRouter',
  Component: () => <HubStaff />,
  path: hubStaff,
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_CAPTAIN,
    roles.AUTH_CS_CAPTAIN,
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_PACKER,
    roles.AUTH_ADMIN,
    roles.AUTH_CUSTOMER_SERVICE,
    roles.AUTH_HO_HR,
    roles.AUTH_HUB_SPV,
    roles.AUTH_SPV_HUB,
  ],
  isDashboard: true,
}

export const CentralWarehouseRouter: RouterInstanceType = {
  name: 'CentralWarehouseRouter',
  Component: () => <CentralWarehouseStaff />,
  path: centralWarehouseStaff,
  routeType: 'PRIVATE',
  allowedRoles: [
    roles.AUTH_CAPTAIN,
    roles.AUTH_CS_CAPTAIN,
    roles.AUTH_DRIVER_COORDINATOR,
    roles.AUTH_PACKER,
    roles.AUTH_ADMIN,
    roles.AUTH_CUSTOMER_SERVICE,
    roles.AUTH_HO_HR,
    roles.AUTH_WH_MANAGER,
    roles.AUTH_WH_INBOUND_SPV,
    roles.AUTH_WH_OUTBOUND_SPV,
    roles.AUTH_WH_INV_SPV,
  ],
  isDashboard: true,
}

const HRISRouter = [
  ScheduleBulkUploadRouter,
  MPPlanningRouter,
  MPBudgetRouter,
  // HubStaffRouter,
  // CentralWarehouseRouter,
]

export const HRISRouterDetail = generateRouterDetail(HRISRouter)

export default HRISRouter
