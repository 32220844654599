import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const CampaignListRolesRBAC = {
  pageID: 'ba5c4a30f46eeefaa7d2d8d4bc5738a8',
  elementID: {
    ViewCampaign: '20f238353639451fbd79651b149126fd',
    ButtonBuatCampaign: '10e0b9c3d1c2fb6270931af52710724e',
    ToggleStatus: 'b7501707140f1f4c9ff6e418a3cc678a',
    ButtonEditCampaign: 'a990915b364cac15e01b3fe30461c90e',
    ButtonHapusCampaign: 'eb560c9e861c8e633107dc112ec24aed',
    ButtonAturProduk: '3a04a421b1f8582c960f531d006df985',
  },
} as const

export const useRBACCampaignListRolesPage = createUseRBAC(CampaignListRolesRBAC.elementID)

export type UseRBACCampaignListRolesPageType = ReturnType<typeof useRBACCampaignListRolesPage>
