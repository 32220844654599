import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ProductDataInterface } from 'features/AssetManagement/@types/typeSupplyOrder'

interface AssetSOAddProductStateType {
  isLoading: boolean
  isCheckAll: boolean
  selectedProducts: ProductDataInterface[]
  products: ProductDataInterface[]
}

const initialState: AssetSOAddProductStateType = {
  isLoading: false,
  isCheckAll: false,
  selectedProducts: [],
  products: [],
}

const assetSOAddProductSlice = createSlice({
  name: 'assetSOAddProduct',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setProducts: (state, action: PayloadAction<ProductDataInterface[]>) => {
      state.products = action.payload
    },
    setSelectedProducts: (state, action: PayloadAction<ProductDataInterface[]>) => {
      state.selectedProducts = action.payload
    },
    setIsCheckAll: (state, action: PayloadAction<boolean>) => {
      state.isCheckAll = action.payload
    },
    resetProducts: (state) => {
      state.products = []
    },
    resetSelectedProducts: (state) => {
      state.selectedProducts = []
    },
  },
})

export const {
  setIsLoading,
  setProducts,
  setSelectedProducts,
  setIsCheckAll,
  resetProducts,
  resetSelectedProducts,
} = assetSOAddProductSlice.actions

export default assetSOAddProductSlice.reducer
