/* eslint-disable sonarjs/no-identical-functions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  GetBrandListResponseType,
  CreateOrUpdateBrandRequestType,
  GetAllBrandCompanyResponseType,
} from 'features/Product/services/brand'

export type BrandMasterStateType = {
  isShowDialog: boolean
  isLoadingBrand: boolean
  isLoadingCompany: boolean
  isSubmittingData: boolean
  keyword: string
  brand: GetBrandListResponseType
  formData: CreateOrUpdateBrandRequestType
  brandCompanyName: string
  isShowResultsSearch: boolean
  companies: GetAllBrandCompanyResponseType['data']
  willUseNewCompany: boolean
  countReload: number
}

const initialState: BrandMasterStateType = {
  isShowDialog: false,
  isLoadingBrand: true,
  isLoadingCompany: true,
  isSubmittingData: false,
  keyword: '',
  brand: {
    data: [],
    pagination: {
      sort: 'created_at',
      direction: 'desc',
      page_size: 20,
      page_index: 1,
      number_of_elements: 0,
    },
  },
  formData: {
    brand_id: 0,
    brand_name: '',
    cloud_kitchen: false,
    brand_company_name: '',
    barcode_required: false,
    bpom_required: false,
    sni_required: false,
    pirt_required: false,
    mui_required: false,
  },
  brandCompanyName: '',
  isShowResultsSearch: false,
  companies: [],
  willUseNewCompany: false,
  countReload: 1,
}

const brandMaster = createSlice({
  name: 'brandMaster',
  initialState,
  reducers: {
    setBrandCompanyName: (state, action: PayloadAction<string>) => {
      state.brandCompanyName = action.payload
    },
    setBrandList: (state, action: PayloadAction<GetBrandListResponseType>) => {
      state.brand = action.payload
    },
    setKeyword: (state, action: PayloadAction<string>) => {
      state.keyword = action.payload
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.brand.pagination.page_index = action.payload
    },
    setIsShowResultsSearch: (state, action: PayloadAction<boolean>) => {
      state.isShowResultsSearch = action.payload
    },
    setIsLoadingCompany: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCompany = action.payload
    },
    setIsSubmittingData: (state, action: PayloadAction<boolean>) => {
      state.isSubmittingData = action.payload
    },
    setWillUseNewCompany: (state, action: PayloadAction<boolean>) => {
      state.willUseNewCompany = action.payload
    },
    setShowDialog: (state, action: PayloadAction<boolean>) => {
      state.isShowDialog = action.payload
    },
    setCountReload: (state) => {
      state.countReload += 1
    },
    setIsLoadingBrand: (state, action: PayloadAction<boolean>) => {
      state.isLoadingBrand = action.payload
    },
    setBrandCompanies: (state, action: PayloadAction<GetAllBrandCompanyResponseType['data']>) => {
      state.companies = action.payload
    },
    setFormData: (state, action: PayloadAction<Partial<BrandMasterStateType['formData']>>) => {
      state.formData = {
        ...state.formData,
        ...action.payload,
      }
    },
    resetFormData: (state) => {
      state.formData = initialState.formData
    },
    incrementCountReload: (state) => {
      state.countReload += 1
    },
  },
})

export const {
  setBrandCompanyName,
  setBrandList,
  setKeyword,
  setPage,
  setIsShowResultsSearch,
  setIsLoadingCompany,
  setIsSubmittingData,
  setWillUseNewCompany,
  setShowDialog,
  setIsLoadingBrand,
  setBrandCompanies,
  setFormData,
  resetFormData,
  incrementCountReload,
} = brandMaster.actions
export default brandMaster.reducer
