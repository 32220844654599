import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getVendorBrandGroupingList,
  postVendorBrandGroup,
  putVendorBrandGroup,
} from 'utils/apiList/vendorBrandGrouping'
import { getVendors } from 'utils/apiList/vendor'
import { getAllBrandV2 } from 'features/Product/services/brand'
import { callErrorMsg } from 'helpers/errorMsg'

export const SLICE_NAME = 'vendorBrandGrouping'

export interface FetchVendorListResponseType {
  data: {
    id: number
    companyName: string
  }[]
}

export interface FetchBrandListThunkResponseType {
  brandId: number
  brandName: string
}

const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2)

export const fetchVendorBrandGroupingThunk = createAsyncThunk(
  `${SLICE_NAME}/fetchVendorBrandGroupingThunk`,
  async (_, { getState, rejectWithValue }) => {
    try {
      const { query } = (getState() as StoreStateType).vendorBrandGrouping

      const { data } = await getVendorBrandGroupingList(query)
      const listData = data.data.map((itemData) => ({ ...itemData, uniqueId: generateUniqueId() }))
      return {
        data: listData,
        pagination: data.pagination,
      }
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const fetchVendorListThunk = createAsyncThunk<
  FetchVendorListResponseType,
  { vendorName?: string }
>(`${SLICE_NAME}/fetchVendorListThunk`, async (param, { rejectWithValue }) => {
  const { vendorName } = param
  try {
    const { data } = await getVendors({ pageSize: 20, companyName: vendorName || '' })
    return data
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(err)
  }
})

export const fetchBrandListThunk = createAsyncThunk<
  FetchBrandListThunkResponseType[],
  { search?: string }
>(`${SLICE_NAME}/fetchBrandListThunk`, async (param, { rejectWithValue }) => {
  const { search } = param
  try {
    const { data } = await getAllBrandV2({ pageIndex: 1, pageSize: 20, name: search })
    return data?.data.map((itemData: { brand_id: number; brand_name: string }) => ({
      brandId: itemData.brand_id,
      brandName: itemData.brand_name,
    }))
  } catch (err) {
    callErrorMsg(err)
    return rejectWithValue(err)
  }
})

export const addEditVendorBrandGroup = createAsyncThunk(
  `${SLICE_NAME}/addEditVendorBrandGroup`,
  async (purpose: 'add' | 'edit', { getState, rejectWithValue }) => {
    try {
      const rootState = getState() as StoreStateType
      const {
        modalAddEdit: { data },
      } = rootState.vendorBrandGrouping

      const dataToSubmit = {
        ...(purpose === 'edit' && { id: +data.id }),
        groupId: +data.groupId,
        vendorId: data.selectedVendor.value,
        brandId: data.selectedBrand.value,
        uom: data.selectedUom.value,
        moq: +data.moq,
        mov: +data.mov,
        status: data.status,
      }

      const result =
        purpose === 'add'
          ? await postVendorBrandGroup(dataToSubmit)
          : await putVendorBrandGroup(dataToSubmit)
      return result.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)

export const updateStatusVendorBrandGroup = createAsyncThunk(
  `${SLICE_NAME}/updateStatusVendorBrandGroup`,
  async (data: { index: number; status: boolean }, { getState, rejectWithValue }) => {
    try {
      const { index, status } = data

      const { vendorBrandGroupingList } = (getState() as StoreStateType).vendorBrandGrouping
      const { id, groupId, vendorId, brandId, uom, moq, mov } = vendorBrandGroupingList[index]
      const dataToSubmit = {
        id,
        groupId,
        vendorId,
        brandId,
        uom,
        moq,
        mov,
        status,
      }

      const result = await putVendorBrandGroup(dataToSubmit)
      return result.data
    } catch (err) {
      callErrorMsg(err)
      return rejectWithValue(err)
    }
  },
)
