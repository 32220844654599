import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const XdockRBAC = {
  pageID: '275094a3edfadd9b966a134eef277eb7',
  elementID: {
    ViewList: 'a1bb333bda81a538d8c6241abf57a84f',
    ButtonAssign: '654132cf6ce0aab5e9cfa163ddfa2069',
  },
} as const

export const useRBACXdockPage = createUseRBAC(XdockRBAC.elementID)

export type UseRBACXdockPageType = ReturnType<typeof useRBACXdockPage>
