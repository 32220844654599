import { lazy } from 'react'

import generateRouterDetail from 'utils/helpers/generateRouterDetail'
import { CampaignListRolesRBAC } from '../pages/CampaignList/rbac'
import { CampaignSegmentListRolesRBAC } from '../pages/CampaignSegmentList/rbac'

const CampaignList = lazy(() => import('../pages/CampaignList'))
const CampaignSegmentList = lazy(() => import('../pages/CampaignSegmentList'))

export const CampaignListRouter: RouterFeatureInterface<
  'CampaignListRouter',
  '/dashboard/campaign'
> = {
  name: 'CampaignListRouter',
  Component: CampaignList,
  path: '/dashboard/campaign',
  routeType: 'PRIVATE',
  allowedRoles: [],
  rbacPageID: CampaignListRolesRBAC.pageID,
  isDashboard: true,
}

export const CampaignSegmentListRouter: RouterFeatureInterface<
  'CampaignSegmentListRouter',
  '/dashboard/campaign/segment'
> = {
  name: 'CampaignSegmentListRouter',
  Component: CampaignSegmentList,
  path: '/dashboard/campaign/segment',
  routeType: 'PRIVATE',
  allowedRoles: [],
  rbacPageID: CampaignSegmentListRolesRBAC.pageID,
  isDashboard: true,
}

const CampaignAndDiscountRouter = [CampaignListRouter, CampaignSegmentListRouter]

export const CampaignAndDiscountRouterDetail = generateRouterDetail(CampaignAndDiscountRouter)

export default CampaignAndDiscountRouter
