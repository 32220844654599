import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SLICE_NAME } from './tanggungRentengAsyncThunk'

export type TanggungRentengSliceType = {
  isLoading: boolean
  isActive: boolean
}

const initialState: TanggungRentengSliceType = {
  isLoading: false,
  isActive: false,
}

const tanggungRentengSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsActiveTanggungRenteng: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload
    },
  },
})

export const { reset, setIsActiveTanggungRenteng } = tanggungRentengSlice.actions
export default tanggungRentengSlice.reducer
