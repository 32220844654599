import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  LocationStateType,
  SetModalPayloadActionType,
  SetFormModalDeliveryConfigPayloadActionType,
  PopupModeType,
  FormLocationDetailType,
  PopupModeOverlayType,
  ScheduleKitchenType,
  SLAType,
  PopupStateType,
} from 'features/Location/@types/location'
import {
  SLICE_NAME,
  fetchLocationList,
  fetchSLAConfig,
  updateSLAConfig,
  fetchKongLocationDetail,
  fetchLocationDetail,
  fetchLocationAll,
  updateKongLocation,
  createKongLocation,
  createVirtualMachineQRCode,
  fetchClusterList,
} from './thunk'

export const TOTAL_SCHEDULE_KITCHEN = 7
export const INITIAL_SCHEDULE_KITCHEN: ScheduleKitchenType = {
  close: false,
  close_reason_content: '',
  close_reason_title: '',
  close_time: '21:00',
  date: '',
  day: 0,
  id: 0,
  is_24hr: false,
  open_time: '08:00',
  reason_id: 0,
  reroute: false,
  uniqueId: window.crypto.randomUUID(),
}

export const composeScheduleKitchen: () => ScheduleKitchenType[] = () => {
  const scheduleKitchens = []

  for (let i = 0; i < TOTAL_SCHEDULE_KITCHEN; i += 1) {
    scheduleKitchens.push({
      ...INITIAL_SCHEDULE_KITCHEN,
      day: i,
      uniqueId: window.crypto.randomUUID(),
    })
  }

  return scheduleKitchens
}

const INITIAL_POPUP_FORM_LOCATION_DETAIL: FormLocationDetailType = {
  grab_close: false,
  has_quadrant: false,
  location_active: false,
  location_address: '',
  location_address_detail: '',
  location_bad_stock: 0,
  location_blacklist: [],
  location_city: '',
  location_close_time: '',
  location_coverage_area: '',
  location_detail: { id: 0, name: '', type: '' },
  location_detail_id: 0,
  location_flag: null,
  location_flag_id: null,
  location_id: 0,
  location_is_24hr: false,
  location_is_reroute: false,
  location_isochrone: false,
  location_latitude: '',
  location_longitude: '',
  location_lost_stock: 0,
  location_name: '',
  location_netsuite_id: null,
  location_open_time: '',
  location_pic_3pl_id: 0,
  location_schedule: [],
  location_type: '',
  location_type_id: 0,
  location_types: { id: 0, name: '' },
  location_whitelist: [],
  partner: { name: '' },
  schedule_delivery: [],
  service_levels: [],
  supervisors: [],
  kitchen_schedule: [],
  is_schedule_kitchen: false,
  selectedSlaKitchen: [],
}

export const initialState: LocationStateType = {
  isLoading: false,
  modal: '',
  popup: {
    mode: '',
    modeOverlay: '',
    formLocationDetail: INITIAL_POPUP_FORM_LOCATION_DETAIL,
    isChangeAllScheduleKitchen: false,
    selectedScheduleKitchenIndex: null,
    selectedScheduleKitchen: INITIAL_SCHEDULE_KITCHEN,
    slaOptions: [],
    clusterOptions: [],
    selectedCluster: { id: '', name: '' },
  },
  formModalDeliveryConfig: {
    startDate: '',
    startTime: '',
    endDate: '',
    endTime: '',
    instant: false,
    twoHourOnly: false,
    selectedLocation: [],
  },
  locationOptions: [],
  locationList: [],
}

const location = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    resetPopupAfterAddEdit: (state) => {
      state.popup.formLocationDetail = initialState.popup.formLocationDetail
      state.popup.selectedCluster = initialState.popup.selectedCluster
      state.popup.mode = initialState.popup.mode
      state.popup.modeOverlay = initialState.popup.modeOverlay
      state.popup.selectedScheduleKitchenIndex = initialState.popup.selectedScheduleKitchenIndex
      state.popup.selectedScheduleKitchen = initialState.popup.selectedScheduleKitchen
    },
    setModal: (state, action: PayloadAction<SetModalPayloadActionType>) => {
      state.modal = action.payload.value
    },
    setFormDeliveryConfig: (
      state,
      action: PayloadAction<SetFormModalDeliveryConfigPayloadActionType>,
    ) => {
      const { name, value } = action.payload
      if (name === 'twoHourOnly' && value) {
        state.formModalDeliveryConfig.instant = false
      }
      if (name === 'instant' && value) {
        state.formModalDeliveryConfig.twoHourOnly = false
      }
      state.formModalDeliveryConfig[name] = value as never
    },
    setPopup: (state, action: PayloadAction<Nullable<PopupStateType>>) => {
      state.popup = { ...state.popup, ...action.payload }
    },
    setPopupMode: (state, action: PayloadAction<PopupModeType>) => {
      state.popup.mode = action.payload
    },
    setPopupModeOverlay: (state, action: PayloadAction<PopupModeOverlayType>) => {
      state.popup.modeOverlay = action.payload
    },
    setIsChangeAllScheduleKitchen: (state, action: PayloadAction<boolean>) => {
      state.popup.isChangeAllScheduleKitchen = action.payload
    },
    setPopupSelectedScheduleKitchen: (
      state,
      action: PayloadAction<{ value: Partial<ScheduleKitchenType> }>,
    ) => {
      state.popup.selectedScheduleKitchen = {
        ...state.popup.selectedScheduleKitchen,
        ...action.payload.value,
      }
    },
    setPopupSelectedScheduleKitchenIndex: (
      state,
      action: PayloadAction<{ value: null | number }>,
    ) => {
      state.popup.selectedScheduleKitchenIndex = action.payload.value
    },
    setPopupFormLocationDetail: (
      state,
      action: PayloadAction<{ value: Partial<FormLocationDetailType> }>,
    ) => {
      state.popup.formLocationDetail = {
        ...state.popup.formLocationDetail,
        ...action.payload.value,
      }
    },
    setPopupSlaOptions: (state, action: PayloadAction<{ value: SLAType[] }>) => {
      state.popup.slaOptions = action.payload.value
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLocationList.fulfilled, (state, action) => {
        state.locationOptions = action.payload.content.map((el) => ({
          name: `${el.location_id} - ${el.location_name}`,
          id: el.location_id,
        }))
      })
      .addCase(fetchSLAConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchSLAConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchSLAConfig.fulfilled, (state, action) => {
        state.isLoading = false
        if (action.payload.length) {
          const { end_date, start_date, instant, locations, regular } = action.payload[0]
          state.formModalDeliveryConfig = {
            endDate: end_date.split(' ')[0],
            endTime: end_date.split(' ')[1],
            startDate: start_date.split(' ')[0],
            startTime: start_date.split(' ')[1],
            instant,
            twoHourOnly: regular,
            selectedLocation: locations.map((el) => ({
              name: `${el.id} -  ${el.name}`,
              id: el.id,
            })),
          }
        }
      })
      .addCase(updateSLAConfig.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateSLAConfig.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateSLAConfig.fulfilled, (state) => {
        state.isLoading = false
        state.modal = ''
        state.formModalDeliveryConfig = initialState.formModalDeliveryConfig
      })
      .addCase(fetchKongLocationDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchKongLocationDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchKongLocationDetail.fulfilled, (state, action) => {
        const { detail } = action.payload
        state.popup.formLocationDetail = {
          ...detail,
          is_schedule_kitchen: detail.kitchen_schedule.length === TOTAL_SCHEDULE_KITCHEN,
          selectedSlaKitchen: [],
          kitchen_schedule: detail.kitchen_schedule.sort((a, b) => a.day - b.day),
        }

        state.popup.selectedCluster = detail.cluster
        state.isLoading = false
      })
      .addCase(fetchLocationDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLocationDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchLocationDetail.fulfilled, (state, action) => {
        state.isLoading = false
        state.popup.formLocationDetail = {
          ...action.payload,
          is_schedule_kitchen: action.payload.kitchen_schedule.length === TOTAL_SCHEDULE_KITCHEN,
          selectedSlaKitchen: [],
          kitchen_schedule: action.payload.kitchen_schedule.sort((el) => el.day),
        }
      })
      .addCase(fetchLocationAll.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchLocationAll.fulfilled, (state, action) => {
        state.isLoading = false
        const { data } = action.payload

        state.locationList = data.location.map((el) => ({
          ...el,
          uniqueId: window.crypto.randomUUID(),
        }))
      })
      .addCase(fetchLocationAll.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateKongLocation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateKongLocation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateKongLocation.fulfilled, (state) => {
        state.isLoading = false
      })

      .addCase(createKongLocation.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createKongLocation.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createKongLocation.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createVirtualMachineQRCode.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createVirtualMachineQRCode.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createVirtualMachineQRCode.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(fetchClusterList.pending, (state) => {
        state.isLoading = true
      })
      .addCase(fetchClusterList.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchClusterList.fulfilled, (state, action) => {
        const data = action.payload
        state.isLoading = false
        state.popup.clusterOptions = data.data
      })
  },
})

export default location.reducer
export const {
  reset,
  setModal,
  setFormDeliveryConfig,
  setPopup,
  setPopupMode,
  setPopupFormLocationDetail,
  setPopupModeOverlay,
  setIsChangeAllScheduleKitchen,
  setPopupSelectedScheduleKitchen,
  setPopupSelectedScheduleKitchenIndex,
  setPopupSlaOptions,
  resetPopupAfterAddEdit,
} = location.actions
