import { createAsyncThunk } from '@reduxjs/toolkit'

import { callErrorMsg } from 'helpers/errorMsg'
import { getHubs } from 'utils/api'
import { downloadFileBlob, downloadFileUrl, base64ToBlob } from 'utils/downloadFileUrl'
import { dateFormat, stringToDateObject } from 'utils/helpers/date'
import { toastSuccess } from 'utils/toast'
import {
  getMasterPayrollList,
  getDetailPayrollByUserIdServices,
  getMasterPayrollPayslipPdf,
  postPublishPayslip,
  getExportedPayrollExcel,
} from 'features/Enterprise/Payroll/services'

import {
  setHubList,
  setLoading,
  sliceName,
  setMasterPayrollList,
  setPayrollDetailByUserId,
  setQuery,
  setQueryPayrollDetailByUserId,
} from './masterPayrollSlice'

export const getHubList = createAsyncThunk(`${sliceName}/getHubList`, async (_, { dispatch }) => {
  try {
    dispatch(setLoading(true))
    const { data } = await getHubs()
    if (data?.length) {
      dispatch(
        setHubList([{ location_id: '0', location_name: 'All Hub', location_type: 'HUB' }, ...data]),
      )
    }
  } catch (error) {
    callErrorMsg(error)
  } finally {
    dispatch(setLoading(false))
  }
})

export const getMasterList = createAsyncThunk(
  `${sliceName}/getMasterList`,
  async (
    payload: {
      date: string
      locationId: string
      search: string
      page: string
      locationType: string
      payrollType: string
    },
    { dispatch, getState },
  ) => {
    try {
      const {
        masterPayroll: { query },
      } = getState() as StoreStateType
      dispatch(setLoading(true))
      const { date, locationId, search, page, payrollType } = payload
      const [splitedDate] = date.split('-')
      const dateObj = stringToDateObject(splitedDate, 'YYYYMMDD')
      const month = dateFormat({ date: dateObj, format: 'M' })
      const year = dateFormat({ date: dateObj, format: 'YYYY' })
      const { numberOfElements: deleted, ...payloadQuery } = query
      const {
        data: {
          data,
          pagination: { number_of_elements, page_index },
        },
      } = await getMasterPayrollList({
        ...payloadQuery,
        locationId: +locationId,
        month,
        year,
        search,
        payrollType: payrollType === `all` ? `` : payrollType,
        pageIndex: +page || 1,
      })
      dispatch(setQuery({ ...query, pageIndex: page_index, numberOfElements: number_of_elements }))
      dispatch(setMasterPayrollList(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getPayrollByUserId = createAsyncThunk(
  `${sliceName}/getPayrollByUserId`,
  async (
    payload: Pick<Parameters<typeof getDetailPayrollByUserIdServices>[0], 'staffId'>,
    { dispatch, getState },
  ) => {
    const {
      masterPayroll: { queryPayrollDetailByUserId },
    } = getState() as StoreStateType
    try {
      dispatch(setLoading(true))
      const {
        data: {
          data,
          pagination: { number_of_elements, page_index },
        },
      } = await getDetailPayrollByUserIdServices({ ...payload, ...queryPayrollDetailByUserId })
      dispatch(
        setQueryPayrollDetailByUserId({
          ...queryPayrollDetailByUserId,
          numberOfElements: number_of_elements,
          pageIndex: page_index,
        }),
      )
      dispatch(setPayrollDetailByUserId(data))
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const exportPayroll = createAsyncThunk(
  `${sliceName}/exportPayroll`,
  async (
    payload: {
      date: string
      locationId: string
      search: string
      payrollType: string
    },
    { dispatch },
  ) => {
    const { locationId, date, search, payrollType } = payload
    const [splitedDate] = date.split('-')
    const dateObj = stringToDateObject(splitedDate, 'YYYYMMDD')
    const month = dateFormat({ date: dateObj, format: 'M' })
    const year = dateFormat({ date: dateObj, format: 'YYYY' })
    try {
      dispatch(setLoading(true))
      const { data } = await getExportedPayrollExcel({
        locationId,
        year,
        month,
        search,
        payoutType: payrollType === `all` ? `` : payrollType,
      })
      downloadFileUrl(data.data.file_url)
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const getPaySlipPdf = createAsyncThunk(
  `${sliceName}/getPaySlipPdf`,
  async (
    payload: Parameters<typeof getMasterPayrollPayslipPdf>[0] & {
      personName: string
      date: string
      isPreview?: boolean
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { personName, date, isPreview, ...restPayload } = payload
      const { data } = await getMasterPayrollPayslipPdf(restPayload)
      downloadFileBlob({
        data: base64ToBlob(data.data, 'application/pdf'),
        mimeType: 'application/pdf',
        fileName: `payslip_${personName}_${date}`,
        isOpenNewTab: isPreview,
      })
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)

export const publishPayroll = createAsyncThunk(
  `${sliceName}/publishPayroll`,
  async (
    payload: {
      date: string
      locationId: string
      search: string
      payrollType: string
    },
    { dispatch },
  ) => {
    try {
      dispatch(setLoading(true))
      const { locationId, date, search, payrollType } = payload
      const [splitedDate] = date.split('-')
      const dateObj = stringToDateObject(splitedDate, 'YYYYMMDD')
      const month = dateFormat({ date: dateObj, format: 'M' })
      const year = dateFormat({ date: dateObj, format: 'YYYY' })

      await postPublishPayslip({
        locationId,
        year,
        month,
        search,
        payout_type: payrollType === `all` ? `` : payrollType,
      })
      toastSuccess('Publish payslip successfully')
    } catch (error) {
      callErrorMsg(error)
    } finally {
      dispatch(setLoading(false))
    }
  },
)
