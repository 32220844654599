import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  type VoucherBenefitType,
  type VoucherTargetedType,
  type VoucherMechanismType,
  type VoucherRuleType,
  type VoucherType,
  type VoucherRulePartnershipType,
  type VoucherRuleCategoryType,
  type VoucherRuleProductType,
  type VoucherRuleLocationType,
  type VoucherRuleSLAType,
  type VoucherUserSegmentType,
} from 'utils/apiList/voucher'

import { PaymentChannelTypeEnum, type PaymentChannelType } from 'utils/apiList/paymentChannel'
import { VoucherContentFormInterface } from 'features/Promotion/@types/promotionVoucherAddEdit'

import {
  fetchVoucherBenefit,
  fetchVoucherTargeted,
  fetchVoucherMechanism,
  getVoucherDetail,
  createVoucher,
  updateVoucher,
  SLICE_NAME,
  updateTargetedUsers,
  fetchUserTargetedList,
  removeCustomerVoucher,
  fetchUserSegment,
} from './addEditVoucherThunk'

export type CategoryType = {
  category_id: number
  category_name: string
}

export type LocationType = {
  location_id: number
  location_name: string
}

export type ProductType = {
  product_id: number
  product_name: string
  product_sku_number: string
}

export type VoucherPaymentChannelType = PaymentChannelType & {
  channel_type: PaymentChannelTypeEnum
  bin: string
}

export type VoucherPartnershipContentFieldType = {
  customId: string
  channel_type: PaymentChannelTypeEnum
  items: VoucherPaymentChannelType[]
}

export type CustomVoucherRuleType = {
  key: VoucherRuleType['key']
  items: (
    | CategoryType
    | LocationType
    | ProductType
    | VoucherPartnershipContentFieldType
    | VoucherRuleSLAType
  )[]
  is_exclude: VoucherRuleType['is_exclude']
  isChecked: boolean
}

export type CostSettingVariantType = 'fullAstro' | 'splitCost'

export type CostSettingOptionType = {
  id: CostSettingVariantType
  name: string
}

export type CustomVoucherType = Omit<VoucherType, 'rules' | 'cost_setting'> & {
  rules: CustomVoucherRuleType[]
  cost_setting: VoucherType['cost_setting'] & {
    splitType: CostSettingOptionType
  }
}

type UserTargetedListType = {
  customer_id: number
  customer_name: string
  customer_phone_number: string
}

export type UserTargetedType = {
  isLoading: boolean
  content: UserTargetedListType[]
  pagination: {
    empty: boolean
    first: boolean
    last: boolean
    number: number
    size: number
    totalElements: number
    totalPages: number
    numberOfElements: number
  }
  query: {
    pageSize: number
    phoneNumber: string
    pageIndex: number
  }
}

export const INITIAL_CONTENT: VoucherContentFormInterface = {
  description: '',
  tnc: '',
  image_banner: '',
  title: '',
}

export const INITIAL_RULES = [
  {
    key: 'partnership',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
  {
    key: 'category',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
  {
    key: 'hub',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
  {
    key: 'product',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
  {
    key: 'sla',
    items: [],
    is_exclude: false,
    isChecked: false,
  },
]

export const COST_SETTING_TYPE_OPTIONS: CostSettingOptionType[] = [
  {
    id: 'fullAstro',
    name: 'Full ASTRO',
  },
  {
    id: 'splitCost',
    name: 'Split Cost',
  },
]

export const INITIAL_COST_SETTING: CustomVoucherType['cost_setting'] = {
  astro_percentage: 100,
  partner_name: [''],
  splitType: COST_SETTING_TYPE_OPTIONS[0],
}

export interface AddEditVoucherStateInterface {
  isLoading: boolean
  benefits: VoucherBenefitType[]
  targets: VoucherTargetedType[]
  paymentChannelList: {
    value: number
    image: string
    name: string
  }[]
  mechanisms: VoucherMechanismType[]
  detailPromotion: CustomVoucherType | null
  isShowModalUploadTargetUser: boolean
  urlDownloadTargetUser: string
  userTargeted: UserTargetedType
  userSegmentList: VoucherUserSegmentType[]
}

const MECHANISM_LOYALTY_USER_ID = 'Loyalty User'

const initialState: AddEditVoucherStateInterface = {
  isLoading: false,
  benefits: [],
  targets: [],
  paymentChannelList: [],
  mechanisms: [],
  userSegmentList: [],
  detailPromotion: null,
  isShowModalUploadTargetUser: false,
  urlDownloadTargetUser: '',
  userTargeted: {
    isLoading: false,
    content: [],
    pagination: {
      empty: true,
      first: false,
      last: false,
      number: 0,
      numberOfElements: 0,
      size: 20,
      totalElements: 0,
      totalPages: 0,
    },
    query: { pageSize: 20, phoneNumber: '', pageIndex: 0 },
  },
}

const normalizeVoucherRulesData = (rules: VoucherType['rules']) =>
  rules.map((rule: VoucherRuleType) => {
    if (rule.key === 'partnership') {
      const { items } = rule

      // transform attributes item to shape as VoucherPaymentChannelType
      const transformedItems = (items as VoucherRulePartnershipType[]).map(
        (item: VoucherRulePartnershipType) => ({
          id: item.id,
          name: item.name,
          image_url: item.icon_url,
          bin: item.bin || '',
          channel_type: item.channel_type,
        }),
      )

      // filter item with channel_type PaymentChannelTypeEnum.creditCard
      const creditCardPaymentItems = transformedItems.filter(
        (item) => item.channel_type === PaymentChannelTypeEnum.creditCard,
      )

      const otherPaymentItems = transformedItems.filter(
        (item) => item.channel_type !== PaymentChannelTypeEnum.creditCard,
      )

      // transform to shape as VoucherPartnershipContentFieldType
      const transformedCreditCardPaymentItems = creditCardPaymentItems.length
        ? [
            {
              customId: crypto.randomUUID(),
              channel_type: creditCardPaymentItems[0].channel_type,
              items: creditCardPaymentItems,
            },
          ]
        : []

      const transformedOtherPaymentItems = otherPaymentItems.map((item) => ({
        customId: crypto.randomUUID(),
        channel_type: item.channel_type,
        items: [item],
      }))

      const result: VoucherPartnershipContentFieldType[] = [
        ...transformedCreditCardPaymentItems,
        ...transformedOtherPaymentItems,
      ]

      return {
        ...rule,
        isChecked: !!result.length,
        items: result,
      }
    }

    if (rule.key === 'category') {
      const { items } = rule

      // transform attributes item to shape as CategoryType
      const transformedItems: CategoryType[] = (items as VoucherRuleCategoryType[]).map(
        (item: VoucherRuleCategoryType) => ({
          category_id: item.id,
          category_name: item.name,
        }),
      )

      return {
        ...rule,
        isChecked: !!transformedItems.length,
        items: transformedItems,
      }
    }

    if (rule.key === 'product') {
      const { items } = rule

      // transform attributes item to shape as ProductType
      const transformedItems: ProductType[] = (items as VoucherRuleProductType[]).map(
        (item: VoucherRuleProductType) => ({
          product_id: item.id,
          product_name: item.name,
          product_sku_number: `${item.sku_number} - ${item.name}`,
        }),
      )

      return {
        ...rule,
        isChecked: !!transformedItems.length,
        items: transformedItems,
      }
    }

    if (rule.key === 'hub') {
      const { items } = rule

      // transform attributes item to shape as LocationType
      const transformedItems: LocationType[] = (items as VoucherRuleLocationType[]).map(
        (item: VoucherRuleLocationType) => ({
          location_id: item.id,
          location_name: item.name,
        }),
      )

      return {
        ...rule,
        isChecked: !!transformedItems.length,
        items: transformedItems,
      }
    }

    if (rule.key === 'sla') {
      const { items } = rule

      return {
        ...rule,
        isChecked: !!items.length,
      }
    }

    return {
      ...rule,
      isChecked: !!rule.items.length,
    }
  })

const addEditVoucherSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    reset: () => initialState,
    setIsShowModalUploadTargetUser: (state, action: PayloadAction<{ isShow: boolean }>) => {
      state.isShowModalUploadTargetUser = action.payload.isShow
    },
    setUserTargetedQuery: (
      state,
      action: PayloadAction<{ fieldName: keyof UserTargetedType['query']; value: string | number }>,
    ) => {
      const { fieldName, value } = action.payload
      state.userTargeted.query[fieldName] = value as never
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoucherBenefit.fulfilled, (state, action) => {
        state.benefits = action.payload
      })
      .addCase(fetchVoucherTargeted.fulfilled, (state, action) => {
        state.targets = action.payload
      })
      .addCase(
        fetchVoucherMechanism.fulfilled,
        (state, action: PayloadAction<VoucherMechanismType[]>) => {
          // omit "Loyalty User"
          const mechanismList = action.payload || []
          const filteredMechanismList = mechanismList.filter(
            (item) => item.name !== MECHANISM_LOYALTY_USER_ID,
          )

          state.mechanisms = filteredMechanismList
        },
      )
      .addCase(getVoucherDetail.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getVoucherDetail.fulfilled, (state, action) => {
        state.isLoading = false
        const voucher = action.payload

        const { rules } = voucher

        let newVoucher: CustomVoucherType = {
          ...voucher,
          rules: [],
          cost_setting: {
            ...voucher.cost_setting,
            splitType:
              voucher.cost_setting.astro_percentage === 100
                ? COST_SETTING_TYPE_OPTIONS[0]
                : COST_SETTING_TYPE_OPTIONS[1],
          },
        }

        // tranform rules
        if (rules.length) {
          const newRulesData = normalizeVoucherRulesData(rules)

          // merge and sync data from API with local rule state
          newVoucher = {
            ...newVoucher,
            rules: INITIAL_RULES.map((rule) => {
              const ruleData = newRulesData.find((i) => i.key === rule.key)

              if (ruleData) {
                return ruleData
              }

              return rule
            }) as CustomVoucherRuleType[],
          }
        }

        state.detailPromotion = newVoucher
      })
      .addCase(getVoucherDetail.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(createVoucher.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createVoucher.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(createVoucher.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateVoucher.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateVoucher.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateVoucher.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(updateTargetedUsers.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateTargetedUsers.fulfilled, (state, action) => {
        state.isLoading = false
        state.urlDownloadTargetUser = action.payload.urlDownload
        state.isShowModalUploadTargetUser = false
      })
      .addCase(updateTargetedUsers.rejected, (state) => {
        state.isLoading = false
      })
      .addCase(fetchUserTargetedList.pending, (state) => {
        state.userTargeted.isLoading = true
      })
      .addCase(fetchUserTargetedList.fulfilled, (state, action) => {
        const {
          payload: {
            content,
            empty,
            first,
            last,
            number,
            numberOfElements,
            size,
            totalPages,
            totalElements,
          },
        } = action
        state.userTargeted.content = content.map((el) => ({
          customer_id: el.id,
          customer_name: el.user_name,
          customer_phone_number: el.phone_number,
        }))
        state.userTargeted.isLoading = false
        state.userTargeted.pagination = {
          empty,
          first,
          last,
          number,
          numberOfElements,
          size,
          totalElements,
          totalPages,
        }
      })
      .addCase(fetchUserTargetedList.rejected, (state) => {
        state.userTargeted.isLoading = false
      })
      .addCase(removeCustomerVoucher.pending, (state) => {
        state.userTargeted.isLoading = true
      })
      .addCase(removeCustomerVoucher.rejected, (state) => {
        state.userTargeted.isLoading = false
      })
      .addCase(removeCustomerVoucher.fulfilled, (state) => {
        state.userTargeted.isLoading = false
      })
      .addCase(fetchUserSegment.fulfilled, (state, { payload }) => {
        state.userSegmentList = payload.data
      })
  },
})

export default addEditVoucherSlice
