import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const campaignAddEditRolesRBAC = {
  pageID: 'e3c571d05262fe7c09757fde78b24aff',
  elementID: {
    ViewCampaign: '54806b313c11312e616cc76587382e19',
    BulkUpload: 'a1f48a7e5daada3d4622a3155abf56a4',
    SaveCampaign: 'd181ca7171fc7541410c652e7c53adab',
    AddProductManual: '748e96c2f547813681d2dabbc4a29f13',
    SaveProductInvetory: '87d4b53c2a65d957ac791f81ef1b00a9',
    DeleteProductInvetory: '5e20c67eea6df16245ddc3bb94c73817',
  },
} as const

export const useRBACcampaignAddEditRolesPage = createUseRBAC(campaignAddEditRolesRBAC.elementID)

export type UseRBACCampaignAddEditRolesPageType = ReturnType<typeof useRBACcampaignAddEditRolesPage>
