/* eslint-disable consistent-return */
import { callErrorMsg } from 'helpers/errorMsg'
import { useState, useRef } from 'react'
import {
  getElligibleRacks,
  GetElligibleRacksParamType,
  GetElligibleRacksResponseType,
} from 'features/Rack/services/rackList'

export type SlocItemType = GetElligibleRacksResponseType['data'][number]

type FlaggingType = {
  manage_ed: boolean
  is_qr: boolean
  is_multi_sku: boolean
}

type DetermineEdByParamsType = {
  expiryDate: string | null
  statusId: number
  locationType: string
}

const useGetElligibleRackInKong = () => {
  const slocRef = useRef<SlocItemType>()
  const [isSlocOpen, setIsSlocOpen] = useState<boolean>(false)
  const [slocNoDataText, setSlocNoDataText] = useState('')
  const [slocList, setSlocList] = useState<SlocItemType[]>([])
  const [queryParams, setQueryParams] = useState<GetElligibleRacksParamType | null>(null)

  const fetchElligibleRack = async (paramData: GetElligibleRacksParamType) => {
    const result = await getElligibleRacks(paramData)
    return result?.data?.data
  }

  const initializeSloc = async ({
    page_size,
    page_index,
    location_id,
    status_id,
    product_id,
    expiry_date,
    is_autofill_request,
  }: Omit<GetElligibleRacksParamType, 'search'>) => {
    try {
      if (product_id && location_id && status_id) {
        const listData = await fetchElligibleRack({
          page_size,
          page_index,
          location_id,
          status_id,
          product_id,
          expiry_date,
          is_autofill_request,
        })
        setQueryParams({
          page_size,
          page_index,
          location_id,
          status_id,
          product_id,
          expiry_date,
        })
        setSlocList(listData)
        return listData
      }
      return []
    } catch (err) {
      callErrorMsg(err)
      return []
    }
  }

  const getIsSlocValid = (selectedItem: SlocItemType) =>
    selectedItem.active && !selectedItem.is_deleted

  const setSelectedSloc = (selectedItem: SlocItemType) => {
    slocRef.current = selectedItem
  }

  const handleValidateSloc = () => {
    setSlocNoDataText('')

    if (slocRef.current && !getIsSlocValid(slocRef.current)) {
      setIsSlocOpen(true)
      setSlocList([])
    } else {
      setIsSlocOpen(false)
    }

    if (slocList.length > 0 && slocRef.current?.is_deleted) {
      setSlocNoDataText('Rack tidak ditemukan karena sudah dihapus')
    } else if (slocList.length > 0 && !slocRef.current?.active) {
      setSlocNoDataText('Rack tidak ditemukan karena sudah nonaktif')
    }
  }

  const handleSearchSloc = async (searchName: string) => {
    try {
      const paramData = queryParams as GetElligibleRacksParamType
      const listData = await fetchElligibleRack({ ...paramData, search: searchName })

      slocRef.current = undefined
      setSlocNoDataText('')

      if (
        listData?.length === 1 &&
        listData.some(
          (item: SlocItemType) =>
            item.rack_name.toLowerCase() === searchName.toLowerCase() && item.is_deleted,
        )
      ) {
        setSlocNoDataText('Rack tidak ditemukan karena sudah dihapus')
        setSlocList([])
        return
      }
      if (
        listData?.length === 1 &&
        listData.some(
          (item: SlocItemType) =>
            item.rack_name.toLowerCase() === searchName.toLowerCase() && !item.active,
        )
      ) {
        setSlocNoDataText('Rack tidak ditemukan karena sudah nonaktif')
        setSlocList([])
        return
      }
      setSlocList(listData)
    } catch (err) {
      callErrorMsg(err)
    }
  }

  const isManageEdRequired = ({
    isManageEd,
    locationType,
  }: {
    isManageEd: boolean
    locationType: string
  }) => isManageEd && locationType?.toUpperCase() === 'WAREHOUSE'

  const isExpiryDateRequired = (params: DetermineEdByParamsType, flagging: FlaggingType) => {
    const { expiryDate, statusId, locationType } = params
    const isRequiredEDByFlagging =
      flagging.is_multi_sku ||
      flagging.is_qr ||
      isManageEdRequired({ isManageEd: flagging.manage_ed, locationType })

    return isRequiredEDByFlagging && !expiryDate && statusId !== 3
  }

  return {
    slocList,
    setSlocList,
    isSlocOpen,
    setIsSlocOpen,
    slocNoDataText,
    initializeSloc,
    handleSearchSloc,
    getIsSlocValid,
    setSelectedSloc,
    handleValidateSloc,
    setQueryParams,
    isManageEdRequired,
    isExpiryDateRequired,
  }
}
export default useGetElligibleRackInKong
