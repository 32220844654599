import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PackageStructureItemType } from 'features/Product/services/packageStructure'
import { ProductV1Type } from 'features/Product/@types/Product'

type PackageStructureInitialStateType = {
  isLoading: boolean
  productId: number
  selectedProduct: Nullable<ProductV1Type>
  params: {
    pageIndex: number
    pageSize: number
    productId: number
  }
  pagination: PaginationType
  packageStructureList: PackageStructureItemType[]
}
export const initialState: PackageStructureInitialStateType = {
  isLoading: false,
  productId: 0,
  selectedProduct: null,
  params: {
    pageIndex: 1,
    pageSize: 20,
    productId: 0,
  },
  pagination: {
    direction: 'asc',
    page_size: 20,
    number_of_elements: 0,
    total_elements: 0,
    sort: '',
    page_index: 1,
  },
  packageStructureList: [],
}

const packageStructureSlice = createSlice({
  name: 'packageStructure',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setProductId: (state, action: PayloadAction<number>) => {
      state.productId = action.payload
    },
    setSelectedProduct: (state, action) => {
      state.selectedProduct = action.payload
    },
    setParamsPackageStructure: (
      state,
      action: PayloadAction<Partial<PackageStructureInitialStateType['params']>>,
    ) => {
      state.params = {
        ...state.params,
        ...action.payload,
      }
    },
    setPackageStructureList: (
      state,
      action: PayloadAction<{
        data: PackageStructureItemType[]
        pagination: PaginationType
      }>,
    ) => {
      const { data, pagination } = action.payload
      state.packageStructureList = data
      state.pagination = pagination
    },
  },
})

export const {
  setIsLoading,
  setProductId,
  setSelectedProduct,
  setParamsPackageStructure,
  setPackageStructureList,
} = packageStructureSlice.actions

export default packageStructureSlice.reducer
