import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const AdminRBAC = {
  pageID: 'f17e886d1483d94f70803889319c2ee2',
  elementID: {
    ViewAccountList: 'cceb6990369b83bfa8dfe7a0c900ce36',
    CreateNewAccount: '44142bff8cdfa5868942722341bba0a7',
    UpdateAccount: 'f8ca5ba43aca9703e175d0fe66092ae6',
    ResetAccount: '18526c84af70cb8bc01b2eedbbfb61a8',
  },
} as const

export const useRBACAdminPage = createUseRBAC(AdminRBAC.elementID)

export type UseRBACAdminPageType = ReturnType<typeof useRBACAdminPage>
