import { createAsyncThunk } from '@reduxjs/toolkit'

import { SOMETHING_WHEN_WRONG } from 'constant/errorMessages'
import { callErrorMsg } from 'helpers/errorMsg'
import { capitalizeFirstLetter } from 'helpers'

import {
  ProductDataInterface,
  AssetSupplyOrderCreateParamsInterface,
  AssetSupplyOrderUpdateDataParamsInterface,
} from 'features/AssetManagement/@types/typeSupplyOrder'

import {
  getSupplyOrderMoveableAssetAPI,
  postSupplyOrderCreateAPI,
  postSupplyOrderUpdateDataAPI,
} from 'features/AssetManagement/services/supplyOrder'
import { setIsLoading, setProducts, setIsCheckAll } from './slice'

const SLICE_NAME = 'purchaseOrderAsset'

// type GetDetailThenPrintParamType = {
//   id: number
//   dataToPrint: {
//     vendor: string
//     poDate: string
//     poNumber: string
//     location: string
//   }
// }

// type PostUpdatePurchaseOrderParamsType = {
//   po_id: number
//   status: string
//   po_number: string
//   checker_id: number
// }

export const getAssetSupplyOrderMoveable = createAsyncThunk(
  `${SLICE_NAME}/getAssetSupplyOrder`,
  async (
    params: { originId: string; searchParams: string },
    { rejectWithValue, dispatch, getState },
  ) => {
    const {
      assetSOAddProduct: { selectedProducts },
    } = getState() as StoreStateType
    dispatch(setIsLoading(true))
    try {
      const res = await getSupplyOrderMoveableAssetAPI(params.originId, params.searchParams)
      const dataMoveable = res.data

      const items = dataMoveable.data

      const newItems = items.map((item) => ({
        productId: item.asset_inventory_id,
        productDetailId: item.asset_inventory_id,
        productImage: item.asset_images?.[0]?.url,
        productName: item.name,
        productSku: item.sku_number,
        productStock: 1,
        productUom: item.uom,
        productAssetCode: item.code,
        productInventoryStatus: capitalizeFirstLetter(item.status),
        productInventoryNote: item.note,
      }))

      const productFormated: ProductDataInterface[] = []
      let countedFinded = 0
      newItems.forEach((product) => {
        const finded = selectedProducts.find((el) => el.productDetailId === product.productDetailId)
        if (finded) {
          countedFinded += 1
          const select = {
            ...finded,
            productName: product.productName,
            productImage: product.productImage,
            productSku: product.productSku,
            productStock: product.productStock,
            checked: true,
          }
          productFormated.push(select)
        } else {
          productFormated.push({ ...product, checked: false, stockInput: 0 })
        }
      })

      dispatch(setIsCheckAll(countedFinded === newItems.length && newItems.length > 0))
      dispatch(setIsLoading(false))
      dispatch(setProducts(productFormated))

      return res.data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postSubmitAssetSO = createAsyncThunk(
  `${SLICE_NAME}/postSubmitAssetSO`,
  async (params: AssetSupplyOrderCreateParamsInterface, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const res = await postSupplyOrderCreateAPI(params)
      dispatch(setIsLoading(false))
      return res.data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

export const postUpdateAssetSO = createAsyncThunk(
  `${SLICE_NAME}/postUpdateAssetSO`,
  async (params: AssetSupplyOrderUpdateDataParamsInterface, { rejectWithValue, dispatch }) => {
    dispatch(setIsLoading(true))
    try {
      const res = await postSupplyOrderUpdateDataAPI(params)
      dispatch(setIsLoading(false))
      return res.data
    } catch (error) {
      callErrorMsg(error)
      dispatch(setIsLoading(false))
      return rejectWithValue(SOMETHING_WHEN_WRONG)
    }
  },
)

// export const actHandleSubmitAssetSO = (history, id) => (dispatch, getState) => {
//   const { selectedProducts } = getState().stateSupplyOrder
//   const initForm = JSON.parse(localStorage.getItem('payload'))
//   const baseItems = JSON.parse(localStorage.getItem('baseItem')) || []

//   const hasEmptyStockInput = selectedProducts.some((product) => !product.stockInput)
//   if (hasEmptyStockInput) {
//     toastFailed('There are products with empty stock input')
//     return
//   }

//   const itemsParams = selectedProducts.map((el) => ({
//     id: ``,
//     asset_inventory_id: String(el.productId),
//     request_qty: Number(el.stockInput),
//     incoming_qty: 0,
//     koli: 0,
//     note: el.productInventoryNote,
//   }))

//   const payload = {
//     origin_id: String(initForm.locationOriginId),
//     destination_id: String(initForm.locationDestinationId),
//     type: initForm.soType.toLowerCase().replace(/\s+/g, ''),
//     remarks: initForm.remarks.code,
//     shipping_instruction: initForm.shippingInstructions,
//     so_date: String(formatDate(initForm.soDate, 'YYYY-MM-DD 00:00:00')), // karena emang ga pake time aja.
//     grn_number: ``,
//     items: itemsParams,
//     so_reff: initForm.soReff,
//     request_by: initForm.requestBy,
//   }

//   if (id) {
//     const extraItems = baseItems.map((el) => ({
//       id: ``,
//       asset_inventory_id: String(el.productId),
//       request_qty: Number(el.productRequestQuantity),
//       incoming_qty: 0,
//       koli: 0,
//       note: el.note,
//     }))
//     payload.so_id = id
//     payload.items = [...extraItems, ...itemsParams]
//   }

//   const soFunction = id ? postSupplyOrderUpdateDataAPI : postSupplyOrderCreateAPI

//   dispatch(setIsLoading(true))
//   soFunction(payload)
//     .then(async (data) => {
//       if (id) {
//         history.replace(`/dashboard/asset/supply-order-edit/${id}`)
//         return
//       }
//       history.replace(`/dashboard/asset/supply-order-edit/${data.data.id}`)
//     })
//     .catch((err) => {
//       toastFailed(err.response.data.error.message)
//     })
//     .finally(() => {
//       dispatch(setIsLoading(false))
//     })
// }
