import { createUseRBAC } from 'features/Auth/utils/hooks/useRBAC'

export const promotionRBAC = {
  pageID: 'f10e6f987883d94f70803866adc09706',
  elementID: {
    ViewVoucherList: 'dc2de151c383fa57a94247ab671529e3',
    EditVoucher: '776a4cfb8e88526bda764fcd3d9d6108',
    CreateVoucher: '67adf298c552114dfc7fdabcc048e31c',
    ViewPriorityVoucher: '2a30724248610bcde708a647437f4837',
    UpdatePriorityVoucher: '9365f684f5224c1e30cbb73f0b860f13',
    UpdateStatusVoucher: '57bc3b06b9a78edb93b9f77f2b54c71e',
  },
} as const

export const useRBACPromotionPage = createUseRBAC(promotionRBAC.elementID)

export type UseRBACPromotionPageType = ReturnType<typeof useRBACPromotionPage>
