import { AppDispatchType } from 'store'
import { getStorageHandlingList } from 'features/Product/services/product'
import { callErrorMsg } from 'helpers/errorMsg'
import { setStorageHandlingList } from './slice'

export const actFetchStorageHandling = () => async (dispatch: AppDispatchType) => {
  try {
    const { data } = await getStorageHandlingList()
    dispatch(setStorageHandlingList(data.data))
  } catch (err) {
    callErrorMsg(err)
  }
}
