import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  type SKPDetailsInterface,
  type SKPProductDetailsInterface,
  type SKPDataQueryInterface,
  type GetSKPResponseInterface,
  type GetSKPProductsResponseInterface,
  type GetSKPFilesResponseInterface,
  type GetSKPReimbursementInterface,
  type SKPProductsInterface,
  type SKPVendorInterface,
  type CampaignClusterType,
} from 'features/Enterprise/SKP/utils/apiList'

export const sliceName = 'skp'

interface SKPInitialStateInterface {
  loadingState: {
    getSKP: boolean
    updateProduct: boolean
    getProduct: boolean
    uploadData: boolean
    downloadData: boolean
    approveActualClaim: boolean
    submitSKP: boolean
  }
  isLoadingPostProducts: boolean
  isLoadingGetProduct: boolean
  skpList: GetSKPResponseInterface
  skpDetails: SKPDetailsInterface
  skpProducts: GetSKPProductsResponseInterface
  skpReimbursement: GetSKPReimbursementInterface
  skpAgreements: GetSKPFilesResponseInterface
  skpActualClaims: GetSKPProductsResponseInterface
  skpProofFiles: GetSKPFilesResponseInterface
  skpProductDetails: SKPProductDetailsInterface
  query: {
    limit: number
    offset: number
    id: string
    skp_no?: string
    is_uploaded_actual_claim?: string
    vendor_name?: string
    campaign_name?: string
    promotion_type?: string
    is_approved_actual_claim?: string
    start_date_from?: string
    start_date_to?: string
    end_date_from?: string
    end_date_to?: string
    start_date_campaign_from?: string
    end_date_campaign_to?: string
    status?: string
    is_promotion_type_internal?: string
  }
  skpNumber: string
  productItemDetails: SKPProductsInterface
  vendorList: SKPVendorInterface[]
  campaignClusterList: CampaignClusterType[]
}

const initialState: SKPInitialStateInterface = {
  loadingState: {
    getSKP: false,
    updateProduct: false,
    getProduct: false,
    uploadData: false,
    downloadData: false,
    approveActualClaim: false,
    submitSKP: false,
  },
  isLoadingPostProducts: false,
  isLoadingGetProduct: false,
  skpList: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpDetails: {} as SKPDetailsInterface,
  skpProducts: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpReimbursement: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpAgreements: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpActualClaims: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpProofFiles: {
    data: [],
    query: {} as SKPDataQueryInterface,
  },
  skpProductDetails: {} as SKPProductDetailsInterface,
  query: {
    limit: 10,
    offset: 0,
    id: 'asc',
    skp_no: '',
    is_uploaded_actual_claim: '',
    vendor_name: '',
    campaign_name: '',
    promotion_type: '',
    is_approved_actual_claim: '',
    end_date_from: '',
    end_date_to: '',
    start_date_campaign_from: '',
    end_date_campaign_to: '',
    status: '',
  },
  skpNumber: '',
  productItemDetails: {} as SKPProductsInterface,
  vendorList: [],
  campaignClusterList: [],
}

const appSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    reset: () => initialState,
    setLoadingState: (
      state,
      action: PayloadAction<{
        key: keyof SKPInitialStateInterface['loadingState']
        isLoading: boolean
      }>,
    ) => {
      state.loadingState[action.payload.key] = action.payload.isLoading
    },
    setQuery: (state, action: PayloadAction<SKPInitialStateInterface['query']>) => {
      state.query = action.payload
    },
    setSKPList: (state, action: PayloadAction<GetSKPResponseInterface>) => {
      state.skpList = action.payload
    },
    setSKPDetails: (state, action: PayloadAction<SKPDetailsInterface>) => {
      state.skpDetails = action.payload
    },
    setSKPProducts: (state, action: PayloadAction<GetSKPProductsResponseInterface>) => {
      state.skpProducts = {
        ...state.skpProducts,
        data:
          action.payload?.data?.map((product) => ({
            ...product,
            quota_per_day_value: String(product.quota_per_day?.Float64 || '-'),
          })) || [],
      }
    },
    setSKPAgreements: (state, action: PayloadAction<GetSKPFilesResponseInterface>) => {
      state.skpAgreements = action.payload
    },
    setSKPActualClaims: (state, action: PayloadAction<GetSKPProductsResponseInterface>) => {
      state.skpActualClaims = action.payload
    },
    setSKPReimbursement: (state, action: PayloadAction<GetSKPReimbursementInterface>) => {
      state.skpReimbursement = action.payload
    },
    setSKPProofFiles: (state, action: PayloadAction<GetSKPFilesResponseInterface>) => {
      state.skpProofFiles = action.payload
    },
    setSKPProductDetails: (state, action: PayloadAction<SKPProductDetailsInterface>) => {
      state.skpProductDetails = action.payload
    },
    setSKPNumber: (state, action: PayloadAction<string>) => {
      state.skpNumber = action.payload
    },
    setProductItemDetails: (state, action: PayloadAction<SKPProductsInterface>) => {
      state.productItemDetails = action.payload
    },
    setApprovedActualClaimStatus: (state, action: PayloadAction<boolean>) => {
      state.skpDetails.is_approved_actual_claim = action.payload
    },
    setVendorList: (state, action: PayloadAction<SKPVendorInterface[]>) => {
      state.vendorList = action.payload
    },
    setCampaignCluster: (state, action: PayloadAction<CampaignClusterType[]>) => {
      state.campaignClusterList = action.payload
    },
  },
})

export const {
  setLoadingState,
  setQuery,
  setSKPList,
  setSKPDetails,
  setSKPProducts,
  setSKPAgreements,
  setSKPActualClaims,
  setSKPReimbursement,
  setSKPProofFiles,
  setSKPProductDetails,
  setSKPNumber,
  setProductItemDetails,
  setApprovedActualClaimStatus,
  setVendorList,
  setCampaignCluster,
  reset,
} = appSlice.actions

export default appSlice.reducer
