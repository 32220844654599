import { createAsyncThunk } from '@reduxjs/toolkit'
import { callErrorMsg } from 'helpers/errorMsg'
import type { CompetitorListSchemaType } from 'features/PricingEngine/pages/ReviewSKU/schema/competitors'
import {
  getProductRegistration,
  getProductRegistrationById,
  approveProductRegistration,
  rejectProductRegistration,
  getRejectReasons,
  type GetProductRegistrationParamsType,
  type ProductRegistrationResponseType,
  type ProductRegistrationDetailResponseType,
  type GeneralResponseType,
  getCompetitorList,
  getCompetitorOptions,
  type CompetitorListResponseType,
  type CompetitorOptionsResponseType,
  type UomOptionsResponseType,
  getUomOptions,
  postCompetitorDraft as postCompetitorDraftAPI,
  type CompetitorDraftResponseType,
  postCompetitorSubmit as postCompetitorSubmitAPI,
  type CalculateRSPParamsType,
  type CalculateRSPResponseType,
  calculateRSP as calculateRSPAPI,
  type SubmitRSPParamsType,
  submitRSP as submitRSPAPI,
} from '../../services/newSkus'

export const fetchNewSkus = createAsyncThunk<
  ProductRegistrationResponseType,
  GetProductRegistrationParamsType | undefined
>('pricingEngineNewSkus/fetchNewSkus', async (params, { rejectWithValue }) => {
  try {
    const newParams = { ...params }
    // Adjust dates if provided
    if (newParams.date_from) {
      const dateFrom = new Date(newParams.date_from)
      dateFrom.setHours(dateFrom.getHours() - 7)
      newParams.date_from = dateFrom.toISOString()
    }

    if (newParams.date_to) {
      const dateTo = new Date(newParams.date_to)
      dateTo.setHours(23, 59, 59, 999)
      newParams.date_to = dateTo.toISOString()
    }

    const response = await getProductRegistration(newParams)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})

export const fetchNewSkuDetail = createAsyncThunk<
  ProductRegistrationDetailResponseType,
  string | number
>('pricingEngineNewSkus/fetchNewSkuDetail', async (id, { rejectWithValue }) => {
  try {
    const response = await getProductRegistrationById(id)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})

export const approveSku = createAsyncThunk<GeneralResponseType, number>(
  'pricingEngineNewSkus/approveSku',
  async (skuId, { rejectWithValue }) => {
    try {
      const response = await approveProductRegistration(skuId)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const rejectSku = createAsyncThunk<
  GeneralResponseType,
  { reasonIds: string[]; skuId: number }
>('pricingEngineNewSkus/rejectSku', async ({ reasonIds, skuId }, { rejectWithValue }) => {
  try {
    const response = await rejectProductRegistration(reasonIds, skuId)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})

export const fetchRejectReasons = createAsyncThunk(
  'pricingEngineNewSkus/fetchRejectReasons',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getRejectReasons()
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchCompetitorList = createAsyncThunk<CompetitorListResponseType, number>(
  'pricingEngineNewSkus/fetchCompetitorList',
  async (id, { rejectWithValue }) => {
    try {
      const response = await getCompetitorList(String(id))
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchCompetitorOptions = createAsyncThunk<CompetitorOptionsResponseType>(
  'pricingEngineNewSkus/fetchCompetitorOptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getCompetitorOptions()
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const fetchUomOptions = createAsyncThunk<UomOptionsResponseType>(
  'pricingEngineNewSkus/fetchUomOptions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await getUomOptions()
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const postCompetitorDraft = createAsyncThunk<
  CompetitorDraftResponseType,
  CompetitorListSchemaType & { request_id: string }
>(
  'pricingEngineNewSkus/postDraftCompetitor',
  async ({ request_id, ...data }, { rejectWithValue }) => {
    try {
      const response = await postCompetitorDraftAPI(request_id, data)

      if (response.data.detail_error) {
        return rejectWithValue(response.data.detail_error)
      }

      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const postCompetitorSubmit = createAsyncThunk<GeneralResponseType, string>(
  'pricingEngineNewSkus/postSubmitCompetitor',
  async (request_id, { rejectWithValue }) => {
    try {
      const response = await postCompetitorSubmitAPI(request_id)
      return response.data
    } catch (error) {
      callErrorMsg(error)
      return rejectWithValue((error as Error).message)
    }
  },
)

export const calculateRSP = createAsyncThunk<
  CalculateRSPResponseType,
  CalculateRSPParamsType & { productId: string }
>('pricingEngineNewSkus/calculateRSP', async ({ productId, ...data }, { rejectWithValue }) => {
  try {
    const response = await calculateRSPAPI(productId, data)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})

export const submitRSP = createAsyncThunk<
  GeneralResponseType,
  SubmitRSPParamsType & { productId: string }
>('pricingEngineNewSkus/submitRSP', async ({ productId, ...data }, { rejectWithValue }) => {
  try {
    const response = await submitRSPAPI(productId, data)
    return response.data
  } catch (error) {
    callErrorMsg(error)
    return rejectWithValue((error as Error).message)
  }
})
