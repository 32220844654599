import { memo } from 'react'
import styled from 'styled-components'
import { SystemIcon, RowPromotion, NoData } from 'components'

function TablePromotion({
  data,
  handleChangeActive,
  handleEdit,
  isAllowedForUpdateStatusVoucher,
  isAllowedForEditVoucher,
}) {
  /****
   *  RESTRICTION LIST FOR FEATURE
   *  USING CUSTOM HOOKS WITH MEMOIZATION
   *
   */

  return (
    <>
      <Container>
        <thead>
          <Tr>
            <Th ratio="3">
              Voucher
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              Created Time
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              Benefit Type
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="3">
              Targeting
              <SystemIcon iconName="up-and-down" fontSize="smallest" className="ml-3" />
            </Th>
            <Th ratio="2">status</Th>
            <Th ratio="2">Created By</Th>
            <Th ratio="3">Total Subsidy</Th>
            <Th ratio="1">aksi</Th>
          </Tr>
        </thead>
        <tbody>
          {data.map((el) => (
            <RowPromotion
              handleChangeActive={handleChangeActive}
              key={el.voucher_id}
              data={el}
              handleEdit={handleEdit}
              isAllowedForUpdateStatusVoucher={isAllowedForUpdateStatusVoucher}
              isAllowedForEditVoucher={isAllowedForEditVoucher}
            />
          ))}
        </tbody>
      </Container>
      {data.length === 0 && <NoData />}
    </>
  )
}

TablePromotion.defaultProps = {
  data: [],
}
export default memo(TablePromotion)

const Container = styled.table`
  width: 100%;
`
const Tr = styled.tr`
  display: flex;
  padding: 16px 32px;
  background-color: ${({ theme: { colors } }) => colors.tableHead};
`
const Th = styled.th`
  color: ${({ theme: { colors } }) => colors.grey};
  /* font-weight: ${({ theme: { fontWeights } }) => fontWeights.light}; */
  font-size: ${({ theme: { fontSizes } }) => fontSizes.smaller};
  flex: ${({ ratio }) => ratio};
  text-align: start;
  text-transform: uppercase;
`
